import React, { useEffect } from 'react';

import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import fetch from 'isomorphic-fetch';
import { forEach } from 'lodash';

import { getClientErrorNotification } from './getClientErrorNotification';
import { localizedNavigate } from './localized-navigate';

import { useMaintenanceMode } from '@use-cases/maintenance-mode';
import { useNotifications } from '@use-cases/notifications';

import { useMaintenanceModeCheck } from '@repositories/maintenance-mode/get-maintenance-mode';

import { deleteCookie } from '@utils/cookies';

import { useTranslation } from '@external/react-i18next';
import { log } from '@hooks/logger';

import { ErrorCode } from '@typings/ErrorCode';
import { FrontendError } from '@typings/errors';
import { LogLevel } from '@typings/graphql';
import { FEATURE_SHAREPOINT, isEnabled } from '@utils/features';

const isLocal = process.env.GATSBY_ENV === 'local';
const isProd = process.env.GATSBY_ENV === 'prod';

const format = (obj: Object): string => JSON.stringify(obj, null, 2);

const isBrowser = typeof window !== 'undefined';

const ApolloCustomProvider: React.FC = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [client, setClient] = React.useState<ApolloClient<any> | null>(null);
  const { activateMaintenanceMode } = useMaintenanceMode();
  const { addError, clearNotification } = useNotifications();
  const { t, i18n } = useTranslation();
  const checkMaintenanceMode = useMaintenanceModeCheck();
  const { language } = i18n;
  const isSharepointEnabled = isEnabled(FEATURE_SHAREPOINT);

  let compressedUserAppData: string | null = null;
  if (isBrowser && isSharepointEnabled) {
    compressedUserAppData = window.sessionStorage.getItem(
      'compressedUserAppData'
    );
  }

  // MRX-986 remove troublesome cookie from request created by MAP apps.
  // The cookie .RIFBA.E5C67A1B-73F8-4465-BC33-FEA178637429 is too large.
  deleteCookie('.RIFBA.E5C67A1B-73F8-4465-BC33-FEA178637429');

  const setErrorNotification = (code: ErrorCode, message: string) => {
    const notificationId = `error-${code}`;
    clearNotification(notificationId);
    addError(message, { id: notificationId });
  };

  const errorLink = onError(
    ({ networkError, graphQLErrors, operation }: ErrorResponse) => {
      const { operationName, variables } = operation;
      const isTypeError = networkError?.name === 'TypeError';
      const isAddLogOperation = operationName === 'AddLog';

      if (isAddLogOperation) {
        // If we try to log an error and the backend is not available, don't do
        // any special error handling here. The log message will still be sent to
        // Rapid7 via a frontend call. And user does not need to know that the
        // AddLog query failed.
        return;
      }

      if (networkError && networkError.name !== 'ServerError') {
        if (!isProd) {
          /* eslint-disable no-console */
          console.error(`Network error: ${format(networkError)}`);
          console.error(`Operation: ${format({ operationName, variables })}`);
          /* eslint-enable no-console */
        }
        if (!isLocal) {
          log({
            level: LogLevel.Error,
            message: `NetworkError: ${networkError.message}`,
            other: JSON.stringify(networkError, null, 2),
          });
        }

        // check drupal API if maintenance mode is active
        if (checkMaintenanceMode?.data?.maintenanace) {
          activateMaintenanceMode();
        } else {
          // We should skip TypeError as:
          // - The TypeError object represents an error when an operation could not be performed
          // typically (but not exclusively) when a value is not of the expected type.
          // in this case it is expecting value -> but got cancelled request
          //
          // Why we're using TypeError:
          // we can not have one general message for each browser
          // - Chrome - TypeError: Failed to fetch
          // - Firefox - TypeError: NetworkError when attempting to fetch resource
          // - Safari - TypeError: Cancelled
          //
          // 	Why we're using this approach.
          // So it is general way to skip `cancelled` requests by browser,
          // one case that we now:
          // logic in safari with `destination` param
          // or hit url with destination param when user is already logged in
          if (isTypeError) {
            // in this case we will not redirect user to the error page
            return;
          }
          addError(
            t(
              'global.error.network-error',
              'An error occurred connecting to the myRotary service'
            )
          );
          localizedNavigate('/error');
          return;
        }
      }

      if (graphQLErrors) {
        if (!isProd) {
          /* eslint-disable no-console */
          console.error(`GraphQL error: ${format(graphQLErrors)}`);
          console.error(`Operation: ${format({ operationName, variables })}`);
          /* eslint-enable no-console */
        }
        if (!isLocal) {
          log({
            level: LogLevel.Error,
            message: 'graphQLErrors',
            other: JSON.stringify(graphQLErrors, null, 2),
          });
        }

        forEach(graphQLErrors, (error: FrontendError) => {
          if (
            !error.extensions?.code ||
            error.extensions.code === ErrorCode.Unexpected ||
            !Object.values(ErrorCode).includes(error.extensions.code)
          ) {
            setErrorNotification(
              ErrorCode.Unexpected,
              t(
                'global.error.unexpected',
                'An unexpected error occurred. Please try again.'
              )
            );
            localizedNavigate('/error');
          } else {
            getClientErrorNotification(error, setErrorNotification, t);
          }
        });
      }
    }
  );

  const userappContext = isSharepointEnabled
    ? {
        'User-Apps-Context': !compressedUserAppData
          ? ''
          : compressedUserAppData,
      }
    : {};

  const languageContext = setContext(() =>
    language
      ? {
          headers: {
            'Language-Context': language,
            ...userappContext,
          },
        }
      : undefined
  );

  const terminatingHttpLink = createHttpLink({
    uri: `${process.env.GATSBY_MRX_API_PUBLIC_URL?.replace(/\/$/, '')}/graphql`,
    fetch,
    credentials: 'include',
  });

  const linkChain = [errorLink, languageContext, terminatingHttpLink];
  const clientDep = isSharepointEnabled
    ? [language, compressedUserAppData]
    : [language];

  useEffect(() => {
    setClient(
      new ApolloClient({
        cache: new InMemoryCache({
          typePolicies: {
            Query: {
              fields: {
                getIndividual: {
                  merge: true,
                },
                clubById: {
                  merge: true,
                },
                getConference: {
                  merge: true,
                },
                getDistrictDetails: {
                  merge: true,
                },
                getAllDistrictClubs: {
                  merge: true,
                },
                getAllDistrictOfficers: {
                  merge: true,
                },
              },
            },
            ClubOperationsAccess: {
              keyFields: [],
            },
          },
        }),
        // @ts-ignore
        link: from(linkChain),
        defaultOptions: {
          query: {
            fetchPolicy: 'no-cache',
          },
        },
      })
    );
  }, [...clientDep]);

  return client ? (
    <ApolloProvider client={client}>{children}</ApolloProvider>
  ) : null;
};

export default ApolloCustomProvider;
