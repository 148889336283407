/* eslint-disable @typescript-eslint/no-explicit-any */

import Workflow from './base';

class Metadata extends Workflow {
  constructor() {
    super('metadatawf');
  }

  async getCountryData() {
    this.setAction('getCountry');
    try {
      const res: any = await this.getCall();

      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get country info');
    }
  }

  async getAreaFocusData() {
    this.setAction('getAreaFocus');
    try {
      const res: any = await this.getCall();

      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get area focus info');
    }
  }

  async getSharingPermissionData() {
    this.setAction('getSharingPermission');
    try {
      const res: any = await this.getCall();

      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get sharing permission info');
    }
  }

  async getStateData(countryCode: string) {
    this.setAction('getState');
    this.setData({
      countryCode,
    });
    try {
      const res: any = await this.getCall();

      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get state info');
    }
  }

  async getLanguageData() {
    this.setAction('getLanguage');
    try {
      const res: any = await this.getCall();

      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get language info');
    }
  }

  async getExpertiseLevels() {
    this.setAction('getExpertiseLevels');
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get expertise levels info');
    }
  }

  async getTerminationReasons() {
    this.setAction('getTerminationReasons');
    try {
      const res: any = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get termination reasons info');
    }
  }

  async getExpertiseAreas() {
    this.setAction('getExpertiseAreas');
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get expertise areas info');
    }
  }

  async getTimezones() {
    this.setAction('getTimezones');
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get timezones info');
    }
  }

  async getAllRoles() {
    this.setAction('getAllRoles');
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get roles info');
    }
  }

  async getProgram() {
    this.setAction('getProgram');
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get programs info');
    }
  }

  async getLeadershipRoles() {
    this.setAction('getLeadershipRoles');
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get leadership info');
    }
  }

  async getRotaryYear() {
    this.setAction('getRotaryYear');
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get leadership info');
    }
  }
}

export default Metadata;
