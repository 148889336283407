import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { MembershipProxy } from '../backend/schema/Membership';
import { DIS } from '../types/dis';
import { MemberSearchResultProxy } from '../backend/schema/MemberSearchResult';
import { ApolloContext } from '../backend/app';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Void: void;
};

export type AgResult = {
  __typename?: 'AGResult';
  error?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type AccPhone = {
  __typename?: 'AccPhone';
  countryId?: Maybe<Scalars['String']>;
  number: Scalars['String'];
};

/** User's access level for an operations. */
export enum AccessLevel {
  /** Can edit, but some fields are restricted. */
  Contributor = 'Contributor',
  LimitedViewer = 'LimitedViewer',
  /** Can edit everything. */
  Manager = 'Manager',
  NoPermission = 'NoPermission',
  Viewer = 'Viewer'
}

export type AccessLevelsArguments = {
  __typename?: 'AccessLevelsArguments';
  targetDistrictId?: Maybe<Scalars['String']>;
  targetTermYear?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AccessLevelsOptions = {
  id?: Maybe<Scalars['String']>;
  targetDistrictId?: Maybe<Scalars['String']>;
  targetTermYearRange?: Maybe<Array<Maybe<Scalars['String']>>>;
  type: Scalars['String'];
};

/** Limited account data for new users. */
export type AccountInfoByToken = {
  __typename?: 'AccountInfoByToken';
  /** Okta id for the account. */
  accountId: Scalars['String'];
  /** The list of security questions for the user to choose from. */
  availableSecurityQuestions: Array<SecurityQuestion>;
  /** Current security question. */
  currentSecurityQuestion: Scalars['String'];
};

export type ActiveMembership = {
  __typename?: 'ActiveMembership';
  admissionDate: Scalars['String'];
  clubId: Scalars['String'];
  clubName?: Maybe<Scalars['String']>;
  clubType?: Maybe<Scalars['String']>;
  districtId: Scalars['String'];
  terminationDate?: Maybe<Scalars['String']>;
  type: MembershipType;
};

/** Input for the addClubMeeting mutation */
export type AddClubMeetingInput = {
  /** Club Id */
  clubId: Scalars['String'];
  /** Domino Id */
  dominoId?: Maybe<Scalars['String']>;
  /** Meeting */
  meeting: MeetingInput;
};

export type AddClubMeetingResult = {
  __typename?: 'AddClubMeetingResult';
  /** Created meeting */
  meeting?: Maybe<Meeting>;
  /** Created meeting id */
  meetingId?: Maybe<Scalars['String']>;
};

/** Input for the addClubVendors mutation */
export type AddClubVendorInput = {
  /** Access for */
  accessFor: Scalars['String'];
  /** Access Type */
  accessType: Scalars['String'];
  /** Certification */
  certification: Scalars['Boolean'];
  /** Club Key */
  clubKey: Scalars['String'];
  /** Vendor Key */
  vendorKey: Scalars['String'];
};

export type AddClubVendorResult = {
  __typename?: 'AddClubVendorResult';
  /** Created vendor id */
  vendorId?: Maybe<Scalars['String']>;
};

export type AddDelegationInput = {
  delegatedRole: Scalars['String'];
  delegateeEmail: Scalars['String'];
  delegateeMemberId: Scalars['String'];
  delegateeOrgTypeName: Scalars['String'];
  delegateePrefLangCode: Scalars['String'];
  endDate: Scalars['String'];
  memberId: Scalars['String'];
  roleKey: Scalars['String'];
  startDate: Scalars['String'];
};

export type AddMemberIndividualResult = {
  __typename?: 'AddMemberIndividualResult';
  individual: Individual;
  membershipList?: Maybe<Array<MembershipForAddMember>>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  internationalProvince?: Maybe<Scalars['String']>;
  lineOne?: Maybe<Scalars['String']>;
  lineThree?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  /** State/Province/Territory for countries other than USA and Canada. */
  state?: Maybe<Scalars['String']>;
  /** State ID (as returned by the states field) for USA and Canada. */
  stateId?: Maybe<Scalars['String']>;
  type: AddressType;
};

export type AddressDetails = {
  __typename?: 'AddressDetails';
  AddressLine1?: Maybe<Scalars['String']>;
  AddressLine2?: Maybe<Scalars['String']>;
  AddressLine3?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  InternationalProvince?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
};

export type AddressInfo = {
  __typename?: 'AddressInfo';
  AddressLine1?: Maybe<Scalars['String']>;
  AddressLine2?: Maybe<Scalars['String']>;
  AddressLine3?: Maybe<Scalars['String']>;
  AddressType?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  InternationalProvince?: Maybe<Scalars['String']>;
  IsPrimary?: Maybe<Scalars['Boolean']>;
  Key?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
  MeetingComment?: Maybe<Scalars['String']>;
  MeetingDay?: Maybe<Scalars['String']>;
  MeetingLocationName?: Maybe<Scalars['String']>;
  MeetingName?: Maybe<Scalars['String']>;
  MeetingTime?: Maybe<Scalars['String']>;
  MeetingType?: Maybe<Scalars['String']>;
  MeetinglocationVariesFlag?: Maybe<Scalars['String']>;
  OnlineLocation?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
};

/** A physical location. */
export type AddressInput = {
  /** Name of the city. Latin characters only. Cannot start with a space, dot, comma or dash. Maximum length is 40. */
  city: Scalars['String'];
  /** Name of the country */
  country?: Maybe<Scalars['String']>;
  /** Id of the country as returned by the countries field. */
  countryId: Scalars['String'];
  /** Required if the country IS NOT one of USA, CAN - Must be empty if country IS one of above */
  internationalProvince?: Maybe<Scalars['String']>;
  /** Should not start with a space, dot, comma or dash. Must be Latin characters (Aa-Zz) and numbers, underscores, periods, and hyphens only. Length between 2 and 40. */
  lineOne: Scalars['String'];
  /** Must be empty if LineTwo is empty. Should not start with a space, dot, comma or dash. Must be Latin characters (Aa-Zz) and numbers, underscores, periods, and hyphens only. Maximum length is 40. */
  lineThree?: Maybe<Scalars['String']>;
  /** Should not start with a space, dot, comma or dash. Must be Latin characters (Aa-Zz) and numbers, underscores, periods, and hyphens only. Maximum length is 40. */
  lineTwo?: Maybe<Scalars['String']>;
  /** Required if the countryId IS one of USA, CAN, GUM, MSM, PRI, VIR, ASM, MHL, MNP, PLW. Otherwise, it's optional. Maximum length is 20. */
  postalCode?: Maybe<Scalars['String']>;
  /** State ID (as returned by the states field) for USA and Canada. */
  state?: Maybe<Scalars['String']>;
  /** Required if the country IS one of USA, CAN, and it must belongs to the country - Must be empty if country IS NOT one of above */
  stateId?: Maybe<Scalars['String']>;
  /** Address type. One of Business, Home, Localized, Project, HostCountry. Defaults to home. */
  type?: Maybe<AddressType>;
};

export enum AddressType {
  Business = 'Business',
  Home = 'Home',
  HostCountry = 'HostCountry',
  Localized = 'Localized',
  Project = 'Project'
}

export type Affiliation = LeadershipAffiliation | MembershipAffiliation;

export enum AffiliationType {
  Leadership = 'Leadership',
  Membership = 'Membership'
}

export type AgClubAffiliation = {
  __typename?: 'AgClubAffiliation';
  clubId: Scalars['String'];
  clubName: Scalars['String'];
  clubType: Scalars['String'];
  endDate: Scalars['String'];
  id: Scalars['String'];
  location?: Maybe<LocationData>;
  startDate: Scalars['String'];
};

/** Represents ApplicationModeResult. */
export type ApplicationModeResult = {
  __typename?: 'ApplicationModeResult';
  Config?: Maybe<Scalars['String']>;
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['String']>;
  ModeKey?: Maybe<Scalars['String']>;
  ModeName?: Maybe<Scalars['String']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
};

export type AssistantGovernor = {
  __typename?: 'AssistantGovernor';
  agId: Scalars['String'];
  clubsAffiliations?: Maybe<Array<Maybe<AgClubAffiliation>>>;
  districtLeaderships?: Maybe<Array<DistrictLeadership>>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  isDeceased?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  localizedName?: Maybe<Scalars['String']>;
  membershipClubs?: Maybe<Array<Maybe<DistrictMembershipClub>>>;
  middleName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photoUri?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  termInfo?: Maybe<AssistantGovernorTermInfo>;
};

export type AssistantGovernorByDate = {
  __typename?: 'AssistantGovernorByDate';
  agId: Scalars['String'];
  clubsAffiliations?: Maybe<Array<Maybe<AgClubAffiliation>>>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  localizedName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
};

export type AssistantGovernorTermInfo = {
  __typename?: 'AssistantGovernorTermInfo';
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type AssistantGovernorsByDateResults = {
  __typename?: 'AssistantGovernorsByDateResults';
  ags: Array<Maybe<AssistantGovernorByDate>>;
  totalCount: Scalars['Int'];
};

export type AssistantGovernorsResults = {
  __typename?: 'AssistantGovernorsResults';
  ags: Array<Maybe<AssistantGovernor>>;
  totalCount: Scalars['Int'];
};

export type BaCountry = {
  __typename?: 'BACountry';
  CountryISO2?: Maybe<Scalars['String']>;
  CountryISO3?: Maybe<Scalars['String']>;
  CountryKey?: Maybe<Scalars['String']>;
  CountryName?: Maybe<Scalars['String']>;
  CountryTranslations?: Maybe<Array<Maybe<CountryTranslations>>>;
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  AlternateBankAccName?: Maybe<Scalars['String']>;
  BankAccountKey?: Maybe<Scalars['String']>;
  BankCode?: Maybe<Scalars['String']>;
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
};

/** Represents a Bank Account Assignment Information. */
export type BankAssignmentResult = {
  __typename?: 'BankAssignmentResult';
  BankAccount?: Maybe<BankAccount>;
  BankAccountAssignmentKey?: Maybe<Scalars['String']>;
  CountryCurrency?: Maybe<CountryCurrency>;
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  LegalEntity?: Maybe<LegalEntity>;
  ModifiedByUser?: Maybe<Scalars['String']>;
  Notes?: Maybe<Scalars['String']>;
  TaxID?: Maybe<Scalars['String']>;
};

/** Represents where a club is located. It is like an Address but does not require an AddressType */
export type BaseAddress = {
  __typename?: 'BaseAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  /** Domino address id field */
  id?: Maybe<Scalars['String']>;
  /** State/Province/Territory for countries other than USA and Canada. */
  internationalProvince?: Maybe<Scalars['String']>;
  lineOne?: Maybe<Scalars['String']>;
  lineThree?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  /** State ID (as returned by the states field) for USA and Canada. */
  stateId?: Maybe<Scalars['String']>;
};

/** Same as AddressInput, but without the type. */
export type BaseAddressInput = {
  action?: Maybe<Scalars['String']>;
  /** Name of the city. Latin characters only. Cannot start with a space, dot, comma or dash. Maximum length is 40. */
  city: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  /** Id of the country as returned by the countries field. */
  countryId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  /** Required if the country IS NOT one of USA, CAN - Must be empty if country IS one of above */
  internationalProvince?: Maybe<Scalars['String']>;
  /** Should not start with a space, dot, comma or dash. Must be Latin characters (Aa-Zz) and numbers, underscores, periods, and hyphens only. Length between 2 and 40. */
  lineOne: Scalars['String'];
  /** Must be empty if LineTwo is empty. Should not start with a space, dot, comma or dash. Must be Latin characters (Aa-Zz) and numbers, underscores, periods, and hyphens only. Maximum length is 40. */
  lineThree?: Maybe<Scalars['String']>;
  /** Should not start with a space, dot, comma or dash. Must be Latin characters (Aa-Zz) and numbers, underscores, periods, and hyphens only. Maximum length is 40. */
  lineTwo?: Maybe<Scalars['String']>;
  /** Required if the countryId IS one of USA, CAN, GUM, MSM, PRI, VIR, ASM, MHL, MNP, PLW. Otherwise, it's optional. Maximum length is 20. */
  postalCode?: Maybe<Scalars['String']>;
  /** State ID (as returned by the states field) for USA and Canada. */
  state?: Maybe<Scalars['String']>;
  /** Required if the country IS one of USA, CAN, and it must belongs to the country - Must be empty if country IS NOT one of above */
  stateId?: Maybe<Scalars['String']>;
};

export type BasicInfo = {
  __typename?: 'BasicInfo';
  crmIndividualInfo?: Maybe<CrmIndividualInfoType>;
  groupApplicationRolesRelationship?: Maybe<Array<Maybe<GroupApplicationRolesRelationshipType>>>;
  oktaProfileData?: Maybe<OktaProfileDataType>;
  rolesAuthenticationTicketData?: Maybe<Array<Maybe<RolesAuthenticationTicketDataType>>>;
};

/** Basic Language input */
export type BasicLanguageInput = {
  action?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  languageId: Scalars['String'];
};

export type CdsRepresentatives = {
  __typename?: 'CDSRepresentatives';
  District?: Maybe<Scalars['String']>;
  Email_1?: Maybe<Scalars['String']>;
  Email_2?: Maybe<Scalars['String']>;
  Email_3?: Maybe<Scalars['String']>;
  Fax_Number_1?: Maybe<Scalars['String']>;
  Job_Title_1?: Maybe<Scalars['String']>;
  Job_Title_2?: Maybe<Scalars['String']>;
  Job_Title_3?: Maybe<Scalars['String']>;
  Location_1?: Maybe<Scalars['String']>;
  Name_1?: Maybe<Scalars['String']>;
  Name_2?: Maybe<Scalars['String']>;
  Name_3?: Maybe<Scalars['String']>;
  Phone_Number_1?: Maybe<Scalars['String']>;
  Phone_Number_2?: Maybe<Scalars['String']>;
  Phone_Number_3?: Maybe<Scalars['String']>;
};

export type CrmIndividualInfoType = {
  __typename?: 'CRMIndividualInfoType';
  individualKey?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
  preferredLanguage?: Maybe<Scalars['String']>;
  userSortName?: Maybe<Scalars['String']>;
};

export type CalendarEvent = {
  /** What kind of event this is. */
  categories?: Maybe<Array<Scalars['String']>>;
  /** Human-readable comments about this event. */
  comment?: Maybe<Array<Scalars['String']>>;
  /** Human-readable description of this event. */
  description?: Maybe<Scalars['String']>;
  /** Date and time at which this event ends. (ISO 8601 date or date/time) */
  dtend?: Maybe<Scalars['String']>;
  /** Modification timestamp of this event. (Seconds since Unix epoch) */
  dtstamp: Scalars['Int'];
  /** Date and time at which this event starts. (ISO 8601 date or date/time) */
  dtstart?: Maybe<Scalars['String']>;
  /** How long this event lasts. (Seconds) */
  duration?: Maybe<Scalars['Int']>;
  /** Geographic coordinates of this event. */
  geo?: Maybe<GeoLocation>;
  /** Physical address of this event. */
  location?: Maybe<Scalars['String']>;
  /** How the event recurs. */
  recurrence?: Maybe<Recurrence>;
  /** Unique identifier for this event. */
  uid: Scalars['String'];
  /** Internet address of this event. */
  url?: Maybe<Scalars['String']>;
};

/** Represents Cancel subscription status. */
export type CancelSubscriptionResponse = {
  __typename?: 'CancelSubscriptionResponse';
  Message?: Maybe<Scalars['String']>;
};

export type CategoriesByLanguage = {
  __typename?: 'CategoriesByLanguage';
  de?: Maybe<Array<Maybe<Category>>>;
  en?: Maybe<Array<Maybe<Category>>>;
  es?: Maybe<Array<Maybe<Category>>>;
  fr?: Maybe<Array<Maybe<Category>>>;
  it?: Maybe<Array<Maybe<Category>>>;
  ja?: Maybe<Array<Maybe<Category>>>;
  ko?: Maybe<Array<Maybe<Category>>>;
  pt?: Maybe<Array<Maybe<Category>>>;
};

/** Represents categories. */
export type CategoriesResult = {
  __typename?: 'CategoriesResult';
  /** categories. */
  categories?: Maybe<CategoriesByLanguage>;
};

export type Category = {
  __typename?: 'Category';
  blurb?: Maybe<Scalars['String']>;
  content?: Maybe<Array<Maybe<Content>>>;
  id?: Maybe<Scalars['String']>;
  roleAccess?: Maybe<RoleAccess>;
  title?: Maybe<Scalars['String']>;
};

export type ChildReport = {
  __typename?: 'ChildReport';
  dataSelector: Scalars['String'];
  description: Scalars['String'];
  label: Scalars['String'];
  oktaId: Scalars['String'];
  path: Scalars['String'];
  subReports: Array<SubReport>;
};

/** Club object, as returned for get club query */
export type Club = {
  __typename?: 'Club';
  charteredDate?: Maybe<Scalars['String']>;
  childClubRelationships?: Maybe<Array<ClubRelationship>>;
  clubId: Scalars['String'];
  clubName: Scalars['String'];
  clubType: Scalars['String'];
  district?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  email?: Maybe<DominoEmailType>;
  /** Club leadership roles */
  leaders?: Maybe<Array<ClubLeadershipItem>>;
  mailingAddress?: Maybe<BaseAddress>;
  meetings: Array<Meeting>;
  members: MemberSearchResults;
  officialLanguage?: Maybe<Language>;
  operationsAccess: ClubOperationsAccess;
  organizationBase?: Maybe<OrganizationBase>;
  parentClubRelationships?: Maybe<Array<ClubRelationship>>;
  physicalLocation?: Maybe<BaseAddress>;
  primaryEmail?: Maybe<Scalars['String']>;
  primaryFax?: Maybe<Fax>;
  primaryPhone?: Maybe<PhoneNumber>;
  primaryWebsite?: Maybe<Scalars['String']>;
  riClubId: Scalars['String'];
  status: ClubStatus;
  vendors: Array<ClubVendor>;
  website?: Maybe<WebsiteType>;
};


/** Club object, as returned for get club query */
export type ClubLeadersArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  termStartYear?: Maybe<Scalars['Int']>;
};


/** Club object, as returned for get club query */
export type ClubMembersArgs = {
  email?: Maybe<Scalars['String']>;
  isMultifilterAllowed?: Maybe<Scalars['Boolean']>;
  membershipStatus?: Maybe<MembershipStatusFilter>;
  membershipStatuses?: Maybe<Array<Maybe<MembershipStatusFilter>>>;
  name?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  riClubId?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ClubAffiliation = {
  __typename?: 'ClubAffiliation';
  affiliations: Array<Affiliation>;
  clubFullName: Scalars['String'];
  clubId: Scalars['String'];
  clubLocation?: Maybe<BaseAddress>;
  clubName: Scalars['String'];
  clubType: ClubType;
  district?: Maybe<DistrictType>;
  meetings?: Maybe<Array<Maybe<Meeting>>>;
};

export type ClubAffiliationFilters = {
  affiliationTypes?: Maybe<Array<AffiliationType>>;
  clubId?: Maybe<Scalars['String']>;
  membershipTypes?: Maybe<Array<MembershipType>>;
};

export type ClubAssignmentsResponse = {
  __typename?: 'ClubAssignmentsResponse';
  errorBatch?: Maybe<Scalars['String']>;
  finalResult?: Maybe<ClubAssignmentsResult>;
};

export type ClubAssignmentsResult = {
  __typename?: 'ClubAssignmentsResult';
  errorResults: Array<ErrorAssignmentsResult>;
  successResults: Array<SuccessAssignmentsResult>;
};

export type ClubCredits = {
  __typename?: 'ClubCredits';
  annualFund?: Maybe<Scalars['Float']>;
  asOfDate: Scalars['String'];
  polioPlus?: Maybe<Scalars['Float']>;
  prevYearDeltas: ClubCreditsDeltas;
  rotaryYear: Scalars['Int'];
};

export type ClubCreditsDeltas = {
  __typename?: 'ClubCreditsDeltas';
  annualFund?: Maybe<Scalars['Float']>;
  polioPlus?: Maybe<Scalars['Float']>;
  prevYearAsOfDate: Scalars['String'];
};

export type ClubDetails = {
  __typename?: 'ClubDetails';
  ClubCountry?: Maybe<Scalars['String']>;
  /** Member Club Details */
  ClubId?: Maybe<Scalars['Int']>;
  ClubName?: Maybe<Scalars['String']>;
  MemberType?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
};

export type ClubDetailsResult = {
  __typename?: 'ClubDetailsResult';
  Address?: Maybe<Scalars['String']>;
  CharterDate?: Maybe<Scalars['String']>;
  ClubId?: Maybe<Scalars['Int']>;
  ClubLanguage?: Maybe<Scalars['String']>;
  ClubName?: Maybe<Scalars['String']>;
  ClubSubType?: Maybe<Scalars['String']>;
  ClubType?: Maybe<Scalars['String']>;
  District?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  FaxCountryCode?: Maybe<Scalars['String']>;
  FaxCountryName?: Maybe<Scalars['String']>;
  FaxExtension?: Maybe<Scalars['String']>;
  FaxKey?: Maybe<Scalars['String']>;
  FaxLastUpdated?: Maybe<Scalars['String']>;
  FaxNumber?: Maybe<Scalars['String']>;
  FaxType?: Maybe<Scalars['String']>;
  IsPrimaryFax?: Maybe<Scalars['Boolean']>;
  IsPrimaryPhone?: Maybe<Scalars['Boolean']>;
  Latitude?: Maybe<Scalars['Int']>;
  Location?: Maybe<ClubLocation>;
  Longitude?: Maybe<Scalars['Int']>;
  NumberOfActiveMembers?: Maybe<Scalars['Int']>;
  PhoneCountryCode?: Maybe<Scalars['String']>;
  PhoneCountryName?: Maybe<Scalars['String']>;
  PhoneExtension?: Maybe<Scalars['String']>;
  PhoneKey?: Maybe<Scalars['String']>;
  PhoneLastUpdated?: Maybe<Scalars['String']>;
  PhoneNumber?: Maybe<Scalars['String']>;
  PhoneType?: Maybe<Scalars['String']>;
  Website?: Maybe<Scalars['String']>;
};

export type ClubDevelopment = {
  __typename?: 'ClubDevelopment';
  july1st: Scalars['Int'];
  newClubs: Scalars['Int'];
  newMembers: Scalars['Int'];
  terminatedClubs: Scalars['Int'];
  terminatedMembers: Scalars['Int'];
  totalCount: Scalars['Int'];
  year: Scalars['Int'];
};

export type ClubInvoiceResult = {
  __typename?: 'ClubInvoiceResult';
  fileContent?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type ClubLeadership = {
  __typename?: 'ClubLeadership';
  clubId?: Maybe<Scalars['String']>;
  clubName?: Maybe<Scalars['String']>;
  clubType?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  riClubId?: Maybe<Scalars['String']>;
  riDistrictId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

/** Represents a leadership position in club. */
export type ClubLeadershipItem = {
  __typename?: 'ClubLeadershipItem';
  /** Optional end date of this leadership. */
  endDate?: Maybe<Scalars['String']>;
  /** The leadership item id. */
  id?: Maybe<Scalars['String']>;
  /** An individual's id. */
  individualId?: Maybe<Scalars['String']>;
  /** The individual's name. */
  individualName?: Maybe<Scalars['String']>;
  /** A flag telling if it's a delegated position. */
  isDelegated?: Maybe<Scalars['Boolean']>;
  /** The photo URI. */
  photoUri?: Maybe<Scalars['String']>;
  /** Leader's primary email */
  primaryEmail?: Maybe<Scalars['String']>;
  /** The leadership role. */
  role: Role;
  /** Start date of this leadership. */
  startDate: Scalars['String'];
};

/** Represents club details by ClubID and ClubType. */
export type ClubLocation = {
  __typename?: 'ClubLocation';
  ClubCountry?: Maybe<Scalars['String']>;
  ClubInternationalProvince?: Maybe<Scalars['String']>;
  ClubState?: Maybe<Scalars['String']>;
};

/** Represents Member Details based on parameters : MemberId. */
export type ClubMemberDetails = {
  __typename?: 'ClubMemberDetails';
  AddressCountry?: Maybe<Scalars['String']>;
  AddressKey?: Maybe<Scalars['String']>;
  AddressLine1?: Maybe<Scalars['String']>;
  AddressLine2?: Maybe<Scalars['String']>;
  AddressLine3?: Maybe<Scalars['String']>;
  AddressType?: Maybe<Scalars['String']>;
  AdmissionDate?: Maybe<Scalars['String']>;
  BadgeName?: Maybe<Scalars['String']>;
  BirthYear?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Classification?: Maybe<Scalars['String']>;
  DateOfBirth?: Maybe<Scalars['String']>;
  EmailAddress?: Maybe<Scalars['String']>;
  EmailKey?: Maybe<Scalars['String']>;
  EmailType?: Maybe<Scalars['String']>;
  FaxCountryCode?: Maybe<Scalars['String']>;
  FaxCountryName?: Maybe<Scalars['String']>;
  FaxExtension?: Maybe<Scalars['String']>;
  FaxKey?: Maybe<Scalars['String']>;
  FaxNumber?: Maybe<Scalars['String']>;
  FaxType?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  /** Member Details */
  Gender?: Maybe<Scalars['String']>;
  InternationalProvince?: Maybe<Scalars['String']>;
  IsOnlineEmail?: Maybe<Scalars['Boolean']>;
  IsPrimaryAddress?: Maybe<Scalars['String']>;
  IsPrimaryEmail?: Maybe<Scalars['Boolean']>;
  IsSatelliteMember?: Maybe<Scalars['Boolean']>;
  Key?: Maybe<Scalars['String']>;
  LastName?: Maybe<Scalars['String']>;
  LocalizedName?: Maybe<Scalars['String']>;
  MemberId?: Maybe<Scalars['Int']>;
  MemberType?: Maybe<Scalars['String']>;
  MiddleName?: Maybe<Scalars['String']>;
  OriginalAdmissionDate?: Maybe<Scalars['String']>;
  PhoneCountryCode?: Maybe<Scalars['String']>;
  PhoneCountryName?: Maybe<Scalars['String']>;
  PhoneExtension?: Maybe<Scalars['String']>;
  PhoneKey?: Maybe<Scalars['String']>;
  PhoneNumber?: Maybe<Scalars['String']>;
  PhoneType?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  Prefix?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Suffix?: Maybe<Scalars['String']>;
  TerminationDate?: Maybe<Scalars['String']>;
  TerminationReason?: Maybe<Scalars['String']>;
  WebsiteAddress?: Maybe<Scalars['String']>;
  WebsiteKey?: Maybe<Scalars['String']>;
  WebsiteType?: Maybe<Scalars['String']>;
};

/** Represents Club Member Details based on parameters : Club Id and Club type. */
export type ClubMembersResult = {
  __typename?: 'ClubMembersResult';
  /** Member Details */
  ClubMembers?: Maybe<Array<Maybe<ClubMemberDetails>>>;
  ClubName: Scalars['String'];
};

export type ClubMembership = {
  __typename?: 'ClubMembership';
  admissionDate?: Maybe<Scalars['String']>;
  clubId?: Maybe<Scalars['String']>;
  clubName?: Maybe<Scalars['String']>;
  clubType?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  riClubId?: Maybe<Scalars['String']>;
  riDistrictId?: Maybe<Scalars['String']>;
  terminationDate?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ClubOperationsAccess = {
  __typename?: 'ClubOperationsAccess';
  club: AccessLevel;
  /** That's a quick workaround used on backend. */
  clubId: Scalars['String'];
  financesGoalsReports: AccessLevel;
  leaderships: AccessLevel;
  memberships: AccessLevel;
};

export type ClubRelationship = {
  __typename?: 'ClubRelationship';
  childClubId?: Maybe<Scalars['String']>;
  childDisId?: Maybe<Scalars['String']>;
  clubName?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  parentClubId?: Maybe<Scalars['String']>;
  parentDisId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  type?: Maybe<ClubType>;
};

export type ClubSearchConstants = {
  __typename?: 'ClubSearchConstants';
  /** Permitted filter values. */
  filter: ClubSearchFilterConstants;
};

export type ClubSearchFilterConstants = {
  __typename?: 'ClubSearchFilterConstants';
  /** Filter values for meetingType. */
  meetingType: Array<Scalars['String']>;
};

/** Club search result object, as they are returned for search query */
export type ClubSearchResult = {
  __typename?: 'ClubSearchResult';
  /** Well-known ID of the club's district. */
  districtId?: Maybe<Scalars['Int']>;
  /** Full name of this club. */
  fullName: Scalars['String'];
  /** UUID of this club. */
  id: Scalars['String'];
  /** Mailing address of this club; not necessarily a meeting site. */
  mailingAddress?: Maybe<Address>;
  /** When, where, and how this club meets. */
  meetings: Array<ClubSearchResultMeeting>;
  /** Terse name of this club. */
  name: Scalars['String'];
  /** URLs of other websites maintained by the club. */
  otherWebsites?: Maybe<Array<Scalars['String']>>;
  /** Physical Location of searched club. */
  physicalLocation?: Maybe<ClubSearchResutLocation>;
  /** URL of the primary website maintained by the club. */
  primaryWebsite?: Maybe<Scalars['String']>;
  /** Well-known ID of this club. */
  riClubId: Scalars['String'];
  /** Clubs that sponsor this club. */
  sponsorClubs?: Maybe<Array<ClubSearchResultClubRelationship>>;
  /** Clubs that this club sponsors. */
  sponsoredClubs?: Maybe<Array<ClubSearchResultClubRelationship>>;
  /** Status of this club, e.g. Active or Terminated. */
  status: Scalars['String'];
  /** Type of this club, e.g. Rotary_Club, Rotaract_Club. */
  type: ClubType;
};

/** Time-bounded pointer to another club. */
export type ClubSearchResultClubRelationship = {
  __typename?: 'ClubSearchResultClubRelationship';
  /** Terse name of the other club. */
  clubName: Scalars['String'];
  /** When this relationship ended. (ISO 8601 date/time) */
  endDate?: Maybe<Scalars['String']>;
  /** UUID of the other club. */
  id: Scalars['String'];
  /** When this relationship began. (ISO 8601 date/time) */
  startDate: Scalars['String'];
  /** Type of the other club. */
  type: Scalars['String'];
};

/** Club search result value object for a club meeting */
export type ClubSearchResultMeeting = CalendarEvent & {
  __typename?: 'ClubSearchResultMeeting';
  /** What kind of event this is. */
  categories?: Maybe<Array<Scalars['String']>>;
  /** Human-readable comments about this event. */
  comment?: Maybe<Array<Scalars['String']>>;
  /** Human-readable description of this event. */
  description?: Maybe<Scalars['String']>;
  /** Date and time at which this event ends. (ISO 8601 date or date/time) */
  dtend?: Maybe<Scalars['String']>;
  /** Modification timestamp of this event. (Seconds since Unix epoch) */
  dtstamp: Scalars['Int'];
  /** Date and time at which this event starts. (ISO 8601 date or date/time) */
  dtstart?: Maybe<Scalars['String']>;
  /** How long this event lasts. (Seconds) */
  duration?: Maybe<Scalars['Int']>;
  /** Geographic coordinates of this event. */
  geo?: Maybe<GeoLocation>;
  /** Physical address of this event. */
  location?: Maybe<Scalars['String']>;
  /** How the event recurs. */
  recurrence?: Maybe<Recurrence>;
  /** Unique identifier for this event. */
  uid: Scalars['String'];
  /** Internet address of this event. */
  url?: Maybe<Scalars['String']>;
  /** Physical address of the meeting location. */
  xRiAddress?: Maybe<BaseAddress>;
  /** 3-letter ISO code for the meeting's language. */
  xRiLanguageId?: Maybe<Scalars['String']>;
  /** English name for the meeting's language, e.g. German. */
  xRiLanguageName?: Maybe<Scalars['String']>;
  /** Whether the location of this event varies. */
  xRiLocationVaries: Scalars['Boolean'];
  /** Meeting's time of day on a 24-hour clock, e.g. 06:25 or 17:30. */
  xRiTime?: Maybe<Scalars['String']>;
  /** Meeting's day of the week, in English. */
  xRiWeekday?: Maybe<Scalars['String']>;
};

/** Club search result object, as they are returned for search query */
export type ClubSearchResults = {
  __typename?: 'ClubSearchResults';
  /** Object containing all results returned from the search.  */
  results: Array<ClubSearchResult>;
  /** Total number of results.  */
  totalCount: Scalars['Int'];
};

export type ClubSearchResutLocation = {
  __typename?: 'ClubSearchResutLocation';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  internationalProvince?: Maybe<Scalars['String']>;
  lineOne?: Maybe<Scalars['String']>;
  lineThree?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
};

export enum ClubStatus {
  Active = 'Active',
  Suspended = 'Suspended',
  Terminated = 'Terminated'
}

export type ClubStatusResponse = {
  __typename?: 'ClubStatusResponse';
  status: Array<Maybe<ClubStatus>>;
};

export enum ClubType {
  RotaractClub = 'RotaractClub',
  RotaractSatelliteClub = 'RotaractSatelliteClub',
  RotaryClub = 'RotaryClub',
  SatelliteClub = 'SatelliteClub'
}

export enum ClubTypeFilterValue {
  RotaractClub = 'Rotaract_Club',
  RotaractSatelliteClub = 'Rotaract_Satellite_Club',
  RotaryClub = 'Rotary_Club',
  SatelliteClub = 'Satellite_Club'
}

export type ClubVendor = {
  __typename?: 'ClubVendor';
  clubId?: Maybe<Scalars['String']>;
  clubType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  isPreferred?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  organizationType?: Maybe<Scalars['String']>;
  requestors: Array<VendorRequestor>;
  startDate?: Maybe<Scalars['String']>;
  vendorKey: Scalars['String'];
  vendorName?: Maybe<Scalars['String']>;
};

export type CommitteeNominations = {
  __typename?: 'CommitteeNominations';
  cmt_asn_code?: Maybe<Scalars['String']>;
  cmt_name?: Maybe<Scalars['String']>;
  cpo_code?: Maybe<Scalars['String']>;
  nms_code?: Maybe<Scalars['String']>;
  nom_cst_key?: Maybe<Scalars['String']>;
  nom_end_date?: Maybe<Scalars['String']>;
  nom_key?: Maybe<Scalars['String']>;
  nom_nms_key?: Maybe<Scalars['String']>;
  nom_start_date?: Maybe<Scalars['String']>;
  org_name?: Maybe<Scalars['String']>;
  rc5_end_date?: Maybe<Scalars['String']>;
  rc5_location_city_state?: Maybe<Scalars['String']>;
  rc5_start_date?: Maybe<Scalars['String']>;
};

export type CommitteeRolesResult = {
  __typename?: 'CommitteeRolesResult';
  endDate: Scalars['String'];
  id: Scalars['String'];
  startDate: Scalars['String'];
  type: Scalars['String'];
};

export type ConferenceAddress = {
  __typename?: 'ConferenceAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  internationalProvince?: Maybe<Scalars['String']>;
  lineOne?: Maybe<Scalars['String']>;
  lineThree?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type ConferenceFeedbackResult = {
  __typename?: 'ConferenceFeedbackResult';
  id: Scalars['String'];
  type: Scalars['String'];
};

export type Constants = {
  __typename?: 'Constants';
  clubSearch: ClubSearchConstants;
  /** The English name of each day of the week. */
  weekdayNames: Array<Scalars['String']>;
};

export type ContactFormInput = {
  club?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
  country: Scalars['String'];
  district?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  primaryPhone?: Maybe<PrimaryPhoneInput>;
  subject: Scalars['String'];
};

export type Content = {
  __typename?: 'Content';
  component?: Maybe<Scalars['String']>;
  config?: Maybe<ContentConfig>;
  defaultFund?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  mode?: Maybe<Scalars['String']>;
};

export type ContentConfig = {
  __typename?: 'ContentConfig';
  funds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type Contribution = {
  __typename?: 'Contribution';
  annualFund: Array<Maybe<ContributionItem>>;
  clubCredits: Array<Maybe<ClubCredits>>;
  polioPlus: Array<Maybe<ContributionItem>>;
};

export type ContributionDeltas = {
  __typename?: 'ContributionDeltas';
  prevYearAsOfDate: Scalars['String'];
  priorMonthEnd: Scalars['Float'];
  totalFor30June?: Maybe<Scalars['Float']>;
};

export type ContributionItem = {
  __typename?: 'ContributionItem';
  asOfDate: Scalars['String'];
  prevYearDeltas: ContributionDeltas;
  priorMonthEnd?: Maybe<Scalars['Float']>;
  rotaryYear: Scalars['Int'];
  totalFor30June?: Maybe<Scalars['Float']>;
};

/** Represents a country. */
export type Country = {
  __typename?: 'Country';
  /** The country's id. This one should be sent to DIS when writting data. */
  id: Scalars['String'];
  /** The name of the country. */
  name: Scalars['String'];
  /** Two letter code of the country. This one should NOT be used when writing data to DIS. */
  shortCode: Scalars['String'];
};

export type CountryCurrency = {
  __typename?: 'CountryCurrency';
  Country?: Maybe<BaCountry>;
  CountryCurrencyKey?: Maybe<Scalars['String']>;
  CountryKey?: Maybe<Scalars['String']>;
  CreatedByUser?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyKey?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  IsPrimary?: Maybe<Scalars['Boolean']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
};

/** Represents country currency mapping. */
export type CountryCurrencyMapped = {
  __typename?: 'CountryCurrencyMapped';
  default?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  list: Array<CurrencyId>;
};

/** Represents country. */
export type CountryDetails = {
  __typename?: 'CountryDetails';
  /** country */
  id?: Maybe<Scalars['String']>;
  iso2?: Maybe<Scalars['String']>;
  name?: Maybe<CountryTNames>;
};

export type CountryTNames = {
  __typename?: 'CountryTNames';
  de?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
  ja?: Maybe<Scalars['String']>;
  ko?: Maybe<Scalars['String']>;
  pt?: Maybe<Scalars['String']>;
  sv?: Maybe<Scalars['String']>;
};

export type CountryTranslations = {
  __typename?: 'CountryTranslations';
  CountryKey?: Maybe<Scalars['String']>;
  CountryTranslateKey?: Maybe<Scalars['String']>;
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  LanguageCode?: Maybe<Scalars['String']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
  TranslatedName?: Maybe<Scalars['String']>;
};

export type CreateIndividualInput = {
  dateOfBirth?: Maybe<Scalars['String']>;
  /** First name (Given name). Max length is 30 chars. Cannot start with a space, dash or dot. Mostly latin characters, DIS regex: `/^[\\ -\\ɏ]*$/`. */
  firstName: Scalars['String'];
  /**
   * Gender. One of Male, Female, Do Not Identify, Not Given, Self Identify. If
   * not provided, set to Not Given.
   */
  gender: Gender;
  /** Gender description. Should be provided when Gender is Self Identify. */
  genderDescription?: Maybe<Scalars['String']>;
  /** Last name (Family name). Max length is 30 chars. Cannot start with a space, dash or dot. Mostly latin characters, DIS regex: `/^[\\ -\\ɏ]*$/`. */
  lastName: Scalars['String'];
  /** Localized name (Full name). Max length is 200 chars. Supports uncode. */
  localizedName?: Maybe<Scalars['String']>;
  /** Middle name. Max length is 30 chars. Cannot start with a space, dash or dot. Mostly latin characters, DIS regex: `/^[\\ -\\ɏ]*$/`. */
  middleName?: Maybe<Scalars['String']>;
  otherAddresses?: Maybe<Array<AddressInput>>;
  otherEmails?: Maybe<Array<EmailInput>>;
  otherExpertise?: Maybe<Array<ExpertiseInput>>;
  otherLanguages?: Maybe<Array<Scalars['String']>>;
  otherPhones?: Maybe<Array<IndividualPhoneInput>>;
  otherProfessions?: Maybe<Array<ProfessionInput>>;
  prefix?: Maybe<Scalars['String']>;
  /** A physical location. */
  primaryAddress?: Maybe<AddressInput>;
  /** Email address. */
  primaryEmail?: Maybe<EmailInput>;
  /** Areas of expertise. */
  primaryExpertise?: Maybe<ExpertiseInput>;
  /** Id of the primary language as returned by the languages field. */
  primaryLanguage: Scalars['String'];
  /** A phone number. */
  primaryPhone?: Maybe<IndividualPhoneInput>;
  /** Professional experience. */
  primaryProfession?: Maybe<ProfessionInput>;
  /** Allow share sponsorship or not */
  recognitionOptOutFlag?: Maybe<Scalars['Boolean']>;
  suffix?: Maybe<Scalars['String']>;
  /** Format YYYY. Must be between this year and 100 years ago. Should be set if youthFlag is true. */
  yearOfBirth?: Maybe<Scalars['Int']>;
  /** Whether the individual is under 18 years old. */
  youthFlag?: Maybe<Scalars['Boolean']>;
};

export type CreateIndividualProgramInput = {
  fields?: Maybe<Array<ProgramFieldInput>>;
  individualId: Scalars['String'];
  programId: Scalars['String'];
};

/** Result of the createOrUpdateIndividual mutation. */
export type CreateOrUpdateIndividualResult = {
  __typename?: 'CreateOrUpdateIndividualResult';
  individualId: Scalars['String'];
};

export type CreditOrg = {
  __typename?: 'CreditOrg';
  Id?: Maybe<Scalars['String']>;
  Key?: Maybe<Scalars['String']>;
  LegacyTypeId?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type CreditOrgDetails = {
  __typename?: 'CreditOrgDetails';
  District?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  Key?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type Currency = {
  __typename?: 'Currency';
  CreatedByUser?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyKey?: Maybe<Scalars['String']>;
  CurrencyName?: Maybe<Scalars['String']>;
  CurrencyTranslations?: Maybe<Array<Maybe<CurrencyTranslations>>>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
};

/** Represents currency. */
export type CurrencyDetails = {
  __typename?: 'CurrencyDetails';
  code?: Maybe<Scalars['String']>;
  /** currency */
  id?: Maybe<Scalars['String']>;
  name?: Maybe<CurrencyTNames>;
};

export type CurrencyId = {
  __typename?: 'CurrencyId';
  currencyId: Scalars['String'];
};

export type CurrencyTNames = {
  __typename?: 'CurrencyTNames';
  de?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
  ja?: Maybe<Scalars['String']>;
  ko?: Maybe<Scalars['String']>;
  pt?: Maybe<Scalars['String']>;
  sv?: Maybe<Scalars['String']>;
  und?: Maybe<Scalars['String']>;
};

export type CurrencyTranslations = {
  __typename?: 'CurrencyTranslations';
  CreatedByUser?: Maybe<Scalars['String']>;
  CurrencyKey?: Maybe<Scalars['String']>;
  CurrencyTranslateKey?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  LanguageCode?: Maybe<Scalars['String']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
  TranslatedName?: Maybe<Scalars['String']>;
};

export type DesDistrictInfo = {
  __typename?: 'DESDistrictInfo';
  districtId: Scalars['String'];
  riDistrictId: Scalars['String'];
  zone?: Maybe<Scalars['String']>;
};

export type DgResult = {
  __typename?: 'DGResult';
  fullName: Scalars['String'];
  riDistrictId: Scalars['String'];
  roleName: Scalars['String'];
};

export enum DashboardClubType {
  RotaractClub = 'RotaractClub',
  RotaryClub = 'RotaryClub'
}

export type DaySpecifier = {
  __typename?: 'DaySpecifier';
  /** Ordinal number of this day within its month or year. */
  offset?: Maybe<Scalars['Int']>;
  /** Day of the week. */
  weekday: WeekDay;
};

export type Delegates = {
  __typename?: 'Delegates';
  DelegationEndDate?: Maybe<Scalars['String']>;
  DelegationStartDate?: Maybe<Scalars['String']>;
  DelegatorFullName?: Maybe<Scalars['String']>;
  DelegatorMemberId?: Maybe<Scalars['String']>;
  Key?: Maybe<Scalars['String']>;
  MemberId?: Maybe<Scalars['String']>;
  OrganizationId?: Maybe<Scalars['String']>;
  OrganizationName?: Maybe<Scalars['String']>;
  OrganizationType?: Maybe<Scalars['String']>;
  Role?: Maybe<Scalars['String']>;
  RoleEndDate?: Maybe<Scalars['String']>;
  RoleStartDate?: Maybe<Scalars['String']>;
};

export type DelegationRoles = {
  __typename?: 'DelegationRoles';
  Committee?: Maybe<Scalars['String']>;
  CommitteeType?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  IsDelegationEligible: Scalars['Boolean'];
  Key: Scalars['String'];
  LastName?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
  Location?: Maybe<Location>;
  MemberId?: Maybe<Scalars['String']>;
  MiddleName?: Maybe<Scalars['String']>;
  Organization?: Maybe<Scalars['String']>;
  OrganizationId?: Maybe<Scalars['String']>;
  OrganizationType?: Maybe<Scalars['String']>;
  Role: Scalars['String'];
  RoleKey: Scalars['String'];
  StartDate: Scalars['String'];
  TerminationReason?: Maybe<Scalars['String']>;
};

export type Delegations = {
  __typename?: 'Delegations';
  DelegateeFullName?: Maybe<Scalars['String']>;
  DelegateeMemberId?: Maybe<Scalars['String']>;
  DelegationEndDate?: Maybe<Scalars['String']>;
  DelegationStartDate?: Maybe<Scalars['String']>;
  Key?: Maybe<Scalars['String']>;
  MemberId?: Maybe<Scalars['String']>;
  OrganizationId?: Maybe<Scalars['String']>;
  OrganizationName?: Maybe<Scalars['String']>;
  OrganizationType?: Maybe<Scalars['String']>;
  Role?: Maybe<Scalars['String']>;
  RoleEndDate?: Maybe<Scalars['String']>;
  RoleStartDate?: Maybe<Scalars['String']>;
};

/** Input for the deleteClubMeeting mutation */
export type DeleteClubMeetingInput = {
  /** Club Id */
  clubId: Scalars['String'];
  /** Domino Id */
  dominoId?: Maybe<Scalars['String']>;
  /** MeetingId */
  meetingId: Scalars['String'];
};

export type DeleteClubMeetingResult = {
  __typename?: 'DeleteClubMeetingResult';
  isMeetingDeleted?: Maybe<Scalars['Boolean']>;
};

export type DeleteDelegationInput = {
  delegationKey: Scalars['String'];
  memberId: Scalars['String'];
};

export type DeleteIndividualProgramInput = {
  individualId: Scalars['String'];
  individualProgramId: Scalars['String'];
};

export type Demographic = {
  __typename?: 'Demographic';
  otherGendersCount: Scalars['Int'];
  over40: Scalars['Int'];
  period: Scalars['String'];
  under40: Scalars['Int'];
  unreported: Scalars['Int'];
  womenCount: Scalars['Int'];
};

/** Represents a displayname. */
export type DisplayNames = {
  __typename?: 'DisplayNames';
  id: Scalars['String'];
  name: Scalars['String'];
  /** Two letter code of the displayname. */
  shortCode: Scalars['String'];
};

/** District object, as they are returned for query. */
export type District = {
  __typename?: 'District';
  /** The district name */
  DistrictName: Scalars['String'];
  /** The district number */
  DistrictNumber: Scalars['Int'];
};

export type DistrictAddress = {
  __typename?: 'DistrictAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internationalProvince?: Maybe<Scalars['String']>;
  lineOne?: Maybe<Scalars['String']>;
  lineThree?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
};

export type DistrictAddressInput = {
  action?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  /** State/Province/Territory for countries other than USA and Canada. */
  internationalProvince?: Maybe<Scalars['String']>;
  lineOne?: Maybe<Scalars['String']>;
  lineThree?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  /** State ID (as returned by the states field) for USA and Canada. */
  stateId?: Maybe<Scalars['String']>;
};

export type DistrictClub = {
  __typename?: 'DistrictClub';
  activeMembersCount?: Maybe<Scalars['Int']>;
  district?: Maybe<DistrictData>;
  governor?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  location?: Maybe<LocationData>;
  meetings?: Maybe<Array<Maybe<Meeting>>>;
  name: Scalars['String'];
  riClubId: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  type: ClubType;
};

export type DistrictClubsResults = {
  __typename?: 'DistrictClubsResults';
  /** Object containing all results returned from the search.  */
  clubs: Array<DistrictClub>;
  /** Total number of results.  */
  totalCount: Scalars['Int'];
};

export type DistrictClubsStatistics = {
  __typename?: 'DistrictClubsStatistics';
  clubType: Scalars['String'];
  totalCount: Scalars['Int'];
};

export type DistrictDis3 = {
  __typename?: 'DistrictDIS3';
  businessAddress?: Maybe<Array<Maybe<DistrictAddress>>>;
  districtLegalName?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<DistrictEmails>>>;
  id: Scalars['String'];
  localizedAddress?: Maybe<DistrictAddress>;
  mailingAddress?: Maybe<DistrictAddress>;
  otherEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
  otherFaxes?: Maybe<Array<Maybe<DistrictFax>>>;
  otherPhones?: Maybe<Array<Maybe<DistrictPhone>>>;
  otherWebsites?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryEmail?: Maybe<Scalars['String']>;
  primaryFax?: Maybe<DistrictFax>;
  primaryPhone?: Maybe<DistrictPhone>;
  primaryWebsite?: Maybe<Scalars['String']>;
  riDistrictId?: Maybe<Scalars['Int']>;
  websites?: Maybe<DistrictWebsites>;
  zone?: Maybe<Scalars['Int']>;
  zoneSection?: Maybe<Scalars['String']>;
};

export type DistrictDashboardsResults = {
  __typename?: 'DistrictDashboardsResults';
  asOfDate: Scalars['String'];
  clubDevelopment: Array<Maybe<ClubDevelopment>>;
  contribution: Contribution;
  demographics: Array<Maybe<Demographic>>;
  membersTrends: Array<Maybe<MembersTrends>>;
};

export type DistrictData = {
  __typename?: 'DistrictData';
  id: Scalars['String'];
  riDistrictId: Scalars['Int'];
};

export type DistrictDetailsInput = {
  businessAddress?: Maybe<Array<Maybe<DistrictAddressInput>>>;
  districtId: Scalars['String'];
  emails?: Maybe<DistrictEmailsInput>;
  localizedAddress?: Maybe<DistrictAddressInput>;
  mailingAddress?: Maybe<DistrictAddressInput>;
  otherFaxes?: Maybe<Array<Maybe<DistrictFaxInput>>>;
  otherPhones?: Maybe<Array<Maybe<DistrictPhoneInput>>>;
  otherWebsites?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryEmail?: Maybe<DistrictEmailInput>;
  primaryFax?: Maybe<DistrictFaxInput>;
  primaryPhone?: Maybe<DistrictPhoneInput>;
  primaryWebsite?: Maybe<Scalars['String']>;
  websites?: Maybe<DistrictWebsiteInput>;
};

export type DistrictEmailInput = {
  action?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  type: EmailType;
};

export type DistrictEmails = {
  __typename?: 'DistrictEmails';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
};

export type DistrictEmailsInput = {
  action?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  primaryEmail?: Maybe<Scalars['String']>;
};

export type DistrictFax = {
  __typename?: 'DistrictFax';
  country?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

export type DistrictFaxInput = {
  action?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number: Scalars['String'];
};

export type DistrictLeadership = {
  __typename?: 'DistrictLeadership';
  districtId?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  id: Scalars['String'];
  riDistrictId: Scalars['String'];
  role: Scalars['String'];
  roleId: Scalars['String'];
  startDate: Scalars['String'];
  termYears?: Maybe<Scalars['Int']>;
};

export type DistrictLeadershipResult = {
  __typename?: 'DistrictLeadershipResult';
  error?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type DistrictMembersStatistics = {
  __typename?: 'DistrictMembersStatistics';
  memberType: Scalars['String'];
  totalCount: Scalars['Int'];
};

export type DistrictMembershipClub = {
  __typename?: 'DistrictMembershipClub';
  clubId: Scalars['String'];
  clubName: Scalars['String'];
  clubType: Scalars['String'];
  id: Scalars['String'];
  location?: Maybe<LocationData>;
  membershipType: Scalars['String'];
  terminationDate?: Maybe<Scalars['String']>;
};

export type DistrictOfficer = {
  __typename?: 'DistrictOfficer';
  endDate: Scalars['String'];
  id: Scalars['String'];
  individual: OfficerIndividual;
  isDelegated?: Maybe<Scalars['Boolean']>;
  role: Scalars['String'];
  roleId: Scalars['String'];
  startDate: Scalars['String'];
};

export type DistrictOfficersResults = {
  __typename?: 'DistrictOfficersResults';
  /** Object containing all results returned from the search.  */
  leadership: Array<Maybe<DistrictOfficer>>;
  /** Total number of results.  */
  totalCount: Scalars['Int'];
};

export type DistrictPhone = {
  __typename?: 'DistrictPhone';
  country: Scalars['String'];
  countryId: Scalars['String'];
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number: Scalars['String'];
};

export type DistrictPhoneInput = {
  action?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number: Scalars['String'];
};

export type DistrictType = {
  __typename?: 'DistrictType';
  id: Scalars['String'];
  isHomeDistrict: Scalars['Boolean'];
  riDistrictId: Scalars['Int'];
  zone?: Maybe<Scalars['String']>;
};

export type DistrictWebsiteInput = {
  action?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  primaryWebsite?: Maybe<Scalars['String']>;
};

export type DistrictWebsites = {
  __typename?: 'DistrictWebsites';
  id?: Maybe<Scalars['String']>;
  otherWebsites?: Maybe<Scalars['String']>;
  primaryWebsite?: Maybe<Scalars['String']>;
};

export type DistrictsResult = {
  __typename?: 'DistrictsResult';
  districts: Array<DistrictType>;
  error?: Maybe<Scalars['String']>;
};

export type DominoContactInfo = {
  address?: Maybe<Array<DominoContactInfoAddress>>;
  email?: Maybe<Array<DominoContactInfoEmail>>;
  phone?: Maybe<Array<DominoContactInfoPhone>>;
};

export type DominoContactInfoAddress = {
  action?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  hasStates?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  internationalProvince?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lineOne: Scalars['String'];
  lineThree?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  type?: Maybe<AddressType>;
};

export type DominoContactInfoEmail = {
  action?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isSignin?: Maybe<Scalars['Boolean']>;
  type: EmailType;
};

export type DominoContactInfoPhone = {
  action?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  type: PhoneType;
};

export type DominoEmailInput = {
  action?: Maybe<Scalars['String']>;
  /** Club email */
  email?: Maybe<Scalars['String']>;
  /** Email identifier */
  id?: Maybe<Scalars['String']>;
  /** Club email is primary */
  isPrimary?: Maybe<Scalars['Boolean']>;
};

export type DominoEmailType = {
  __typename?: 'DominoEmailType';
  /** Club email */
  email: Scalars['String'];
  /** Email identifier */
  id: Scalars['String'];
  /** Club email is primary */
  isPrimary: Scalars['Boolean'];
};

/** Represents ECardDetailsResult. */
export type ECardDetailsResult = {
  __typename?: 'ECardDetailsResult';
  Created?: Maybe<Scalars['String']>;
  DedicatedTo?: Maybe<Scalars['String']>;
  DedicationType?: Maybe<Scalars['Int']>;
  DonorName?: Maybe<Scalars['String']>;
  ImageFid?: Maybe<Scalars['Int']>;
  LookupKey?: Maybe<Scalars['String']>;
  NotificationType?: Maybe<Scalars['Int']>;
  PersonalMsg?: Maybe<Scalars['String']>;
};

export type EditDelegationInput = {
  delegatedRole: Scalars['String'];
  delegateeEmail: Scalars['String'];
  delegateeIndividualId: Scalars['String'];
  delegateeMemberId: Scalars['String'];
  delegateeOrgTypeName: Scalars['String'];
  delegateePrefLangCode: Scalars['String'];
  delegationKey: Scalars['String'];
  endDate: Scalars['String'];
  memberId: Scalars['String'];
  startDate: Scalars['String'];
};

/** Input for the editMeeting mutation */
export type EditMeetingInput = {
  /** Club Id */
  clubId: Scalars['String'];
  /** Domino Id */
  dominoId?: Maybe<Scalars['String']>;
  /** Meeting */
  meeting: MeetingInput;
  /** MeetingId */
  meetingId: Scalars['String'];
};

export type EditMeetingResult = {
  __typename?: 'EditMeetingResult';
  /** Updated meeting */
  meeting?: Maybe<Meeting>;
  /** Updated meeting id */
  meetingId?: Maybe<Scalars['String']>;
};

export type Email = {
  __typename?: 'Email';
  address: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  /** Only vailable if _this_ individual is currently logged in into the app. Otherwise it's null. */
  isSignin?: Maybe<Scalars['Boolean']>;
  type: EmailType;
};

export type EmailAddress = {
  __typename?: 'EmailAddress';
  EmailAddress?: Maybe<Scalars['String']>;
  EmailType?: Maybe<Scalars['String']>;
  IsPrimary?: Maybe<Scalars['Boolean']>;
  Key?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
};

/** An email address with metadata. */
export type EmailInput = {
  /** The email address. */
  address: Scalars['String'];
  /** Email type. One of Personal, Business. If not provided, set to Personal. */
  type: EmailType;
};

export enum EmailType {
  Business = 'Business',
  Personal = 'Personal'
}

export type ErrorAssignmentsResult = {
  __typename?: 'ErrorAssignmentsResult';
  body?: Maybe<ErrorBody>;
  id: Scalars['Int'];
  statusCode: Scalars['Int'];
};

export type ErrorBatch = {
  __typename?: 'ErrorBatch';
  code: Scalars['String'];
  description: Scalars['String'];
  source: Scalars['String'];
};

export type ErrorBody = {
  __typename?: 'ErrorBody';
  detail: Scalars['String'];
  errors?: Maybe<Array<ErrorBatch>>;
  status: Scalars['Int'];
  title: Scalars['String'];
  type: Scalars['String'];
};

/** Represents exchange rates. */
export type ExchangeRates = {
  __typename?: 'ExchangeRates';
  /** exchange rates */
  FromCurrency?: Maybe<Scalars['String']>;
  RateDivisor?: Maybe<Scalars['String']>;
  RateMultiplier?: Maybe<Scalars['String']>;
  ToCurrency?: Maybe<Scalars['String']>;
};

export type Expertise = {
  __typename?: 'Expertise';
  area: Scalars['String'];
  areaId: Scalars['String'];
  level: Scalars['String'];
  levelId: Scalars['String'];
};

export type ExpertiseArea = {
  __typename?: 'ExpertiseArea';
  areaId: Scalars['String'];
  label: Scalars['String'];
  primary: Scalars['Boolean'];
};

export type ExpertiseAreaItem = {
  __typename?: 'ExpertiseAreaItem';
  id: Scalars['String'];
  label: Scalars['String'];
};

export type ExpertiseInput = {
  areaId: Scalars['String'];
  levelId: Scalars['String'];
};

export type ExpertiseLevel = {
  __typename?: 'ExpertiseLevel';
  areas: Array<ExpertiseArea>;
  level: Scalars['String'];
  levelId: Scalars['String'];
};

export type ExpertiseLevelItem = {
  __typename?: 'ExpertiseLevelItem';
  id: Scalars['String'];
  label: Scalars['String'];
};

/** A Fax number */
export type Fax = {
  __typename?: 'Fax';
  country?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  type?: Maybe<FaxType>;
};

export type FaxInput = {
  action?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  type?: Maybe<FaxType>;
};

export enum FaxType {
  Business = 'Business',
  Home = 'Home'
}

export type Feedback = {
  __typename?: 'Feedback';
  feedbackType: Scalars['String'];
  id: Scalars['String'];
  isDistrictPresRep?: Maybe<Scalars['Boolean']>;
  questions: Array<Question>;
  responses: Array<QuestionResponse>;
};

export type FeedbackQuestionsResponse = {
  __typename?: 'FeedbackQuestionsResponse';
  id: Scalars['String'];
  questions: Array<Question>;
  sectionName: Scalars['String'];
};

export type FeedbackSection = {
  __typename?: 'FeedbackSection';
  id: Scalars['String'];
  sectionName: Scalars['String'];
};

export enum FieldCharSet {
  OnlyDigits = 'OnlyDigits',
  /** DIS regex: `/^[\\ -\\ɏ]*$/`. */
  OnlyLatin = 'OnlyLatin'
}

export type FieldConstraints = {
  __typename?: 'FieldConstraints';
  charSet?: Maybe<FieldCharSet>;
  maxLength: Scalars['Int'];
};

export type FieldOption = {
  __typename?: 'FieldOption';
  key: Scalars['String'];
  value: Scalars['String'];
};

export enum FieldType {
  Date = 'Date',
  Number = 'Number',
  Options = 'Options',
  String = 'String'
}

export type FileContentResult = {
  __typename?: 'FileContentResult';
  FileContent?: Maybe<Scalars['String']>;
};

export type FinancialReprentatives = {
  __typename?: 'FinancialReprentatives';
  Collector?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  District?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Office?: Maybe<Scalars['String']>;
  Phone?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
};

export type FormattedClub = {
  __typename?: 'FormattedClub';
  clubId: Scalars['String'];
  location: FormattedLocation;
  name: Scalars['String'];
  type: Scalars['String'];
};

export type FormattedLocation = {
  __typename?: 'FormattedLocation';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  internationalProvince?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type FormattedMembership = {
  __typename?: 'FormattedMembership';
  fullName: Scalars['String'];
  individualId: Scalars['String'];
  isTerminated: Scalars['Boolean'];
  startDate: Scalars['Int'];
  type: Scalars['String'];
};

export type FundSubtitle = {
  __typename?: 'FundSubtitle';
  de?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
  ja?: Maybe<Scalars['String']>;
  ko?: Maybe<Scalars['String']>;
  pt?: Maybe<Scalars['String']>;
};

export type FundTitle = {
  __typename?: 'FundTitle';
  de?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
  ja?: Maybe<Scalars['String']>;
  ko?: Maybe<Scalars['String']>;
  pt?: Maybe<Scalars['String']>;
};

export type Funding = {
  __typename?: 'Funding';
  AmountExpected?: Maybe<Scalars['Float']>;
  AmountReceived?: Maybe<Scalars['Float']>;
  AmountRemaining?: Maybe<Scalars['Float']>;
  Budget?: Maybe<Scalars['Float']>;
  Paid?: Maybe<Scalars['Boolean']>;
};

/** Represents funds. */
export type Funds = {
  __typename?: 'Funds';
  alias?: Maybe<Scalars['String']>;
  fundId?: Maybe<Scalars['String']>;
  fundSubId?: Maybe<Scalars['String']>;
  fundSubtitle?: Maybe<FundSubtitle>;
  fundTitle?: Maybe<FundTitle>;
  /** funds */
  id?: Maybe<Scalars['Int']>;
  isPaymentType?: Maybe<Scalars['Boolean']>;
  recurring?: Maybe<Scalars['Boolean']>;
  rnwPurposeText?: Maybe<Scalars['String']>;
  typeName?: Maybe<Scalars['String']>;
};

export enum Gender {
  Dni = 'DNI',
  Female = 'Female',
  Male = 'Male',
  NotGiven = 'NotGiven',
  Self = 'Self'
}

export type GeoLocation = {
  __typename?: 'GeoLocation';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type GetAccessLevelsResults = {
  __typename?: 'GetAccessLevelsResults';
  arguments?: Maybe<AccessLevelsArguments>;
  /** User's access level for an DIS API url. */
  level: AccessLevel;
  /** DIS API url */
  target: Scalars['String'];
};

export type GetAddDelegationResult = {
  __typename?: 'GetAddDelegationResult';
  DelegationKey?: Maybe<Scalars['String']>;
};

export type GetAllDistrictOfficersOptions = {
  shouldFetchDominoOfficers?: Maybe<Scalars['Boolean']>;
  shouldFetchNonMemberDES?: Maybe<Scalars['Boolean']>;
  shouldGetMembershipsFromOtherDistricts?: Maybe<Scalars['Boolean']>;
};

export type GetConferenceDetailsResult = {
  __typename?: 'GetConferenceDetailsResult';
  comments?: Maybe<Scalars['String']>;
  conferenceAddress?: Maybe<ConferenceAddress>;
  createdByRiDistrictId?: Maybe<Scalars['String']>;
  detailForJoiningRemotely?: Maybe<Scalars['String']>;
  districtId: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isPresRepRequested?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  locationNameOrDetail?: Maybe<Scalars['String']>;
  onlineLocation?: Maybe<Scalars['String']>;
  participatingDistricts?: Maybe<Array<ParticipatingDistricts>>;
  prAccPhone?: Maybe<Scalars['String']>;
  prAccommodation?: Maybe<Scalars['String']>;
  prAddress?: Maybe<ConferenceAddress>;
  presRepAccPhone?: Maybe<AccPhone>;
  presidentialRepresentative?: Maybe<PresidentialRepresentative>;
  relatedRotaryYear: Scalars['Int'];
  startDate?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  venueName?: Maybe<Scalars['String']>;
  venueType: MeetingType;
};

export type GetConferencesResults = {
  __typename?: 'GetConferencesResults';
  conferenceAddress?: Maybe<ConferenceAddress>;
  createdByRiDistrictId?: Maybe<Scalars['String']>;
  districtId: Scalars['String'];
  endDate: Scalars['String'];
  feedbacks: Array<ConferenceFeedbackResult>;
  id: Scalars['String'];
  isDistrictPresRep?: Maybe<Scalars['Boolean']>;
  participatingDistricts?: Maybe<Array<ParticipatingDistricts>>;
  presidentialRepresentative?: Maybe<PresidentialRepresentative>;
  relatedRotaryYear: Scalars['Int'];
  startDate: Scalars['String'];
  venueType: MeetingType;
};

export type GetDelegationProfileResult = {
  __typename?: 'GetDelegationProfileResult';
  Delegates?: Maybe<Array<Maybe<Delegates>>>;
  Delegations?: Maybe<Array<Maybe<Delegations>>>;
  Roles?: Maybe<Array<DelegationRoles>>;
};

export type GetEditedDelegationResult = {
  __typename?: 'GetEditedDelegationResult';
  DelegationKey?: Maybe<Scalars['String']>;
};

export type GetPresRepConferencesResult = {
  __typename?: 'GetPresRepConferencesResult';
  conferences: Array<GetConferencesResults>;
  districtId: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  riDistrictId: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
  term: Scalars['Int'];
};

/** Represents a RRN Number by Member ID. */
export type GinResult = {
  __typename?: 'GinResult';
  FiscalCode_c?: Maybe<Scalars['String']>;
  PAN_c?: Maybe<Scalars['String']>;
  /** RRN Number. */
  RRN_c?: Maybe<Scalars['String']>;
};

export type GrantDetailsResult = {
  __typename?: 'GrantDetailsResult';
  EndDate?: Maybe<Scalars['String']>;
  Funding?: Maybe<Funding>;
  Id?: Maybe<Scalars['String']>;
  Location?: Maybe<GrantLocation>;
  Message?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  ProgramYear?: Maybe<Scalars['Int']>;
  StartDate?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

/** Represents grant details by GrantId. */
export type GrantLocation = {
  __typename?: 'GrantLocation';
  GrantCountry?: Maybe<Scalars['String']>;
};

export type GroupApplicationRolesRelationshipType = {
  __typename?: 'GroupApplicationRolesRelationshipType';
  accessFlag?: Maybe<Scalars['String']>;
  applications?: Maybe<Array<Maybe<GroupApplicationsType>>>;
  containErrors?: Maybe<Scalars['Boolean']>;
  errorCode?: Maybe<Scalars['Int']>;
  errorId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<GroupRolesType>>>;
};

export type GroupApplicationsType = {
  __typename?: 'GroupApplicationsType';
  appInstanceId?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GroupRolesType = {
  __typename?: 'GroupRolesType';
  roleKey?: Maybe<Scalars['String']>;
  roleName?: Maybe<Scalars['String']>;
};

export type HateoasLink = {
  __typename?: 'HATEOASLink';
  href: Scalars['String'];
  method: Scalars['String'];
};

/**
 * Individual object for the getIndividual query,
 * with IndividualSearchResult properties.
 */
export type Individual = {
  __typename?: 'Individual';
  aboutMe?: Maybe<Scalars['String']>;
  /**
   * The list contains only the active club affiliations.
   * The list is sorted and grouped with the following order: full membership
   * (only one item), officer roles, honorary memberships. Within the groups, the
   * list is sorted alphabetically by the club name.
   */
  clubAffiliations: Array<ClubAffiliation>;
  dateOfBirth?: Maybe<Scalars['String']>;
  /** The edit permission rights for the requesting user. */
  editPermissions?: Maybe<IndividualEditPermissions>;
  /** Individual's primary email address. */
  email?: Maybe<Scalars['String']>;
  /** Individual's first name. */
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  genderDescription?: Maybe<Scalars['String']>;
  groupedExpertise: Array<ExpertiseLevel>;
  /** The HATEOAS links collection */
  hateoasLinks?: Maybe<Array<HateoasLink>>;
  /** Individual's id. */
  id: Scalars['String'];
  /** Flag that indicates if current user is a club officer */
  isClubOfficer?: Maybe<Scalars['Boolean']>;
  /** Flag that indicates if current user is a club officer or district officer */
  isClubOrDistrictOfficer?: Maybe<Scalars['Boolean']>;
  /** Individual's last name. */
  lastName?: Maybe<Scalars['String']>;
  /** Individual's localized name. */
  localizedName?: Maybe<Scalars['String']>;
  /** Individual's location. */
  location?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  /** Individual's Full name. */
  name: Scalars['String'];
  nfKey?: Maybe<Scalars['String']>;
  onlineId?: Maybe<Scalars['String']>;
  otherAddresses?: Maybe<Array<Address>>;
  otherEmails?: Maybe<Array<Email>>;
  otherExpertise?: Maybe<Array<Expertise>>;
  otherLanguages?: Maybe<Array<IndividualLanguage>>;
  otherPhones?: Maybe<Array<PhoneNumber>>;
  otherProfessions?: Maybe<Array<Profession>>;
  phoneNumber: Scalars['String'];
  /** The URL for individual's photo. */
  photoUri?: Maybe<Scalars['String']>;
  preferredLanguage: Scalars['String'];
  prefix?: Maybe<Scalars['String']>;
  /** Individual's primary address. */
  primaryAddress?: Maybe<Address>;
  /** Individual's primary email. */
  primaryEmail?: Maybe<Email>;
  primaryExpertise?: Maybe<Expertise>;
  primaryLanguage: Scalars['String'];
  primaryPhone?: Maybe<PhoneNumber>;
  primaryProfession?: Maybe<Profession>;
  programs: Array<Program>;
  recognitionOptOutFlag?: Maybe<Scalars['Boolean']>;
  /** Indicates whether user have requested their personal information to not be shown. */
  restrictInfo?: Maybe<Scalars['Boolean']>;
  /** Indicates whether user have requested their photo to not be shown. */
  restrictPhoto?: Maybe<Scalars['Boolean']>;
  resumeRecognitions: Array<RecognitionItem>;
  /**
   * The individual's roles grouped by organizations.
   * The list is ordered descending by the biggest startDate within the organization roles.
   * Roles are both membership and leadership roles.
   */
  resumeRoles: ResumeRoles;
  resumeSponsorships: SponsorshipResult;
  /** Individual's riId. */
  riIndividualId?: Maybe<Scalars['Int']>;
  /** The extended permissions are only available if user can edit them. */
  sharingPermissionsExtended?: Maybe<SharingPermissionsExtended>;
  /** The regular permissions are only available if extended permissions are missing. */
  sharingPermissionsRegular?: Maybe<SharingPermissionsRegular>;
  suffix?: Maybe<Scalars['String']>;
  yearOfBirth?: Maybe<Scalars['Int']>;
  youthFlag?: Maybe<Scalars['Boolean']>;
};


/**
 * Individual object for the getIndividual query,
 * with IndividualSearchResult properties.
 */
export type IndividualClubAffiliationsArgs = {
  filters?: Maybe<ClubAffiliationFilters>;
};


/**
 * Individual object for the getIndividual query,
 * with IndividualSearchResult properties.
 */
export type IndividualResumeSponsorshipsArgs = {
  profileIndividualId: Scalars['String'];
};

export type IndividualAddress = {
  __typename?: 'IndividualAddress';
  AddressLine1?: Maybe<Scalars['String']>;
  AddressLine2?: Maybe<Scalars['String']>;
  AddressLine3?: Maybe<Scalars['String']>;
  /** Member Address Details */
  AddressType?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  InternationalProvince?: Maybe<Scalars['String']>;
  IsPrimary?: Maybe<Scalars['Boolean']>;
  Key?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
  MemberId?: Maybe<Scalars['Int']>;
  PostalCode?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
};

export type IndividualAffiliationInfo = {
  __typename?: 'IndividualAffiliationInfo';
  clubs: Array<ShortClubInfo>;
  districts: Array<DesDistrictInfo>;
};

export type IndividualDesResult = {
  __typename?: 'IndividualDESResult';
  affiliationInfo?: Maybe<IndividualAffiliationInfo>;
};

export type IndividualDetails = {
  __typename?: 'IndividualDetails';
  FirstName?: Maybe<Scalars['String']>;
  LastName?: Maybe<Scalars['String']>;
  LocalizedName?: Maybe<Scalars['String']>;
  MemberToken?: Maybe<Scalars['String']>;
};

export type IndividualEditPermissions = {
  __typename?: 'IndividualEditPermissions';
  areasOfExpertise?: Maybe<Scalars['Boolean']>;
  background?: Maybe<Scalars['Boolean']>;
  contactInformation?: Maybe<Scalars['Boolean']>;
  photo?: Maybe<Scalars['Boolean']>;
  professionalExperience?: Maybe<Scalars['Boolean']>;
  recognition?: Maybe<Scalars['Boolean']>;
  rotaryPrograms?: Maybe<Scalars['Boolean']>;
};

export type IndividualEmail = {
  __typename?: 'IndividualEmail';
  EmailAddress?: Maybe<Scalars['String']>;
  EmailType?: Maybe<Scalars['String']>;
  IsOnlineId?: Maybe<Scalars['Boolean']>;
  IsPrimary?: Maybe<Scalars['Boolean']>;
  Key?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
  MemberId?: Maybe<Scalars['Int']>;
};

export type IndividualInfo = {
  __typename?: 'IndividualInfo';
  BadgeName?: Maybe<Scalars['String']>;
  Classification?: Maybe<Scalars['String']>;
  DateOfBirth?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  Gender?: Maybe<Scalars['String']>;
  GenderDescription?: Maybe<Scalars['String']>;
  Key?: Maybe<Scalars['String']>;
  LastName?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
  LocalizedName?: Maybe<Scalars['String']>;
  /** Member Details */
  MemberId?: Maybe<Scalars['Int']>;
  MiddleName?: Maybe<Scalars['String']>;
  Prefix?: Maybe<Scalars['String']>;
  PrimaryLanguage?: Maybe<Scalars['String']>;
  Suffix?: Maybe<Scalars['String']>;
  YouthFlag?: Maybe<Scalars['Boolean']>;
};

export type IndividualLanguage = {
  __typename?: 'IndividualLanguage';
  language: Scalars['String'];
  languageId: Scalars['String'];
};

/** Represents Member Details based on parameters : MemberId. */
export type IndividualMemberDetailsResult = {
  __typename?: 'IndividualMemberDetailsResult';
  BadgeName?: Maybe<Scalars['String']>;
  Classification?: Maybe<Scalars['String']>;
  DateOfBirth?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  Gender?: Maybe<Scalars['String']>;
  GenderDescription?: Maybe<Scalars['String']>;
  Key?: Maybe<Scalars['String']>;
  LastName?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
  LocalizedName?: Maybe<Scalars['String']>;
  /** Member Details */
  MemberId?: Maybe<Scalars['Int']>;
  MiddleName?: Maybe<Scalars['String']>;
  Prefix?: Maybe<Scalars['String']>;
  PrimaryLanguage?: Maybe<Scalars['String']>;
  Suffix?: Maybe<Scalars['String']>;
  YouthFlag?: Maybe<Scalars['Boolean']>;
};

/** Current user's access levels for operations on members. */
export type IndividualOperationsAccessGql = {
  __typename?: 'IndividualOperationsAccessGql';
  assignRole?: Maybe<AccessLevel>;
  editMember?: Maybe<AccessLevel>;
  editMembershipType?: Maybe<AccessLevel>;
  terminateMembership?: Maybe<AccessLevel>;
};

export type IndividualOrganization = {
  __typename?: 'IndividualOrganization';
  Id?: Maybe<Scalars['Int']>;
  Key?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type IndividualPhone = {
  __typename?: 'IndividualPhone';
  CountryCode?: Maybe<Scalars['String']>;
  IsPrimary?: Maybe<Scalars['Boolean']>;
  Key?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
  MemberId?: Maybe<Scalars['Int']>;
  PhoneCountryName?: Maybe<Scalars['String']>;
  PhoneExtension?: Maybe<Scalars['String']>;
  PhoneNumber?: Maybe<Scalars['String']>;
  PhoneType?: Maybe<Scalars['String']>;
};

export type IndividualPhoneInput = {
  /** Id of the country as returned by the countries field. */
  countryId: Scalars['String'];
  /** Only allows digits, spaces, dashs, slashes, commas parentheses and dots Can ONLY start with either a numeric or open parentheses. Maximum length is 10. */
  extension?: Maybe<Scalars['String']>;
  /** Only allows digits, spaces, dashs, slashes, commas parentheses and dots. Can ONLY start with either a numeric or open parentheses. Number/extension/type combination must not already exist for this individual. Duplicate checking must remove all non-numeric characters. Length limit is between 1 and 30. */
  number: Scalars['String'];
  /** Phone type. Must be one of Business, Home, Mobile. If not provided, set to Mobile. */
  type: PhoneType;
};

/** Represents Member Details based on parameters : MemberId. */
export type IndividualProfileDetailsResult = {
  __typename?: 'IndividualProfileDetailsResult';
  Address?: Maybe<Array<Maybe<IndividualAddress>>>;
  Email?: Maybe<Array<Maybe<IndividualEmail>>>;
  Individual?: Maybe<IndividualInfo>;
  Language?: Maybe<Array<Maybe<LanguageDetails>>>;
  Phone?: Maybe<Array<Maybe<IndividualPhone>>>;
};

/** Represents Member Role Details based on Organiazation. */
export type IndividualRole = {
  __typename?: 'IndividualRole';
  IsDelegated?: Maybe<Scalars['Boolean']>;
  IsUnverified?: Maybe<Scalars['Boolean']>;
  Key?: Maybe<Scalars['String']>;
  /** Member Role Details */
  Name?: Maybe<Scalars['String']>;
  RoleEventKey?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
};

/** Member search result object, as they are returned for search query */
export type IndividualSearchResult = {
  __typename?: 'IndividualSearchResult';
  activeLeaderships: Array<Leadership>;
  /** Can have only one Member, but multiple Honorary. */
  activeMemberships: Array<ActiveMembership>;
  /** Individual's primary address. */
  address?: Maybe<Address>;
  /** Individual's primary email address. */
  email?: Maybe<Scalars['String']>;
  /** Individual's UUID. */
  id: Scalars['String'];
  /** Individual's location. */
  location?: Maybe<Scalars['String']>;
  /** Individual's Full name. */
  name: Scalars['String'];
  /**
   * The members phone number, if visible.
   * Format: +[prefix] [number]
   */
  phoneNumber?: Maybe<Scalars['String']>;
  /** The URL for individual's photo. */
  photoUri?: Maybe<Scalars['String']>;
  /** Individual's riIndividualId. */
  riIndividualId?: Maybe<Scalars['String']>;
  /** fields that shared with me if I am club officer */
  sharingPermission?: Maybe<SharingPermissionResult>;
};


/** Member search result object, as they are returned for search query */
export type IndividualSearchResultActiveLeadershipsArgs = {
  clubId?: Maybe<Scalars['String']>;
  riClubId?: Maybe<Scalars['String']>;
};


/** Member search result object, as they are returned for search query */
export type IndividualSearchResultActiveMembershipsArgs = {
  clubId?: Maybe<Scalars['String']>;
  riClubId?: Maybe<Scalars['String']>;
};

/** Member search result object, as they are returned for search query */
export type IndividualSearchResults = {
  __typename?: 'IndividualSearchResults';
  /** Object containing all results returned from the search.  */
  results: Array<IndividualSearchResult>;
  /** Total number of results.  */
  totalCount: Scalars['Int'];
};

/** Represents Security group Details based on parameters : MemberId. */
export type IndividualSecurityGroupsResult = {
  __typename?: 'IndividualSecurityGroupsResult';
  Key?: Maybe<Scalars['String']>;
  /** Security group Details */
  Name?: Maybe<Scalars['String']>;
  Organizations?: Maybe<Array<Maybe<IndividualOrganization>>>;
  Roles?: Maybe<Array<Maybe<IndividualRole>>>;
  TermAccessStatus?: Maybe<Scalars['String']>;
};

export type InputAccomodationPhone = {
  countryId: Scalars['String'];
  extention?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  type: Scalars['String'];
};

export type InputConferenceAddress = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  internationalProvince?: Maybe<Scalars['String']>;
  lineOne?: Maybe<Scalars['String']>;
  lineThree?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type InputCreateConference = {
  comments?: Maybe<Scalars['String']>;
  conferenceAddress?: Maybe<InputConferenceAddress>;
  detailForJoiningRemotely?: Maybe<Scalars['String']>;
  districtId: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  isPresRepRequested?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  onlineLocation?: Maybe<Scalars['String']>;
  participatingDistricts?: Maybe<Array<ParticipatingDistrictsInput>>;
  prAccommodation?: Maybe<Scalars['String']>;
  prAddress?: Maybe<InputConferenceAddress>;
  presRepAccPhone?: Maybe<InputAccomodationPhone>;
  startDate?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  venueName?: Maybe<Scalars['String']>;
  venueType: Scalars['String'];
};

export type InputCreateFeedback = {
  conferenceId: Scalars['String'];
  feedbackType: Scalars['String'];
  questions: Array<InputQuestion>;
  questionsResponse: Array<InputQuestionResponse>;
};

export type InputFeedbackSection = {
  id: Scalars['String'];
  sectionName: Scalars['String'];
};

export type InputOptionDetail = {
  id: Scalars['String'];
  optionValue: Scalars['String'];
};

export type InputOptionType = {
  id: Scalars['String'];
  optionType: Scalars['String'];
};

export type InputQuestion = {
  applicationFeature: Scalars['String'];
  applicationId: Scalars['String'];
  feedbackSection: InputFeedbackSection;
  id: Scalars['String'];
  option: Scalars['String'];
  optionDataType: Scalars['String'];
  optionDetail: InputOptionDetail;
  optionSeq: Scalars['Int'];
  optionType: InputOptionType;
};

export type InputQuestionResponse = {
  questionId: Scalars['String'];
  value: Scalars['String'];
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  amount: Scalars['String'];
  checked: Scalars['Boolean'];
  date: Scalars['String'];
  description: Scalars['String'];
  exchangeRateBillingtoUSD: Scalars['String'];
  exchangeRateLocaltoUSD: Scalars['String'];
  exchangeRateUSDtoBilling: Scalars['String'];
  exchangeRateUSDtoLocal: Scalars['String'];
  itemNumber: Scalars['String'];
};

export type LeLegalEntityTranslations = {
  __typename?: 'LELegalEntityTranslations';
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  LanguageCode?: Maybe<Scalars['String']>;
  LegalEntityKey?: Maybe<Scalars['String']>;
  LegalEntityTranslationsKey?: Maybe<Scalars['String']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
  TranslatedName?: Maybe<Scalars['String']>;
};

/** Represents a language. */
export type Language = {
  __typename?: 'Language';
  /** The id of the language. */
  id: Scalars['String'];
  /** The name of the language. */
  name: Scalars['String'];
};

export type LanguageDetails = {
  __typename?: 'LanguageDetails';
  IsPrimary?: Maybe<Scalars['Boolean']>;
  Key?: Maybe<Scalars['String']>;
  LanguageName?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
  MemberId?: Maybe<Scalars['Int']>;
  Proficiency?: Maybe<Scalars['String']>;
  Read?: Maybe<Scalars['Boolean']>;
  Speak?: Maybe<Scalars['Boolean']>;
};

/** Represents a language. */
export type LanguageExtended = {
  __typename?: 'LanguageExtended';
  /** The id of the language. */
  id: Scalars['String'];
  /** The name of the language. */
  name: Scalars['String'];
  /** Two letter code of the language. This one should NOT be used when writing data to DIS. */
  shortCode: Scalars['String'];
};

export type LanguageInput = {
  isPrimary: Scalars['Boolean'];
  languageId: Scalars['String'];
};

export type Leadership = {
  __typename?: 'Leadership';
  clubId: Scalars['String'];
  clubName: Scalars['String'];
  /** District ID. */
  clubType: Scalars['String'];
  districtId: Scalars['String'];
  endDate: Scalars['String'];
  id: Scalars['String'];
  role: Scalars['String'];
  roleId: Scalars['String'];
  startDate: Scalars['String'];
};

export type LeadershipAffiliation = {
  __typename?: 'LeadershipAffiliation';
  isDelegated?: Maybe<Scalars['Boolean']>;
  leadershipRole: Scalars['String'];
};

export type LeadershipEmbeddedDataBase = {
  __typename?: 'LeadershipEmbeddedDataBase';
  organization?: Maybe<OrganisationDataBase>;
};

export type LeadershipResponseResult = {
  __typename?: 'LeadershipResponseResult';
  _embedded?: Maybe<LeadershipEmbeddedDataBase>;
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDelegated?: Maybe<Scalars['Boolean']>;
  role: Scalars['String'];
  roleId: Scalars['String'];
  startDate: Scalars['String'];
};

export type LegalEntity = {
  __typename?: 'LegalEntity';
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  LegalEntityAbbr?: Maybe<Scalars['String']>;
  LegalEntityKey?: Maybe<Scalars['String']>;
  LegalEntityName?: Maybe<Scalars['String']>;
  LegalEntityTranslations?: Maybe<Array<Maybe<LegalEntityTranslations>>>;
  ModifiedByUser?: Maybe<Scalars['String']>;
};

/** Represents LegalEntitiesResult. */
export type LegalEntityResult = {
  __typename?: 'LegalEntityResult';
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  LegalEntityAbbr?: Maybe<Scalars['String']>;
  LegalEntityKey?: Maybe<Scalars['String']>;
  LegalEntityName?: Maybe<Scalars['String']>;
  LegalEntityTranslations?: Maybe<Array<Maybe<LeLegalEntityTranslations>>>;
  ModifiedByUser?: Maybe<Scalars['String']>;
};

export type LegalEntityTranslations = {
  __typename?: 'LegalEntityTranslations';
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  LanguageCode?: Maybe<Scalars['String']>;
  LegalEntityKey?: Maybe<Scalars['String']>;
  LegalEntityTranslationsKey?: Maybe<Scalars['String']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
  TranslatedName?: Maybe<Scalars['String']>;
};

export type Link = {
  __typename?: 'Link';
  href?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
};

export type Links = {
  __typename?: 'Links';
  individual?: Maybe<Link>;
};

export type Location = {
  __typename?: 'Location';
  Country?: Maybe<Scalars['String']>;
  InternationalProvince?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
};

export type LocationData = {
  __typename?: 'LocationData';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  internationalProvince?: Maybe<Scalars['String']>;
  lineOne?: Maybe<Scalars['String']>;
  lineThree?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
};

export type LocationDetails = {
  __typename?: 'LocationDetails';
  /** Location Details */
  City?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  InternationalProvince?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
};

export type LocationInfo = {
  __typename?: 'LocationInfo';
  City?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  InternationalProvince?: Maybe<Scalars['String']>;
};

export enum LogLevel {
  Debug = 'debug',
  Error = 'error',
  Info = 'info',
  Warn = 'warn'
}

/** The returned object for a meetings, when retrieving a club. */
export type Meeting = {
  __typename?: 'Meeting';
  address?: Maybe<BaseAddress>;
  comment?: Maybe<Scalars['String']>;
  detailForJoiningRemotely?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  languageId?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  locationNameOrDetail?: Maybe<Scalars['String']>;
  locationVaries: Scalars['Boolean'];
  longitude?: Maybe<Scalars['Float']>;
  meetingId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  onlineLocation?: Maybe<Scalars['String']>;
  time: Scalars['String'];
  type: MeetingType;
  weekday: WeekdayFull;
};

/** Input for the meetings field when using the updateClub mutation. */
export type MeetingInput = {
  address?: Maybe<BaseAddressInput>;
  comment?: Maybe<Scalars['String']>;
  detailForJoiningRemotely?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  languageId?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  locationNameOrDetail?: Maybe<Scalars['String']>;
  locationVaries?: Maybe<Scalars['Boolean']>;
  longitude?: Maybe<Scalars['Float']>;
  meetingId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  onlineLocation?: Maybe<Scalars['String']>;
  time: Scalars['String'];
  type: MeetingType;
  weekday: WeekdayFull;
};

export enum MeetingType {
  Hybrid = 'Hybrid',
  Online = 'Online',
  Physical = 'Physical'
}

export enum MeetingTypeFilterValue {
  Hybrid = 'Hybrid',
  Online = 'Online',
  Physical = 'Physical'
}

/** Represents Member Details based on parameters : MemberId. */
export type MemberOrganizationDetailsResult = {
  __typename?: 'MemberOrganizationDetailsResult';
  BadgeName?: Maybe<Scalars['String']>;
  Clubs?: Maybe<Array<Maybe<ClubDetails>>>;
  CreditOrg?: Maybe<CreditOrgDetails>;
  DigitalLanguage?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  IsDeceased?: Maybe<Scalars['Boolean']>;
  IsOnline?: Maybe<Scalars['Boolean']>;
  IsYouth?: Maybe<Scalars['Boolean']>;
  /** Member Details */
  Key?: Maybe<Scalars['String']>;
  LastName?: Maybe<Scalars['String']>;
  Location?: Maybe<LocationDetails>;
  MemberId?: Maybe<Scalars['Int']>;
  MiddleName?: Maybe<Scalars['String']>;
  Prefix?: Maybe<Scalars['String']>;
  Suffix?: Maybe<Scalars['String']>;
};

/** Member search result object, as they are returned for search query */
export type MemberSearchResult = {
  __typename?: 'MemberSearchResult';
  activeLeaderships: Array<Leadership>;
  /** Active club affiliation by order, fist showing club aff from current district */
  activeMembershipInAllDistricts?: Maybe<ActiveMembership>;
  /** Can have only one Member, but multiple Honorary. */
  activeMemberships: Array<ActiveMembership>;
  /** Individual's primary address. */
  address?: Maybe<Address>;
  associatedClubsInfo: Array<Club>;
  clubMemberships: Array<ActiveMembership>;
  /** The member's deceased date. */
  deceasedDate?: Maybe<Scalars['String']>;
  /** Individual's primary email address. */
  email?: Maybe<Scalars['String']>;
  /** Individual's UUID. */
  id: Scalars['String'];
  /** Tells if a given member is deceased. */
  isDeceased?: Maybe<Scalars['Boolean']>;
  /** Individual's localized name. */
  localizedName?: Maybe<Scalars['String']>;
  /** Individual's location. */
  location?: Maybe<Scalars['String']>;
  /** Membership ID. Available when executed in the club context. */
  membershipId?: Maybe<Scalars['String']>;
  /** Individual's Full name. */
  name: Scalars['String'];
  /** Individual's Full name with prefix and suffix. */
  nameWithPrefixSuffix: Scalars['String'];
  onlineId?: Maybe<Scalars['String']>;
  /** Access levels for operations for the current user. */
  operationsAccess: IndividualOperationsAccessGql;
  otherEmails?: Maybe<Array<Scalars['String']>>;
  /**
   * The members phone number, if visible.
   * Format: +[prefix] [number]
   */
  phoneNumber?: Maybe<Scalars['String']>;
  /** The URL for individual's photo. */
  photoUri?: Maybe<Scalars['String']>;
  preferredLanguage?: Maybe<Language>;
  /** Individual's riIndividualId. */
  riIndividualId: Scalars['String'];
  /** Individual's sharing permission for fields name, email, phone etc. */
  sharingPermissionsResult: SharingPermissionsResult;
  thisClubLeadership: Array<Leadership>;
  thisDistrictLeadership: Array<DistrictLeadership>;
};


/** Member search result object, as they are returned for search query */
export type MemberSearchResultActiveLeadershipsArgs = {
  clubId?: Maybe<Scalars['String']>;
  riClubId?: Maybe<Scalars['String']>;
};


/** Member search result object, as they are returned for search query */
export type MemberSearchResultActiveMembershipInAllDistrictsArgs = {
  clubId?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  riClubId?: Maybe<Scalars['String']>;
};


/** Member search result object, as they are returned for search query */
export type MemberSearchResultActiveMembershipsArgs = {
  clubId?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  riClubId?: Maybe<Scalars['String']>;
};


/** Member search result object, as they are returned for search query */
export type MemberSearchResultAssociatedClubsInfoArgs = {
  district?: Maybe<Scalars['String']>;
};


/** Member search result object, as they are returned for search query */
export type MemberSearchResultClubMembershipsArgs = {
  clubId?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  riClubId?: Maybe<Scalars['String']>;
};


/** Member search result object, as they are returned for search query */
export type MemberSearchResultThisClubLeadershipArgs = {
  clubId?: Maybe<Scalars['String']>;
  riClubId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};


/** Member search result object, as they are returned for search query */
export type MemberSearchResultThisDistrictLeadershipArgs = {
  district?: Maybe<Scalars['String']>;
  includeDLHistory?: Maybe<Scalars['Boolean']>;
};

/** Member search result object, as they are returned for search query */
export type MemberSearchResults = {
  __typename?: 'MemberSearchResults';
  /** Object containing all results returned from the search.  */
  results: Array<MemberSearchResult>;
  /** Total number of results.  */
  totalCount: Scalars['Int'];
};

export type MembersTrends = {
  __typename?: 'MembersTrends';
  july1st: Scalars['Int'];
  monthlyData: Array<MonthlyData>;
  totalCount: Scalars['Int'];
  year: Scalars['Int'];
};

export type Membership = {
  __typename?: 'Membership';
  admissionDate: Scalars['String'];
  club: Club;
  id: Scalars['String'];
  individual: Individual;
  terminationDate?: Maybe<Scalars['String']>;
  type: MembershipType;
};

export type MembershipAffiliation = {
  __typename?: 'MembershipAffiliation';
  admissionDate: Scalars['String'];
  id: Scalars['String'];
  membershipType: MembershipType;
  terminationDate?: Maybe<Scalars['String']>;
};

export type MembershipEmbeddedDataBase = {
  __typename?: 'MembershipEmbeddedDataBase';
  sponsors?: Maybe<Array<Maybe<SubSponser>>>;
};

export type MembershipForAddMember = {
  __typename?: 'MembershipForAddMember';
  clubId: Scalars['String'];
  clubType: Scalars['String'];
  terminationDate?: Maybe<Scalars['String']>;
  type: MembershipType;
};

export type MembershipHistoryBase = {
  __typename?: 'MembershipHistoryBase';
  _embedded?: Maybe<MembershipEmbeddedDataBase>;
  _links?: Maybe<Links>;
  admissionDate: Scalars['String'];
  id: Scalars['String'];
  terminationDate?: Maybe<Scalars['String']>;
  terminationReason?: Maybe<Scalars['String']>;
  terminationReasonId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export enum MembershipStatusFilter {
  Active = 'Active',
  Deceased = 'Deceased',
  Honorary = 'Honorary',
  HonoraryRotaractor = 'Honorary_Rotaractor',
  Rotaractor = 'Rotaractor',
  Terminated = 'Terminated'
}

export enum MembershipType {
  Honorary = 'Honorary',
  Member = 'Member',
  RotaractHonorary = 'Rotaract_Honorary',
  Rotaractor = 'Rotaractor'
}

export type MembershipsList = {
  __typename?: 'MembershipsList';
  admissionDate?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  terminationDate?: Maybe<Scalars['String']>;
  terminationReason?: Maybe<Scalars['String']>;
  terminationReasonId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type MonthlyData = {
  __typename?: 'MonthlyData';
  count?: Maybe<Scalars['Int']>;
  fiscalMonth: Scalars['Int'];
  month: Scalars['String'];
};

export type MovedItem = {
  affiliationId: Scalars['String'];
  affiliationStartDate?: Maybe<Scalars['String']>;
  clubId: Scalars['String'];
  clubName: Scalars['String'];
  destinationId: Scalars['String'];
  destinationName: Scalars['String'];
  sourceId: Scalars['String'];
  sourceName: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Validates the token and returns the account data associated with it. Defined as mutation to avoid any possible caching. */
  accountInfoByToken: AccountInfoByToken;
  addClubMeeting: AddClubMeetingResult;
  addClubVendor: AddClubVendorResult;
  addDelegation: GetAddDelegationResult;
  /** Add a Ecard */
  addEcardDetails?: Maybe<EcardAddResult>;
  /** Add a Fiscal Code */
  addFiscalCode?: Maybe<FiscalCodeInfoResult>;
  /** Add a Pan */
  addPan?: Maybe<PanInfoResult>;
  /** Add a RRN */
  addRRN?: Maybe<RrnInfoResult>;
  /** Terminate a membership */
  addSponsor?: Maybe<Scalars['Void']>;
  assignDistrictLeadership: DistrictLeadershipResult;
  /** Cancel subscription based on parameters : SubscriptionId,  */
  cancelSubscriptionForMember?: Maybe<CancelSubscriptionResponse>;
  /** Changes the password of the currently logged in user. */
  changePassword?: Maybe<Scalars['Void']>;
  /** Changes the password of the currently logged in user. */
  changeSecurityQuestion?: Maybe<Scalars['Void']>;
  /** Sends the confirmation email for changing the SignIn email address. */
  changeSigninEmail?: Maybe<Scalars['Void']>;
  /** Submit ContactUs form and send email */
  contactUs?: Maybe<Scalars['Void']>;
  /** Creates an OKTA account and sends the activation email. */
  createAccount?: Maybe<Scalars['Void']>;
  createConference?: Maybe<Scalars['Void']>;
  createFeedback?: Maybe<Scalars['Void']>;
  createIndividual: CreateOrUpdateIndividualResult;
  /** Creates an individual from the logged in user. */
  createIndividualFromSession?: Maybe<Scalars['Void']>;
  createIndividualProgram: Program;
  /** Terminate a membership */
  createLeadership: CreateLeadershipResult;
  /** Terminate a membership */
  createMembership: CreateMembershipResult;
  deleteClubMeeting: DeleteClubMeetingResult;
  deleteConference?: Maybe<Scalars['Void']>;
  deleteDelegation?: Maybe<Scalars['Void']>;
  deleteDistrictLeadership: DistrictLeadershipResult;
  /** Returns the individualProgramId of the deleted individual program. */
  deleteIndividualProgram: Scalars['String'];
  /** Deletes the person's photo. */
  deletePhoto?: Maybe<Scalars['Void']>;
  editConference?: Maybe<Scalars['Void']>;
  editDelegation: GetEditedDelegationResult;
  editFeedback?: Maybe<Scalars['Void']>;
  editMeeting: EditMeetingResult;
  /** Finalizes the account creation process and activates it. */
  finalizeAccountCreation?: Maybe<Scalars['Void']>;
  /** Finalizes the change of the SignIn email. */
  finalizeChangeOfSigninEmail?: Maybe<Scalars['Void']>;
  mapByDisEmail: Scalars['Boolean'];
  moveAgClubAffiliations: ClubAssignmentsResponse;
  replaceAgClubAffiliations: AgResult;
  /** Re-sends the activation message with a fresh token. */
  resendActivationEmail?: Maybe<Scalars['Void']>;
  /** Reset any modified example data to its initial state. */
  reset?: Maybe<Scalars['Void']>;
  sendLog?: Maybe<Scalars['Void']>;
  /** Sends a password recovery message if the email is found. */
  sendPasswordResetMessage?: Maybe<Scalars['Void']>;
  terminateClubVendor: TerminateClubVendorResult;
  /** Terminate a Leadership */
  terminateLeadership?: Maybe<Scalars['Void']>;
  /** Terminate a membership */
  terminateMembership?: Maybe<Scalars['Void']>;
  updateAgClubAffiliations: AgResult;
  updateClub: UpdateClubResult;
  updateContactInfo: CreateOrUpdateIndividualResult;
  updateDistrictDetails?: Maybe<Scalars['Void']>;
  updateDistrictLeadership: DistrictLeadershipResult;
  updateIndividual: CreateOrUpdateIndividualResult;
  updateIndividualProgram: Program;
  /** Change a membership type Member <-> Honorary */
  updateMembershipType: UpdateMembershipTypeResult;
  /** Updates the user's password based on the token and answer to the security question. */
  updatePassword?: Maybe<Scalars['Void']>;
  /** Uploads a new photo for the given individual. */
  uploadPhoto?: Maybe<Scalars['String']>;
};


export type MutationAccountInfoByTokenArgs = {
  token: Scalars['String'];
};


export type MutationAddClubMeetingArgs = {
  input: AddClubMeetingInput;
};


export type MutationAddClubVendorArgs = {
  input: AddClubVendorInput;
};


export type MutationAddDelegationArgs = {
  input: AddDelegationInput;
};


export type MutationAddEcardDetailsArgs = {
  DedicatedTo: Scalars['String'];
  DedicationType: Scalars['Int'];
  DonorName: Scalars['String'];
  ImageFid: Scalars['Int'];
  NotificationType: Scalars['Int'];
  PersonalMsg: Scalars['String'];
};


export type MutationAddFiscalCodeArgs = {
  collectionDate: Scalars['String'];
  fiscalCode: Scalars['String'];
  netForumMemberId: Scalars['String'];
};


export type MutationAddPanArgs = {
  netForumMemberId: Scalars['String'];
  panId: Scalars['String'];
};


export type MutationAddRrnArgs = {
  collectionDate: Scalars['String'];
  netForumMemberId: Scalars['String'];
  rrnId: Scalars['String'];
};


export type MutationAddSponsorArgs = {
  clubId: Scalars['String'];
  membershipId: Scalars['String'];
  sponsorIndividualId?: Maybe<Array<Scalars['String']>>;
};


export type MutationAssignDistrictLeadershipArgs = {
  districtId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  individualId: Scalars['String'];
  riDistrictId: Scalars['String'];
  roleId: Scalars['String'];
  startDate: Scalars['String'];
};


export type MutationCancelSubscriptionForMemberArgs = {
  MerchantConfig: Scalars['String'];
  SubscriptionId: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationChangeSecurityQuestionArgs = {
  answer: Scalars['String'];
  currentPassword: Scalars['String'];
  question: Scalars['String'];
};


export type MutationChangeSigninEmailArgs = {
  currentPassword: Scalars['String'];
  langcode: Scalars['String'];
  newEmail: Scalars['String'];
  recaptchaToken?: Maybe<Scalars['String']>;
};


export type MutationContactUsArgs = {
  contactForm: ContactFormInput;
  email: Scalars['String'];
  langcode: Scalars['String'];
  recaptchaToken?: Maybe<Scalars['String']>;
  supportCenterEmail: Scalars['String'];
};


export type MutationCreateAccountArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  langcode: Scalars['String'];
  lastName: Scalars['String'];
  recaptchaToken?: Maybe<Scalars['String']>;
  yearOfBirth?: Maybe<Scalars['Int']>;
  youthFlag: Scalars['Boolean'];
};


export type MutationCreateConferenceArgs = {
  input: InputCreateConference;
};


export type MutationCreateFeedbackArgs = {
  input: InputCreateFeedback;
};


export type MutationCreateIndividualArgs = {
  input: CreateIndividualInput;
};


export type MutationCreateIndividualFromSessionArgs = {
  langcode: Scalars['String'];
};


export type MutationCreateIndividualProgramArgs = {
  input: CreateIndividualProgramInput;
};


export type MutationCreateLeadershipArgs = {
  clubId: Scalars['String'];
  endDate: Scalars['String'];
  individualId: Scalars['String'];
  roleId: Scalars['String'];
  startDate: Scalars['String'];
};


export type MutationCreateMembershipArgs = {
  admissionDate: Scalars['String'];
  clubId: Scalars['String'];
  individualId: Scalars['String'];
  sponsorIds?: Maybe<Array<Scalars['String']>>;
  type: Scalars['String'];
};


export type MutationDeleteClubMeetingArgs = {
  input: DeleteClubMeetingInput;
};


export type MutationDeleteConferenceArgs = {
  conferenceId: Scalars['String'];
};


export type MutationDeleteDelegationArgs = {
  input: DeleteDelegationInput;
};


export type MutationDeleteDistrictLeadershipArgs = {
  districtId: Scalars['String'];
  leadershipId: Scalars['String'];
};


export type MutationDeleteIndividualProgramArgs = {
  input: DeleteIndividualProgramInput;
};


export type MutationDeletePhotoArgs = {
  individualId: Scalars['String'];
};


export type MutationEditConferenceArgs = {
  conferenceId: Scalars['String'];
  input: InputCreateConference;
};


export type MutationEditDelegationArgs = {
  input: EditDelegationInput;
};


export type MutationEditFeedbackArgs = {
  feedbackId: Scalars['String'];
  input: InputCreateFeedback;
};


export type MutationEditMeetingArgs = {
  input: EditMeetingInput;
};


export type MutationFinalizeAccountCreationArgs = {
  accountId: Scalars['String'];
  answer: Scalars['String'];
  password: Scalars['String'];
  question: Scalars['String'];
  token: Scalars['String'];
};


export type MutationFinalizeChangeOfSigninEmailArgs = {
  token: Scalars['String'];
};


export type MutationMapByDisEmailArgs = {
  disEmail: Scalars['String'];
};


export type MutationMoveAgClubAffiliationsArgs = {
  districtId: Scalars['String'];
  movedItems: Array<MovedItem>;
  startDate: Scalars['String'];
};


export type MutationReplaceAgClubAffiliationsArgs = {
  districtId: Scalars['String'];
  individualId: Scalars['String'];
  newIndividualId: Scalars['String'];
  startDate: Scalars['String'];
};


export type MutationResendActivationEmailArgs = {
  email: Scalars['String'];
  langcode: Scalars['String'];
};


export type MutationSendLogArgs = {
  level: LogLevel;
  message: Scalars['String'];
  other?: Maybe<Scalars['String']>;
};


export type MutationSendPasswordResetMessageArgs = {
  email: Scalars['String'];
  langcode: Scalars['String'];
};


export type MutationTerminateClubVendorArgs = {
  input: TerminateClubVendorInput;
};


export type MutationTerminateLeadershipArgs = {
  clubId: Scalars['String'];
  leadershipId: Scalars['String'];
  terminationDate: Scalars['String'];
};


export type MutationTerminateMembershipArgs = {
  clubId: Scalars['String'];
  membershipId: Scalars['String'];
  terminationDate: Scalars['String'];
  terminationReasonId?: Maybe<Scalars['String']>;
};


export type MutationUpdateAgClubAffiliationsArgs = {
  districtId: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  individualId: Scalars['String'];
  newIndividualId: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
};


export type MutationUpdateClubArgs = {
  input: UpdateClubInput;
};


export type MutationUpdateContactInfoArgs = {
  input: UpdateContactInfoInput;
};


export type MutationUpdateDistrictDetailsArgs = {
  district: DistrictDetailsInput;
};


export type MutationUpdateDistrictLeadershipArgs = {
  districtId: Scalars['String'];
  isExtraData?: Maybe<Scalars['Boolean']>;
  leadershipId: Scalars['String'];
  terminationDate: Scalars['String'];
};


export type MutationUpdateIndividualArgs = {
  input: UpdateIndividualInput;
};


export type MutationUpdateIndividualProgramArgs = {
  input: UpdateIndividualProgramInput;
};


export type MutationUpdateMembershipTypeArgs = {
  admissionDate: Scalars['String'];
  clubId: Scalars['String'];
  membershipId: Scalars['String'];
  type: Scalars['String'];
};


export type MutationUpdatePasswordArgs = {
  answer: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationUploadPhotoArgs = {
  fileContentsBase64Encoded: Scalars['String'];
  fileMimeType: Scalars['String'];
  fileName: Scalars['String'];
  fileSize: Scalars['String'];
  individualId: Scalars['String'];
};

export type OfficerIndividual = {
  __typename?: 'OfficerIndividual';
  clubId?: Maybe<Scalars['String']>;
  clubLocation?: Maybe<BaseAddress>;
  clubName?: Maybe<Scalars['String']>;
  clubType?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  localizedName?: Maybe<Scalars['String']>;
  membership: OfficerMembership;
  middleName?: Maybe<Scalars['String']>;
  photoUri?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  primaryEmail?: Maybe<Scalars['String']>;
  primaryPhone?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
};

export type OfficerMembership = {
  __typename?: 'OfficerMembership';
  admissionDate?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  terminationDate?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type OktaProfileDataType = {
  __typename?: 'OktaProfileDataType';
  emailAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  registrationForm?: Maybe<Scalars['String']>;
  registrationType?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

/** Represents OpenInvoiceListResult. */
export type OpenInvoiceListResult = {
  __typename?: 'OpenInvoiceListResult';
  billingToUsd: Scalars['String'];
  currency: Scalars['String'];
  list: Array<InvoiceItem>;
  usdToBilling: Scalars['String'];
};

export type OptionDetail = {
  __typename?: 'OptionDetail';
  id: Scalars['String'];
  optionValue?: Maybe<Scalars['String']>;
};

export type OptionType = {
  __typename?: 'OptionType';
  id: Scalars['String'];
  optionType: Scalars['String'];
};

export enum OrgType {
  District = 'District',
  RotaractClub = 'Rotaract_Club',
  RotaryClub = 'Rotary_Club'
}

export type OrganisationDataBase = {
  __typename?: 'OrganisationDataBase';
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type Organization = {
  __typename?: 'Organization';
  Key?: Maybe<Scalars['String']>;
  OrganizationId?: Maybe<Scalars['String']>;
  OrganizationName?: Maybe<Scalars['String']>;
  OrganizationType?: Maybe<Scalars['String']>;
};

export type OrganizationAddress = {
  __typename?: 'OrganizationAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
};

export enum OrganizationBase {
  Community = 'Community',
  School = 'School',
  University = 'University'
}

export type OrganizationData = {
  __typename?: 'OrganizationData';
  dominoId: Scalars['String'];
};

/** Represents Organization Details based on parameters : OrganizationType,OrganizationId. */
export type OrganizationDetailsResult = {
  __typename?: 'OrganizationDetailsResult';
  Addresses?: Maybe<Array<Maybe<AddressInfo>>>;
  CreditOrg?: Maybe<CreditOrg>;
  EmailAddresses?: Maybe<Array<Maybe<EmailAddress>>>;
  /** Organization Details */
  Id?: Maybe<Scalars['Int']>;
  Key?: Maybe<Scalars['String']>;
  Language?: Maybe<Scalars['String']>;
  LegacyTypeId?: Maybe<Scalars['String']>;
  LocalizedName?: Maybe<Scalars['String']>;
  Location?: Maybe<LocationInfo>;
  Name?: Maybe<Scalars['String']>;
  PhoneNumbers?: Maybe<Array<Maybe<PhoneNumbers>>>;
  SponsoredByOrganizations?: Maybe<Array<Maybe<Organization>>>;
  SponsoringOrganizations?: Maybe<Array<Maybe<Organization>>>;
  Type?: Maybe<Scalars['String']>;
  Websites?: Maybe<Array<Maybe<Website>>>;
};

export type ParticipatingDistricts = {
  __typename?: 'ParticipatingDistricts';
  id: Scalars['String'];
  riDistrictId: Scalars['Int'];
};

export type ParticipatingDistrictsInput = {
  id: Scalars['String'];
  riDistrictId: Scalars['Int'];
};

export type Payment = {
  __typename?: 'Payment';
  ExpirationMonth?: Maybe<Scalars['String']>;
  ExpirationYear?: Maybe<Scalars['String']>;
  MaskedCC?: Maybe<Scalars['String']>;
  PaymentType?: Maybe<Scalars['String']>;
};

/** Represents a Payment Profile Name by CountryISO2, CurrencyCode, TransactionTypeAlias, CustomerType, TransactionFlowName. */
export type PaymentProfileNameResult = {
  __typename?: 'PaymentProfileNameResult';
  /** Payment Profile Name. */
  PaymentProfileName?: Maybe<Scalars['String']>;
};

export type PaymentProfileResult = {
  __typename?: 'PaymentProfileResult';
  FoundationID: Scalars['String'];
  FoundationName: Scalars['String'];
  MerchantApiKey: Scalars['String'];
  MerchantId: Scalars['String'];
  WidgetId: Scalars['String'];
  paymentProfileName: Scalars['String'];
};

/** Phone input */
export type PhoneInput = {
  action?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  /** Id of the country as returned by the countries field. */
  countryId: Scalars['String'];
  /** Only allows digits, spaces, dashes, slashes, commas parentheses and dots Can ONLY start with either a numeric or open parentheses. Maximum length is 10. */
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  /** Only allows digits, spaces, dashes, slashes, commas parentheses and dots. Can ONLY start with either a numeric or open parentheses. Number/extension/type combination must not already exist for this individual. Duplicate checking must remove all non-numeric characters. Length limit is between 1 and 30. */
  number: Scalars['String'];
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  country: Scalars['String'];
  countryId: Scalars['String'];
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  type?: Maybe<PhoneType>;
};

export type PhoneNumbers = {
  __typename?: 'PhoneNumbers';
  CountryCode?: Maybe<Scalars['String']>;
  IsPrimary?: Maybe<Scalars['Boolean']>;
  Key?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
  PhoneCountryName?: Maybe<Scalars['String']>;
  PhoneExtension?: Maybe<Scalars['String']>;
  PhoneNumber?: Maybe<Scalars['String']>;
  PhoneType?: Maybe<Scalars['String']>;
};

export enum PhoneType {
  Business = 'Business',
  Home = 'Home',
  Mobile = 'Mobile'
}

export type PhysicalLocation = {
  __typename?: 'PhysicalLocation';
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type PresidentialRepresentative = {
  __typename?: 'PresidentialRepresentative';
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
};

export type PrimaryPhoneInput = {
  country?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  number: Scalars['String'];
};

export type PrivacyPolicyTMessages = {
  __typename?: 'PrivacyPolicyTMessages';
  DE?: Maybe<Scalars['String']>;
  EN?: Maybe<Scalars['String']>;
  ES?: Maybe<Scalars['String']>;
  FR?: Maybe<Scalars['String']>;
  IT?: Maybe<Scalars['String']>;
  JA?: Maybe<Scalars['String']>;
  KO?: Maybe<Scalars['String']>;
  PT?: Maybe<Scalars['String']>;
};

export type Profession = {
  __typename?: 'Profession';
  employer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  occupation?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};

/** At least one of the fields should be filled in. */
export type ProfessionInput = {
  employer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  occupation?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};

export type Program = {
  __typename?: 'Program';
  endDate?: Maybe<Scalars['String']>;
  fields: Array<ProgramField>;
  individualProgramId: Scalars['String'];
  isVerified: Scalars['Boolean'];
  programId: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ProgramDefinition = {
  __typename?: 'ProgramDefinition';
  fieldGroups: Array<ProgramFieldGroup>;
  programId: Scalars['String'];
  title: Scalars['String'];
};

export type ProgramField = {
  __typename?: 'ProgramField';
  fieldId: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type ProgramFieldDefinition = {
  __typename?: 'ProgramFieldDefinition';
  /** Available for STRING type. */
  constraints?: Maybe<FieldConstraints>;
  fieldId: Scalars['String'];
  isRequired: Scalars['Boolean'];
  label: Scalars['String'];
  /** Available for OPTIONS type. */
  options?: Maybe<Array<FieldOption>>;
  type: FieldType;
};

export type ProgramFieldGroup = {
  __typename?: 'ProgramFieldGroup';
  defaultTranslation: Scalars['String'];
  fields: Array<ProgramFieldDefinition>;
  translationKey: Scalars['String'];
};

export type ProgramFieldInput = {
  fieldId: Scalars['String'];
  value: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** This function gets data for an Individual by its ID or riIndividualId. */
  addMemberGetIndividual: AddMemberIndividualResult;
  /** This function gets the available sponsors for the club with the given club ID and given individual ID.  */
  availableSponsors?: Maybe<MemberSearchResults>;
  basicInfo?: Maybe<BasicInfo>;
  cdsRepresentatives?: Maybe<Array<Maybe<CdsRepresentatives>>>;
  /** This function gets data for the club with the given ID.  */
  clubById: Club;
  clubStatusByIds: Array<ClubStatus>;
  /** Constant values. */
  constants: Constants;
  /** Returns all the available countries. */
  countries: Array<Country>;
  currentUser?: Maybe<User>;
  /** Returns all the available display names. */
  displayNames: Array<DisplayNames>;
  /** Returns all the available districts. */
  districts?: Maybe<Array<District>>;
  expertiseAreas: Array<ExpertiseAreaItem>;
  expertiseLevels: Array<ExpertiseLevelItem>;
  financialReprentatives?: Maybe<Array<Maybe<FinancialReprentatives>>>;
  getAccessLevels: Array<GetAccessLevelsResults>;
  getAllAssistantGovernors: Array<AssistantGovernor>;
  getAllDistrictAGsByDate: AssistantGovernorsByDateResults;
  getAllDistrictClubs: DistrictClubsResults;
  getAllDistrictOfficers: DistrictOfficersResults;
  getAllDistricts: Array<DistrictDis3>;
  getAllSharingPermissions: Array<SharingPermission>;
  /** Returns all ApplicationMode */
  getApplicationMode?: Maybe<Array<Maybe<ApplicationModeResult>>>;
  getAssistantGovernorsPaginate: AssistantGovernorsResults;
  /** Returns Bank Account Assignment Information */
  getBankAssignmentInfo?: Maybe<Array<Maybe<BankAssignmentResult>>>;
  /** Returns all categories */
  getCategories?: Maybe<CategoriesResult>;
  getClubBalanceReport?: Maybe<FileContentResult>;
  /** Returns club details by ClubID and ClubType */
  getClubDetails?: Maybe<Array<Maybe<ClubDetailsResult>>>;
  /** Returns invoice with success */
  getClubInvoiceReport: ClubInvoiceResult;
  /** Returns Member Details based on parameters : MemberId */
  getClubMembers?: Maybe<ClubMembersResult>;
  getCommitteeInvitations?: Maybe<Array<Maybe<CommitteeNominations>>>;
  getCommitteeRoles: Array<CommitteeRolesResult>;
  getConference?: Maybe<GetConferenceDetailsResult>;
  getConferences: Array<GetConferencesResults>;
  /** Returns all countries */
  getCountries?: Maybe<Array<Maybe<CountryDetails>>>;
  /** Returns all country currency mapping */
  getCountryCurrencyMapping?: Maybe<Array<Maybe<CountryCurrencyMapped>>>;
  /** Returns all currencies */
  getCurrencies?: Maybe<Array<Maybe<CurrencyDetails>>>;
  getDelegationProfile: GetDelegationProfileResult;
  getDistrictClubs: DistrictClubsResults;
  getDistrictClubsStatistics: Array<DistrictClubsStatistics>;
  getDistrictDashboards: DistrictDashboardsResults;
  getDistrictDetails: DistrictDis3;
  getDistrictMembersStatistics: Array<DistrictMembersStatistics>;
  getDistrictsForLeadership: DistrictsResult;
  getDominoOrganizationId: OrganizationData;
  /** Returns ECard by ECardId */
  getEcardDetails?: Maybe<Array<Maybe<ECardDetailsResult>>>;
  /** Returns all exchange rates */
  getExchangeRates?: Maybe<Array<Maybe<ExchangeRates>>>;
  getFeedbackQuestions: Array<FeedbackQuestionsResponse>;
  /** Returns all funds */
  getFunds?: Maybe<Array<Maybe<Funds>>>;
  /** Returns RRN number by member Id */
  getGin?: Maybe<GinResult>;
  /** Returns grant details by GrantID */
  getGrantDetails?: Maybe<GrantDetailsResult>;
  /** This function gets data for an Individual by its ID or riIndividualId. */
  getIndividual: Individual;
  /** Returns Member Details based on parameters : MemberId */
  getIndividualMemberDetails?: Maybe<IndividualMemberDetailsResult>;
  /** Returns Member Details based on parameters : MemberId */
  getIndividualProfileDetails?: Maybe<IndividualProfileDetailsResult>;
  /** Returns Member Details based on parameters : MemberId */
  getIndividualSecurityGroups?: Maybe<Array<Maybe<IndividualSecurityGroupsResult>>>;
  /** This function gets Leadership History data for an Individual by its ID or riIndividualId. */
  getLeadershipHistory: Array<LeadershipResponseResult>;
  /** Returns all Legal Entities */
  getLegalEntities?: Maybe<Array<Maybe<LegalEntityResult>>>;
  /** Returns Member Details based on parameters : MemberId */
  getMemberOrganizationDetails?: Maybe<MemberOrganizationDetailsResult>;
  getMembership?: Maybe<Membership>;
  /** This function gets data for an Individual by its ID or riIndividualId. */
  getMembershipHistory: Array<MembershipHistoryBase>;
  getNonMemberDES: Array<IndividualSearchResult>;
  getOneFeedback?: Maybe<Feedback>;
  getOnlineIdEmail?: Maybe<Scalars['String']>;
  /** Returns open invoice list */
  getOpenInvoiceList?: Maybe<OpenInvoiceListResult>;
  /** Returns Organization Details based on parameters : OrganizationType,OrganizationId. */
  getOrganizationDetails?: Maybe<OrganizationDetailsResult>;
  /** Returns all payment profiles */
  getPaymentProfile?: Maybe<Array<Maybe<PaymentProfileResult>>>;
  /** Returns Payment Profile Name by CountryISO2, CurrencyCode, TransactionTypeAlias, CustomerType, TransactionFlowName */
  getPaymentProfileName?: Maybe<PaymentProfileNameResult>;
  getPresRepConferences: Array<GetPresRepConferencesResult>;
  getPreviousDES: Array<DistrictOfficer>;
  /** Returns privacy policy translation messages */
  getPrivacyPolicyMessages?: Maybe<PrivacyPolicyTMessages>;
  getPrograms: Array<ProgramDefinition>;
  getRecognitionInfo: RecognitionDataInfo;
  getRegionalGroupDistricts: Array<RegionalGroupDistricts>;
  /** Returns all request source */
  getRequestSource?: Maybe<Array<Maybe<RequestSource>>>;
  getSitecoreReport: Array<Report>;
  /** Returns States by CountryId */
  getStatesByCountry?: Maybe<Array<Maybe<StatesByCountryResult>>>;
  /** Returns all subscriptions Details based on parameters : MemberID, */
  getSubscriptionsForMember?: Maybe<Array<Maybe<Subscriptions>>>;
  getTimeZones: Array<TimeZone>;
  /** Returns Tracked Gift Details based on parameters : TrackedGiftID, DonorType, DonorID, LanguageCode */
  getTrackedGiftDetails?: Maybe<TrackedGiftDetailsResult>;
  /** Returns all TransactionType */
  getTransactionType?: Maybe<Array<Maybe<TransactionTypeResult>>>;
  /** Returns all the available languages. */
  languages: Array<LanguageExtended>;
  /** This function searches for members that match the criteria provided.  */
  memberSearchAdvanced: MemberSearchResults;
  /** Returns all the available club officer roles. */
  roles?: Maybe<Array<Role>>;
  /** This function searches for clubs that match the criteria provided.  */
  searchClub: ClubSearchResults;
  searchClubsInDistrict: DistrictClubsResults;
  searchDESByIndividualId: IndividualDesResult;
  searchDGByRY: DgResult;
  /** This function searches for individuals that match the criteria provided.  */
  searchIndividual: IndividualSearchResults;
  searchPreviousOfficers: MemberSearchResults;
  /** Runs a query on the Solr index with the criteria provided.  */
  searchSolr: SiteSearchResults;
  /** Returns all the available states */
  states: Array<State>;
  /** Returns all the available termination reasons. */
  terminationReasons: Array<TerminationReason>;
  /** Returns all the available vendors. */
  vendors?: Maybe<Array<Vendor>>;
};


export type QueryAddMemberGetIndividualArgs = {
  access?: Maybe<Scalars['String']>;
  clubId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryAvailableSponsorsArgs = {
  admissionDate?: Maybe<Scalars['String']>;
  clubId: Scalars['String'];
  individualId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryBasicInfoArgs = {
  emailId: Scalars['String'];
};


export type QueryClubByIdArgs = {
  clubId: Scalars['String'];
  dominoId?: Maybe<Scalars['String']>;
};


export type QueryClubStatusByIdsArgs = {
  clubIds: Array<Scalars['String']>;
};


export type QueryDisplayNamesArgs = {
  displayLanguage: Scalars['String'];
  displayType: Scalars['String'];
};


export type QueryGetAccessLevelsArgs = {
  options: Array<AccessLevelsOptions>;
};


export type QueryGetAllAssistantGovernorsArgs = {
  districtId: Scalars['String'];
};


export type QueryGetAllDistrictAGsByDateArgs = {
  date?: Maybe<Scalars['String']>;
  districtId: Scalars['String'];
};


export type QueryGetAllDistrictClubsArgs = {
  date?: Maybe<Scalars['String']>;
  districtId: Scalars['String'];
  hasAGAffiliation?: Maybe<Scalars['Boolean']>;
  isExtraData?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
};


export type QueryGetAllDistrictOfficersArgs = {
  districtId: Scalars['String'];
  districtRoles?: Maybe<Array<Scalars['String']>>;
  options?: Maybe<GetAllDistrictOfficersOptions>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  riDistrictId: Scalars['Int'];
  rotaryYear: Scalars['String'];
};


export type QueryGetAllDistrictsArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};


export type QueryGetAssistantGovernorsPaginateArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  districtId: Scalars['String'];
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryGetClubBalanceReportArgs = {
  orgId: Scalars['String'];
  orgType: OrgType;
};


export type QueryGetClubDetailsArgs = {
  ClubId: Scalars['String'];
  ClubType: Scalars['String'];
};


export type QueryGetClubInvoiceReportArgs = {
  clubId: Scalars['Int'];
  clubType: Scalars['Int'];
  docType: Scalars['String'];
  isCurrentInvoice: Scalars['Boolean'];
};


export type QueryGetClubMembersArgs = {
  clubId: Scalars['String'];
  clubType: Scalars['String'];
  currentRecord: Scalars['Int'];
  recordCount: Scalars['Int'];
  status: Scalars['Boolean'];
};


export type QueryGetCommitteeRolesArgs = {
  individualId: Scalars['String'];
};


export type QueryGetConferenceArgs = {
  conferenceId: Scalars['String'];
  isDistrictPresRep?: Maybe<Scalars['Boolean']>;
};


export type QueryGetConferencesArgs = {
  districtIds: Array<Scalars['String']>;
  isExtraData?: Maybe<Scalars['Boolean']>;
  maxStartDate?: Maybe<Scalars['String']>;
  minStartDate?: Maybe<Scalars['String']>;
  presRepresentativeId?: Maybe<Scalars['String']>;
  skipFetchingRiDistrictId?: Maybe<Scalars['Boolean']>;
};


export type QueryGetDelegationProfileArgs = {
  memberId: Scalars['String'];
  page: Scalars['String'];
};


export type QueryGetDistrictClubsArgs = {
  districtId: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  rotaryYear?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
};


export type QueryGetDistrictClubsStatisticsArgs = {
  districtId: Scalars['String'];
  rotaryYear?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  types: Array<Scalars['String']>;
};


export type QueryGetDistrictDashboardsArgs = {
  clubType?: Maybe<DashboardClubType>;
  districtId: Scalars['String'];
  years: Array<Scalars['Int']>;
};


export type QueryGetDistrictDetailsArgs = {
  districtId: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryGetDistrictMembersStatisticsArgs = {
  riDistrictId: Scalars['String'];
  types: Array<Scalars['String']>;
};


export type QueryGetDistrictsForLeadershipArgs = {
  role: Scalars['String'];
  rotaryYear: Scalars['Int'];
};


export type QueryGetDominoOrganizationIdArgs = {
  idType: Scalars['String'];
  value: Scalars['String'];
};


export type QueryGetEcardDetailsArgs = {
  eCardId: Scalars['String'];
};


export type QueryGetExchangeRatesArgs = {
  EffectiveDate: Scalars['String'];
  FromCurrency?: Maybe<Scalars['String']>;
  ToCurrency?: Maybe<Scalars['String']>;
};


export type QueryGetFeedbackQuestionsArgs = {
  conferenceYear: Scalars['Int'];
  feedbackType: Scalars['String'];
};


export type QueryGetGinArgs = {
  netForumMemberId: Scalars['String'];
};


export type QueryGetGrantDetailsArgs = {
  GrantId: Scalars['String'];
};


export type QueryGetIndividualArgs = {
  delayAfterResponse?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  riIndividualId?: Maybe<Scalars['Int']>;
};


export type QueryGetIndividualMemberDetailsArgs = {
  MemberId: Scalars['String'];
};


export type QueryGetIndividualProfileDetailsArgs = {
  MemberId: Scalars['String'];
};


export type QueryGetIndividualSecurityGroupsArgs = {
  memberId: Scalars['String'];
};


export type QueryGetLeadershipHistoryArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryGetMemberOrganizationDetailsArgs = {
  MemberId: Scalars['String'];
};


export type QueryGetMembershipArgs = {
  clubId: Scalars['String'];
  membershipId: Scalars['String'];
};


export type QueryGetMembershipHistoryArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryGetNonMemberDesArgs = {
  riDistrictId: Scalars['Int'];
  rotaryYear: Scalars['String'];
};


export type QueryGetOneFeedbackArgs = {
  conferenceId: Scalars['String'];
  feedbackType: Scalars['String'];
  isDistrictPresRep?: Maybe<Scalars['Boolean']>;
};


export type QueryGetOnlineIdEmailArgs = {
  individualId: Scalars['String'];
};


export type QueryGetOpenInvoiceListArgs = {
  Id: Scalars['String'];
  Type: Scalars['String'];
};


export type QueryGetOrganizationDetailsArgs = {
  OrganizationId: Scalars['String'];
  OrganizationType: Scalars['String'];
};


export type QueryGetPaymentProfileNameArgs = {
  CountryISO2: Scalars['String'];
  CurrencyCode: Scalars['String'];
  CustomerType: Scalars['String'];
  TransactionFlowName: Scalars['String'];
  TransactionTypeAlias: Scalars['String'];
};


export type QueryGetPresRepConferencesArgs = {
  individualId: Scalars['String'];
};


export type QueryGetPreviousDesArgs = {
  districtId: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  riDistrictId: Scalars['Int'];
  year: Scalars['Int'];
};


export type QueryGetRecognitionInfoArgs = {
  individualId: Scalars['String'];
};


export type QueryGetRegionalGroupDistrictsArgs = {
  regionalGroupId: Scalars['String'];
};


export type QueryGetSitecoreReportArgs = {
  reportType: SitecoreReportTypeEnum;
};


export type QueryGetStatesByCountryArgs = {
  CountryId: Scalars['String'];
};


export type QueryGetSubscriptionsForMemberArgs = {
  MemberID: Scalars['String'];
};


export type QueryGetTrackedGiftDetailsArgs = {
  DonorID: Scalars['String'];
  DonorType: Scalars['String'];
  LanguageCode: Scalars['String'];
  TrackedGiftID: Scalars['String'];
};


export type QueryMemberSearchAdvancedArgs = {
  allowRotaractors?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  clubIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  clubNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  clubRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  country?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  districtRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  includeDLHistory?: Maybe<Scalars['Boolean']>;
  individualId?: Maybe<Scalars['String']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isIncludedDistrictLeadership?: Maybe<Scalars['Boolean']>;
  isIncludedDistrictMembership?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  memberClubType?: Maybe<Scalars['String']>;
  memberTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  riClubId?: Maybe<Scalars['String']>;
  rolesToExclude?: Maybe<Array<Maybe<Scalars['String']>>>;
  rotaryYear?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  yearRange?: Maybe<Array<Scalars['Int']>>;
};


export type QueryRolesArgs = {
  officerManagement?: Maybe<Scalars['String']>;
  organizationType?: Maybe<Array<Scalars['String']>>;
  rolesToInclude?: Maybe<Array<Scalars['String']>>;
  validOn?: Maybe<Scalars['String']>;
};


export type QuerySearchClubArgs = {
  advanced?: Maybe<Scalars['Boolean']>;
  clubFullNamePartial?: Maybe<Scalars['String']>;
  clubName?: Maybe<Scalars['String']>;
  clubNamePartial?: Maybe<Scalars['String']>;
  clubType?: Maybe<ClubTypeFilterValue>;
  clubTypes?: Maybe<Array<ClubTypeFilterValue>>;
  earliestMeetingTime?: Maybe<Scalars['String']>;
  isSearchByClubName?: Maybe<Scalars['Boolean']>;
  keyword?: Maybe<Scalars['String']>;
  latestMeetingTime?: Maybe<Scalars['String']>;
  meetingDay?: Maybe<Scalars['String']>;
  meetingLanguageId?: Maybe<Scalars['String']>;
  meetingLocationLatitude?: Maybe<Scalars['Float']>;
  meetingLocationLongitude?: Maybe<Scalars['Float']>;
  meetingLocationRange?: Maybe<Scalars['Float']>;
  meetingTime?: Maybe<Scalars['String']>;
  meetingType?: Maybe<MeetingTypeFilterValue>;
  orderBy?: Maybe<Scalars['String']>;
  organizationBase?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  riClubId?: Maybe<Scalars['String']>;
  riDistrictId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};


export type QuerySearchClubsInDistrictArgs = {
  clubLocation?: Maybe<Scalars['String']>;
  clubName?: Maybe<Scalars['String']>;
  clubTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  riDistrictId: Scalars['Int'];
  status?: Maybe<Scalars['String']>;
};


export type QuerySearchDesByIndividualIdArgs = {
  individualId: Scalars['String'];
};


export type QuerySearchDgByRyArgs = {
  conferenceDistrictId: Scalars['String'];
  conferenceEndDate: Scalars['String'];
  conferenceStartDate: Scalars['String'];
  relatedRY: Scalars['String'];
};


export type QuerySearchIndividualArgs = {
  advanced?: Maybe<Scalars['Boolean']>;
  clubName?: Maybe<Scalars['String']>;
  clubTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  countryId?: Maybe<Array<Maybe<Scalars['String']>>>;
  customQueryString?: Maybe<Scalars['String']>;
  customSortSyntax?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailExactMatch?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  riClubId?: Maybe<Array<Maybe<Scalars['String']>>>;
  riIndividualId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  searchNonMembers?: Maybe<Scalars['Boolean']>;
};


export type QuerySearchPreviousOfficersArgs = {
  clubRole: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  riClubId?: Maybe<Scalars['String']>;
  yearRange: Array<Scalars['Int']>;
};


export type QuerySearchSolrArgs = {
  content_type?: Maybe<Scalars['String']>;
  document_mimetype?: Maybe<Scalars['String']>;
  keywords: Scalars['String'];
  langcode?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
};


export type QueryStatesArgs = {
  countryCode?: Maybe<Scalars['String']>;
};


export type QueryTerminationReasonsArgs = {
  clubType?: Maybe<Scalars['String']>;
};

export type Question = {
  __typename?: 'Question';
  applicationFeature: Scalars['String'];
  applicationId: Scalars['String'];
  feedbackSection: FeedbackSection;
  id: Scalars['String'];
  option: Scalars['String'];
  optionDataType: Scalars['String'];
  optionDetail: OptionDetail;
  optionSeq: Scalars['Int'];
  optionType: OptionType;
};

export type QuestionResponse = {
  __typename?: 'QuestionResponse';
  questionId: Scalars['String'];
  value: Scalars['String'];
};

export enum Reason {
  Age = 'Age',
  BusinessObligations = 'BusinessObligations',
  ByClubForCause = 'ByClubForCause',
  Deceased = 'Deceased',
  FamilyObligations = 'FamilyObligations',
  Health = 'Health',
  JoiningNewClub = 'JoiningNewClub',
  JoiningRotaryClub = 'JoiningRotaryClub',
  LackOfParticipation = 'LackOfParticipation',
  NoContactUpdate = 'NoContactUpdate',
  NonAttendance = 'NonAttendance',
  NonPaymentOfClubDues = 'NonPaymentOfClubDues',
  Personal = 'Personal',
  Relocation = 'Relocation',
  SchoolGraduation = 'SchoolGraduation'
}

export type Recognition = {
  __typename?: 'Recognition';
  date: Scalars['String'];
  description: Scalars['String'];
  level: Scalars['Int'];
};

export type RecognitionClub = {
  __typename?: 'RecognitionClub';
  clubId: Scalars['String'];
  clubName: Scalars['String'];
  clubType: Scalars['String'];
  physicalLocation?: Maybe<PhysicalLocation>;
};

export type RecognitionDataInfo = {
  __typename?: 'RecognitionDataInfo';
  bronze?: Maybe<Array<Maybe<RecognitionItemInfo>>>;
  gold?: Maybe<Array<Maybe<RecognitionItemInfo>>>;
  platinum?: Maybe<Array<Maybe<RecognitionItemInfo>>>;
  silver?: Maybe<Array<Maybe<RecognitionItemInfo>>>;
};

export type RecognitionIndividual = {
  __typename?: 'RecognitionIndividual';
  club: RecognitionClub;
  firstName?: Maybe<Scalars['String']>;
  individualId: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  localizedName?: Maybe<Scalars['String']>;
  membership: RecognitionMembership;
  middleName?: Maybe<Scalars['String']>;
  photoUri?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  sponsorships: Scalars['Int'];
  suffix?: Maybe<Scalars['String']>;
};

export type RecognitionItem = {
  __typename?: 'RecognitionItem';
  recognitions: Array<Recognition>;
  type: Scalars['String'];
};

export type RecognitionItemInfo = {
  __typename?: 'RecognitionItemInfo';
  districtId: Scalars['String'];
  individuals: Array<RecognitionIndividual>;
  riDistrictId: Scalars['Int'];
};

export type RecognitionMembership = {
  __typename?: 'RecognitionMembership';
  status: Scalars['String'];
  type: Scalars['String'];
};

export type Recurrence = {
  __typename?: 'Recurrence';
  /** Date and time of each exception to the recurrence. (ISO date or date/time) */
  exdate?: Maybe<Array<Scalars['String']>>;
  /** Date and time of each occurrence that doesn't fit the pattern. (ISO date or date/time) */
  rdate?: Maybe<Array<Scalars['String']>>;
  /** Pattern by which occurrences are generated. */
  rrule?: Maybe<Array<RecurrenceRule>>;
};

export enum RecurrenceFrequency {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Minutely = 'MINUTELY',
  Monthly = 'MONTHLY',
  Secondly = 'SECONDLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type RecurrenceRule = {
  __typename?: 'RecurrenceRule';
  by?: Maybe<RecurrenceSpecifiers>;
  /** How many times to recur, including the first occurrence. */
  count?: Maybe<Scalars['Int']>;
  /** Time scale on which the recurrence rule operates. */
  freq: RecurrenceFrequency;
  /** Coefficient for freq. */
  interval?: Maybe<Scalars['Int']>;
  /** Date at which the recurrence stops recurring. (ISO date or date/time) */
  until?: Maybe<Scalars['String']>;
  /** Which day is the first day of each week. */
  wkst?: Maybe<WeekDay>;
};

export type RecurrenceSpecifiers = {
  __typename?: 'RecurrenceSpecifiers';
  /** Which day of the week at which to recur. (See: freq.) */
  day?: Maybe<Array<DaySpecifier>>;
  /** Which hour of the day at which to recur. */
  hour?: Maybe<Array<Scalars['Int']>>;
  /** Which minutes of the hour at which to recur. (0-59) */
  minute?: Maybe<Array<Scalars['Int']>>;
  /** Which seconds of the minute at which to recur. (0-60) */
  second?: Maybe<Array<Scalars['Int']>>;
};

export type RegionalGroupDistricts = {
  __typename?: 'RegionalGroupDistricts';
  DistrictId?: Maybe<Scalars['String']>;
  DistrictKey?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
  RegionalGroupId?: Maybe<Scalars['String']>;
  RegionalGroupName?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
};

export type Report = {
  __typename?: 'Report';
  children: Array<ChildReport>;
  description: Scalars['String'];
  label: Scalars['String'];
};

/** Represents request source. */
export type RequestSource = {
  __typename?: 'RequestSource';
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
  ShowPrivacyPolicy?: Maybe<Scalars['Boolean']>;
  /** request source */
  SourceGUID?: Maybe<Scalars['String']>;
  SourceName?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ResumeItem = {
  __typename?: 'ResumeItem';
  address: OrganizationAddress;
  organizationId?: Maybe<Scalars['String']>;
  organizationName: Scalars['String'];
  organizationType: Scalars['String'];
  /**
   * The individual's roles from a single organization.
   * The list is ordered descending by the biggest startDate.
   * Roles are both membership and leadership roles.
   */
  roles: Array<ResumeRole>;
};

export type ResumeRole = {
  __typename?: 'ResumeRole';
  committeeName?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  isShowOnline?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  startDate: Scalars['String'];
};

export type ResumeRoles = {
  __typename?: 'ResumeRoles';
  currentClubJoinDate?: Maybe<Scalars['String']>;
  originalJoinDate?: Maybe<Scalars['String']>;
  resumeRolesGroups?: Maybe<Array<ResumeItem>>;
  yearsOfService?: Maybe<Scalars['Float']>;
};

/** Role object, as they are returned for query */
export type Role = {
  __typename?: 'Role';
  /** The role's uuid. */
  id: Scalars['String'];
  /** If it's an officer role. */
  isOfficer?: Maybe<Scalars['Boolean']>;
  /** The name of the role. */
  name: Scalars['String'];
  /** Length of the term. */
  termLength?: Maybe<Scalars['Int']>;
  /** Terms of access after. */
  termsOfAccessAfter?: Maybe<Scalars['Int']>;
  /** Terms of access before. */
  termsOfAccessBefore?: Maybe<Scalars['Int']>;
  /** Organization type = Rotary_Club, District, etc. */
  type?: Maybe<Scalars['String']>;
};

export type RoleAccess = {
  __typename?: 'RoleAccess';
  allow?: Maybe<Array<Maybe<Scalars['String']>>>;
  deny?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RolesAuthenticationTicketDataType = {
  __typename?: 'RolesAuthenticationTicketDataType';
  clubGroupId?: Maybe<Scalars['String']>;
  clubGroupKey?: Maybe<Scalars['String']>;
  clubGroupName?: Maybe<Scalars['String']>;
  clubId?: Maybe<Scalars['String']>;
  delegation?: Maybe<Scalars['String']>;
  delegationEndDate?: Maybe<Scalars['String']>;
  delegationStartDate?: Maybe<Scalars['String']>;
  delegatorIndividualKey?: Maybe<Scalars['String']>;
  delegatorMemberId?: Maybe<Scalars['String']>;
  delegatorSortName?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  districtKey?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  individualKey?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
  positionType?: Maybe<Scalars['String']>;
  preferredLanguage?: Maybe<Scalars['String']>;
  regionalGroupId?: Maybe<Scalars['String']>;
  regionalGroupKey?: Maybe<Scalars['String']>;
  regionalGroupName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  roleKey?: Maybe<Scalars['String']>;
  rotaractClubId?: Maybe<Scalars['String']>;
  rotaractClubKey?: Maybe<Scalars['String']>;
  rotaryClubKey?: Maybe<Scalars['String']>;
  termAfterDuration?: Maybe<Scalars['Int']>;
  termBeforeDuration?: Maybe<Scalars['Int']>;
  termEndDate?: Maybe<Scalars['String']>;
  termStartDate?: Maybe<Scalars['String']>;
  userSortName?: Maybe<Scalars['String']>;
};

/** An OKTA security question. */
export type SecurityQuestion = {
  __typename?: 'SecurityQuestion';
  id: Scalars['String'];
  text: Scalars['String'];
};

export type SharingPermission = {
  __typename?: 'SharingPermission';
  id: Scalars['String'];
  label: Scalars['String'];
};

export type SharingPermissionResult = {
  __typename?: 'SharingPermissionResult';
  address: Scalars['String'];
  email: Scalars['String'];
  memberId: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type SharingPermissionsExtended = {
  __typename?: 'SharingPermissionsExtended';
  aboutMe: SharingPermission;
  address: SharingPermission;
  dateOfBirth: SharingPermission;
  email: SharingPermission;
  expertise: SharingPermission;
  fax: SharingPermission;
  gender: SharingPermission;
  language: SharingPermission;
  name: SharingPermission;
  phone: SharingPermission;
  photo: SharingPermission;
  profession: SharingPermission;
  program: SharingPermission;
  recognition: SharingPermission;
  sponsors: SharingPermission;
};

/** Each field takes the ID of a sharing permission to set. */
export type SharingPermissionsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expertise?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  recognition?: Maybe<Scalars['String']>;
};

export type SharingPermissionsRegular = {
  __typename?: 'SharingPermissionsRegular';
  aboutMe: Scalars['Boolean'];
  address: Scalars['Boolean'];
  dateOfBirth: Scalars['Boolean'];
  email: Scalars['Boolean'];
  expertise: Scalars['Boolean'];
  fax: Scalars['Boolean'];
  gender: Scalars['Boolean'];
  language: Scalars['Boolean'];
  name: Scalars['Boolean'];
  phone: Scalars['Boolean'];
  photo: Scalars['Boolean'];
  profession: Scalars['Boolean'];
};

export type SharingPermissionsResult = {
  __typename?: 'SharingPermissionsResult';
  address: Scalars['Boolean'];
  email: Scalars['Boolean'];
  name: Scalars['Boolean'];
  phone: Scalars['Boolean'];
  photo: Scalars['Boolean'];
};

export type ShortClubInfo = {
  __typename?: 'ShortClubInfo';
  clubId: Scalars['String'];
  clubName: Scalars['String'];
  clubType: Scalars['String'];
  meetings?: Maybe<Array<Maybe<Meeting>>>;
};

/** Site search result object */
export type SiteSearchResults = {
  __typename?: 'SiteSearchResults';
  facet_counts: SolrFacets;
  highlighting?: Maybe<Array<SolrHighlighting>>;
  response: SolrResponse;
  responseHeader: SolrResponseHeader;
  spellcheckSuggestion?: Maybe<Scalars['String']>;
};

export enum SitecoreReportTypeEnum {
  Club = 'club',
  District = 'district',
  Individual = 'individual',
  Region = 'region'
}

export type SolrFacetFieldOption = {
  __typename?: 'SolrFacetFieldOption';
  count: Scalars['String'];
  value: Scalars['String'];
};

export type SolrFacetFields = {
  __typename?: 'SolrFacetFields';
  content_type: Array<Maybe<SolrFacetFieldOption>>;
  document_mimetype?: Maybe<Array<Maybe<SolrFacetFieldOption>>>;
  langcode?: Maybe<Array<Maybe<SolrFacetFieldOption>>>;
  topic: Array<Maybe<SolrFacetFieldOption>>;
};

/** The facet data returned from Solr. */
export type SolrFacets = {
  __typename?: 'SolrFacets';
  facet_fields: SolrFacetFields;
};

/** The highlighting data returned from Solr. */
export type SolrHighlighting = {
  __typename?: 'SolrHighlighting';
  content_fulltext: Array<Maybe<Scalars['String']>>;
  id: Scalars['String'];
};

/** The main Solr response object, contains the results in docs property. */
export type SolrResponse = {
  __typename?: 'SolrResponse';
  docs: Array<SolrResultItem>;
  numFound: Scalars['Int'];
  start: Scalars['Int'];
};

/** Solr response header object, metadata on the response. */
export type SolrResponseHeader = {
  __typename?: 'SolrResponseHeader';
  QTime: Scalars['Int'];
  status: Scalars['Int'];
  zkConnected: Scalars['Boolean'];
};

/** The Solr index item. */
export type SolrResultItem = {
  __typename?: 'SolrResultItem';
  _version_: Scalars['String'];
  bundle?: Maybe<Scalars['String']>;
  content_fulltext: Array<Maybe<Scalars['String']>>;
  content_type?: Maybe<Scalars['String']>;
  date_created: Scalars['String'];
  date_modified: Scalars['String'];
  document_file_size?: Maybe<Scalars['Int']>;
  document_mimetype?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  item_id?: Maybe<Scalars['String']>;
  langcode?: Maybe<Scalars['String']>;
  role?: Maybe<Array<Maybe<Scalars['String']>>>;
  site: Scalars['String'];
  source_id: Scalars['String'];
  source_path?: Maybe<Scalars['String']>;
  sticky?: Maybe<Scalars['Boolean']>;
  title_fulltext: Scalars['String'];
  topic?: Maybe<Array<Maybe<Scalars['String']>>>;
  weight?: Maybe<Scalars['Int']>;
};

export type SponsorshipItem = {
  __typename?: 'SponsorshipItem';
  club: FormattedClub;
  membershipList: Array<FormattedMembership>;
};

export type SponsorshipResult = {
  __typename?: 'SponsorshipResult';
  sponsoredByMe: Array<SponsorshipItem>;
  sponsoredBySomeone: Array<SponsorshipItem>;
};

/** Represents a state by country code. */
export type State = {
  __typename?: 'State';
  /** The id of the country the states belongs to. */
  countryId: Scalars['String'];
  /** The state's id. */
  id: Scalars['String'];
  /** The name of the State. */
  name: Scalars['String'];
};

/** Represents StatesByCountryResult. */
export type StatesByCountryResult = {
  __typename?: 'StatesByCountryResult';
  CountryKey?: Maybe<Scalars['String']>;
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
  StateAbbreviation?: Maybe<Scalars['String']>;
  StateKey?: Maybe<Scalars['String']>;
};

export type SubReport = {
  __typename?: 'SubReport';
  reportId: Scalars['String'];
  reportTechnicalName: Scalars['String'];
  reportTitle: Scalars['String'];
};

export type SubSponser = {
  __typename?: 'SubSponser';
  id?: Maybe<Scalars['String']>;
};

/** Represents subscriptions. */
export type Subscriptions = {
  __typename?: 'Subscriptions';
  Address?: Maybe<AddressDetails>;
  Amount?: Maybe<Scalars['String']>;
  Currency?: Maybe<Scalars['String']>;
  EmailAddress?: Maybe<Scalars['String']>;
  Frequency?: Maybe<Scalars['String']>;
  FundName?: Maybe<Scalars['String']>;
  FundType?: Maybe<Scalars['String']>;
  Individual?: Maybe<IndividualDetails>;
  MerchantConfig?: Maybe<Scalars['String']>;
  NextChargeTimestamp?: Maybe<Scalars['String']>;
  NextCharged?: Maybe<Scalars['String']>;
  Payment?: Maybe<Payment>;
  PhoneNumber?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  SubFundType?: Maybe<Scalars['String']>;
  SubscriptionCreatedBy?: Maybe<Scalars['String']>;
  SubscriptionCreationDate?: Maybe<Scalars['String']>;
  SubscriptionCreationSource?: Maybe<Scalars['String']>;
  /** subscriptions */
  SubscriptionId?: Maybe<Scalars['String']>;
  SubscriptionLastModificationDate?: Maybe<Scalars['String']>;
  TranslatedFrequency?: Maybe<Scalars['String']>;
  WidgetID?: Maybe<Scalars['String']>;
};

export type SuccessAssignmentsResult = {
  __typename?: 'SuccessAssignmentsResult';
  body?: Maybe<SuccessBody>;
  id: Scalars['Int'];
  statusCode: Scalars['Int'];
};

export type SuccessBody = {
  __typename?: 'SuccessBody';
  endDate: Scalars['String'];
  id: Scalars['String'];
  message: Scalars['String'];
  startDate: Scalars['String'];
};

/** The term a role is filled by an individual. */
export type Term = {
  __typename?: 'Term';
  /** Format: YYYY-MM-DD */
  from: Scalars['String'];
  /** Format: YYYY-MM-DD */
  to?: Maybe<Scalars['String']>;
};

/** Input for the terminateClubVendors mutation */
export type TerminateClubVendorInput = {
  clubKey: Scalars['String'];
  requestorKey: Scalars['String'];
  vendorKey: Scalars['String'];
};

export type TerminateClubVendorResult = {
  __typename?: 'TerminateClubVendorResult';
  /** Terminated vendor id? */
  vendorId?: Maybe<Scalars['String']>;
};

/** Termination Reason object, as they are returned for query */
export type TerminationReason = {
  __typename?: 'TerminationReason';
  /** Club type */
  clubType: Scalars['String'];
  /** The termination reason's uuid. */
  id: Scalars['String'];
  isNotAllowed?: Maybe<Scalars['Boolean']>;
  /** The termination reason descriptive text. */
  reason: Reason;
};

export type TimeZone = {
  __typename?: 'TimeZone';
  id: Scalars['String'];
  offset: Scalars['String'];
  timeZoneName: Scalars['String'];
  value: Scalars['String'];
};

/** Represents Tracked Gift Details based on parameters : TrackedGiftID, DonorType, DonorID, LanguageCode. */
export type TrackedGiftDetailsResult = {
  __typename?: 'TrackedGiftDetailsResult';
  ALIAS_CODE?: Maybe<Scalars['String']>;
  ALIAS_DESCR?: Maybe<Scalars['String']>;
  ALIAS_DESCR_LANG?: Maybe<Scalars['String']>;
  DESIGNATION_DESCR?: Maybe<Scalars['String']>;
  ENDOWMENT_TYPE?: Maybe<Scalars['String']>;
  FUND?: Maybe<Scalars['String']>;
  GIFT_NAME?: Maybe<Scalars['String']>;
  GIFT_TYPE?: Maybe<Scalars['String']>;
  /** Tracked Gift Details */
  STATUS?: Maybe<Scalars['String']>;
  SUB_FUND?: Maybe<Scalars['String']>;
  TRACKED_GIFT_ID?: Maybe<Scalars['String']>;
};

/** Represents TransactionTypeResult. */
export type TransactionTypeResult = {
  __typename?: 'TransactionTypeResult';
  Alias?: Maybe<Scalars['String']>;
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  FundId?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['String']>;
  IsRecurring?: Maybe<Scalars['String']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
  SubFundId?: Maybe<Scalars['String']>;
  TransactionTypeKey?: Maybe<Scalars['String']>;
  TransactionTypeTranslations?: Maybe<Array<Maybe<TransactionTypeTranslations>>>;
  TypeName?: Maybe<Scalars['String']>;
};

export type TransactionTypeTranslations = {
  __typename?: 'TransactionTypeTranslations';
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
  TransactionTypeKey?: Maybe<Scalars['String']>;
  TransactionTypeTranslationKey?: Maybe<Scalars['String']>;
  TranslatedLearnMore?: Maybe<Scalars['String']>;
  TranslatedName?: Maybe<Scalars['String']>;
};

/** Input for the updateClub mutation */
export type UpdateClubInput = {
  /** Club chartered date. */
  charteredDate?: Maybe<Scalars['String']>;
  /** ID of the club to update. */
  clubId: Scalars['String'];
  /** Club type. */
  clubType?: Maybe<Scalars['String']>;
  /** Club district. */
  district?: Maybe<Scalars['String']>;
  /** Domino ID */
  dominoId?: Maybe<Scalars['String']>;
  /** Club primary email */
  email?: Maybe<DominoEmailInput>;
  /** Club mailing address. */
  mailingAddress?: Maybe<BaseAddressInput>;
  /** Club meetings. */
  meetings?: Maybe<Array<MeetingInput>>;
  /** Club name. */
  name?: Maybe<Scalars['String']>;
  /** Club language */
  officialLanguage?: Maybe<BasicLanguageInput>;
  /** Club's physical location */
  physicalLocation?: Maybe<BaseAddressInput>;
  /** Club contact email */
  primaryEmail?: Maybe<Scalars['String']>;
  /** Club contact fax */
  primaryFax?: Maybe<FaxInput>;
  /** Club contact phone number */
  primaryPhone?: Maybe<PhoneInput>;
  /** Club contact web site */
  primaryWebsite?: Maybe<Scalars['String']>;
  /** Club's riClubId */
  riClubId?: Maybe<Scalars['String']>;
  /** Club type. */
  type?: Maybe<Scalars['String']>;
  /** Club website */
  website?: Maybe<WebsiteInput>;
};

/** Result of the updateClub mutation. */
export type UpdateClubResult = {
  __typename?: 'UpdateClubResult';
  clubId: Scalars['String'];
};

export type UpdateContactInfoInput = {
  contactInfo: DominoContactInfo;
};

export type UpdateIndividualInput = {
  aboutMe?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  /** First name (Given name). Max length is 30 chars. Cannot start with a space, dash or dot. Mostly latin characters, DIS regex: `/^[\\ -\\ɏ]*$/`. */
  firstName?: Maybe<Scalars['String']>;
  /**
   * Gender. One of Male, Female, Not Given, Not to Identify, Self Identify. If
   * not provided, set to Not Given.
   */
  gender?: Maybe<Gender>;
  /** Gender description. Provided when Gender is Self Identify. */
  genderDescription?: Maybe<Scalars['String']>;
  /** ID of the individual to update. */
  individualId: Scalars['String'];
  /** Last name (Family name). Max length is 30 chars. Cannot start with a space, dash or dot. Mostly latin characters, DIS regex: `/^[\\ -\\ɏ]*$/`. */
  lastName?: Maybe<Scalars['String']>;
  /** Localized name (Full name). Max length is 200 chars. Supports uncode. */
  localizedName?: Maybe<Scalars['String']>;
  /** Middle name. Max length is 30 chars. Cannot start with a space, dash or dot. Mostly latin characters, DIS regex: `/^[\\ -\\ɏ]*$/`. */
  middleName?: Maybe<Scalars['String']>;
  otherAddresses?: Maybe<Array<AddressInput>>;
  otherEmails?: Maybe<Array<EmailInput>>;
  otherExpertise?: Maybe<Array<ExpertiseInput>>;
  otherLanguages?: Maybe<Array<Scalars['String']>>;
  otherPhones?: Maybe<Array<IndividualPhoneInput>>;
  otherProfessions?: Maybe<Array<ProfessionInput>>;
  /** Id of the preferred language */
  preferredLanguage?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  /** A physical location. */
  primaryAddress?: Maybe<AddressInput>;
  /** Email address. */
  primaryEmail?: Maybe<EmailInput>;
  /** Areas of expertise. */
  primaryExpertise?: Maybe<ExpertiseInput>;
  /** Id of the primary language as returned by the languages field. */
  primaryLanguage?: Maybe<Scalars['String']>;
  /** A phone number. */
  primaryPhone?: Maybe<IndividualPhoneInput>;
  /** Professional experience. */
  primaryProfession?: Maybe<ProfessionInput>;
  /** Allow share sponsorship or not */
  recognitionOptOutFlag?: Maybe<Scalars['Boolean']>;
  sharingPermissionsExtended?: Maybe<SharingPermissionsInput>;
  suffix?: Maybe<Scalars['String']>;
  useInternalKey?: Maybe<Scalars['Boolean']>;
  /** Format YYYY. Must be between this year and 100 years ago. */
  yearOfBirth?: Maybe<Scalars['Int']>;
};

export type UpdateIndividualProgramInput = {
  fields?: Maybe<Array<ProgramFieldInput>>;
  individualId: Scalars['String'];
  individualProgramId: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  /** The list of security questions for the user to choose from. */
  availableSecurityQuestions: Array<SecurityQuestion>;
  /** The current security question. */
  currentSecurityQuestion?: Maybe<Scalars['String']>;
  /** Impersonation session expiration */
  impersonationExpiration?: Maybe<Scalars['String']>;
  /** The current Impersonator Id */
  impersonatorId?: Maybe<Scalars['String']>;
  individualId?: Maybe<Scalars['String']>;
  login: Scalars['String'];
  nfKey?: Maybe<Scalars['String']>;
  oktaId: Scalars['String'];
  /** User profile */
  profile?: Maybe<UserProfile>;
  riIndividualId?: Maybe<Scalars['String']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  /** User firstname */
  firstName?: Maybe<Scalars['String']>;
  /** User lastname */
  lastName?: Maybe<Scalars['String']>;
};

/** Represents a vendor */
export type Vendor = {
  __typename?: 'Vendor';
  /** Vendor Key */
  Key: Scalars['String'];
  /** Vendor Name */
  Name: Scalars['String'];
};

export type VendorRequestor = {
  __typename?: 'VendorRequestor';
  add?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  edit?: Maybe<Scalars['Boolean']>;
  get?: Maybe<Scalars['Boolean']>;
  isPreferred?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  lastUpdated?: Maybe<Scalars['String']>;
  organizationKey: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  organizationType: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
};

export type Website = {
  __typename?: 'Website';
  IsPrimary?: Maybe<Scalars['Boolean']>;
  Key?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
  WebsiteAddress?: Maybe<Scalars['String']>;
  WebsiteType?: Maybe<Scalars['String']>;
};

export type WebsiteInput = {
  action?: Maybe<Scalars['String']>;
  /** Website identifier */
  id?: Maybe<Scalars['String']>;
  /** Other club websites */
  otherWebsites?: Maybe<Scalars['String']>;
  /** club primary website */
  primaryWebsite?: Maybe<Scalars['String']>;
};

export type WebsiteType = {
  __typename?: 'WebsiteType';
  /** Website identifier */
  id: Scalars['String'];
  /** Other club websites */
  otherWebsites?: Maybe<Scalars['String']>;
  /** club primary website */
  primaryWebsite: Scalars['String'];
};

export enum WeekDay {
  Fr = 'FR',
  Mo = 'MO',
  Sa = 'SA',
  Su = 'SU',
  Th = 'TH',
  Tu = 'TU',
  We = 'WE'
}

/** Club Address input */
export enum WeekdayFull {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

/** Represents a validation error returned by Oracle. */
export type AddFiscalCodeError = {
  __typename?: 'addFiscalCodeError';
  /** The type of the error. */
  body: Scalars['String'];
  /** The field that failed validation. */
  code: Scalars['String'];
  /** The error message. */
  statusText: Scalars['String'];
};

/** Represents a validation error returned by Oracle. */
export type AddPanError = {
  __typename?: 'addPanError';
  /** The type of the error. */
  body: Scalars['String'];
  /** The field that failed validation. */
  code: Scalars['String'];
  /** The error message. */
  statusText: Scalars['String'];
};

/** Represents a validation error returned by Oracle. */
export type AddRrnError = {
  __typename?: 'addRrnError';
  /** The type of the error. */
  body: Scalars['String'];
  /** The field that failed validation. */
  code: Scalars['String'];
  /** The error message. */
  statusText: Scalars['String'];
};

/** Represents a validation error returned by DIS. */
export type AddSponsorError = {
  __typename?: 'addSponsorError';
  /** The type of the error. */
  code: Scalars['String'];
  /** The error message. */
  description: Scalars['String'];
  /** The field that failed validation. */
  source: Scalars['String'];
};

/** Represents a validation error returned by DIS. */
export type CreateLeadershipError = {
  __typename?: 'createLeadershipError';
  /** The type of the error. */
  code: Scalars['String'];
  /** The error message. */
  description: Scalars['String'];
  /** The field that failed validation. */
  source: Scalars['String'];
};

/** Result of the createIndividual mutation. */
export type CreateLeadershipResult = {
  __typename?: 'createLeadershipResult';
  leadership?: Maybe<LeadershipResult>;
};

/** Represents a validation error returned by DIS. */
export type CreateMembershipError = {
  __typename?: 'createMembershipError';
  /** The type of the error. */
  code: Scalars['String'];
  /** The error message. */
  description: Scalars['String'];
  /** The field that failed validation. */
  source: Scalars['String'];
};

/** Result of the createIndividual mutation. */
export type CreateMembershipResult = {
  __typename?: 'createMembershipResult';
  membership?: Maybe<MembershipResult>;
};

/** Result of the createECard mutation. */
export type EcardAddResult = {
  __typename?: 'ecardAddResult';
  ecardId?: Maybe<Scalars['String']>;
};

/** Result of the addFiscalCode Mutation. */
export type FiscalCodeInfoResult = {
  __typename?: 'fiscalCodeInfoResult';
  errorMsg?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Leadership Object */
export type LeadershipResult = {
  __typename?: 'leadershipResult';
  /** The date on which this leadership will be valid until */
  endDate?: Maybe<Scalars['String']>;
  /** Leadership's ID */
  id: Scalars['String'];
  /** Individual's ID that this leadership refers to */
  individualId: Scalars['String'];
  /** Shows if leadership is delegated */
  isDelegated?: Maybe<Scalars['Boolean']>;
  /** Leadership Role description */
  role: Scalars['String'];
  /** Leadership Role ID' */
  roleId: Scalars['String'];
  /** The date on which this leadership will be valid from */
  startDate: Scalars['String'];
};

/** Membership Object */
export type MembershipResult = {
  __typename?: 'membershipResult';
  /** The date on which this membership will be valid from */
  admissionDate: Scalars['String'];
  /** The date on which this membership will be valid from */
  id: Scalars['String'];
  /** Individual's ID that this membership refers to */
  individualId: Scalars['String'];
  /** If terminated, contains the date (should be null for newly created Membership) */
  terminationDate?: Maybe<Scalars['String']>;
  /** If terminated, displays termination reason's text(should be null for newly created Membership) */
  terminationReason?: Maybe<Scalars['String']>;
  /** If terminated, the termination reason's ID(should be null for newly created Membership) */
  terminationReasonId?: Maybe<Scalars['String']>;
  /** Membership's type. Can be either 'Member' or 'Honorary' */
  type: Scalars['String'];
};

/** Result of the createIndividual mutation. */
export type PanInfoResult = {
  __typename?: 'panInfoResult';
  pan?: Maybe<Scalars['String']>;
};

/** Result of the createIndividual mutation. */
export type RrnInfoResult = {
  __typename?: 'rrnInfoResult';
  rrn?: Maybe<Scalars['String']>;
};

/** Represents a validation error returned by DIS. */
export type TerminateLeadershipError = {
  __typename?: 'terminateLeadershipError';
  /** The type of the error. */
  code: Scalars['String'];
  /** The error message. */
  description: Scalars['String'];
  /** The field that failed validation. */
  source: Scalars['String'];
};

/** Represents a validation error returned by DIS. */
export type TerminateMembershipError = {
  __typename?: 'terminateMembershipError';
  /** The type of the error. */
  code: Scalars['String'];
  /** The error message. */
  description: Scalars['String'];
  /** The field that failed validation. */
  source: Scalars['String'];
};

/** Result of the createIndividual mutation. */
export type UpdateMembershipTypeResult = {
  __typename?: 'updateMembershipTypeResult';
  /** If operation is successful, created after successful operation */
  membership?: Maybe<MembershipResult>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;

export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AGResult: ResolverTypeWrapper<AgResult>;
  AccPhone: ResolverTypeWrapper<AccPhone>;
  AccessLevel: AccessLevel;
  AccessLevelsArguments: ResolverTypeWrapper<AccessLevelsArguments>;
  AccessLevelsOptions: AccessLevelsOptions;
  AccountInfoByToken: ResolverTypeWrapper<AccountInfoByToken>;
  ActiveMembership: ResolverTypeWrapper<ActiveMembership>;
  AddClubMeetingInput: AddClubMeetingInput;
  AddClubMeetingResult: ResolverTypeWrapper<AddClubMeetingResult>;
  AddClubVendorInput: AddClubVendorInput;
  AddClubVendorResult: ResolverTypeWrapper<AddClubVendorResult>;
  AddDelegationInput: AddDelegationInput;
  AddMemberIndividualResult: ResolverTypeWrapper<AddMemberIndividualResult>;
  Address: ResolverTypeWrapper<Address>;
  AddressDetails: ResolverTypeWrapper<AddressDetails>;
  AddressInfo: ResolverTypeWrapper<AddressInfo>;
  AddressInput: AddressInput;
  AddressType: AddressType;
  Affiliation: ResolversTypes['LeadershipAffiliation'] | ResolversTypes['MembershipAffiliation'];
  AffiliationType: AffiliationType;
  AgClubAffiliation: ResolverTypeWrapper<AgClubAffiliation>;
  ApplicationModeResult: ResolverTypeWrapper<ApplicationModeResult>;
  AssistantGovernor: ResolverTypeWrapper<AssistantGovernor>;
  AssistantGovernorByDate: ResolverTypeWrapper<AssistantGovernorByDate>;
  AssistantGovernorTermInfo: ResolverTypeWrapper<AssistantGovernorTermInfo>;
  AssistantGovernorsByDateResults: ResolverTypeWrapper<AssistantGovernorsByDateResults>;
  AssistantGovernorsResults: ResolverTypeWrapper<AssistantGovernorsResults>;
  BACountry: ResolverTypeWrapper<BaCountry>;
  BankAccount: ResolverTypeWrapper<BankAccount>;
  BankAssignmentResult: ResolverTypeWrapper<BankAssignmentResult>;
  BaseAddress: ResolverTypeWrapper<BaseAddress>;
  BaseAddressInput: BaseAddressInput;
  BasicInfo: ResolverTypeWrapper<BasicInfo>;
  BasicLanguageInput: BasicLanguageInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  CDSRepresentatives: ResolverTypeWrapper<CdsRepresentatives>;
  CRMIndividualInfoType: ResolverTypeWrapper<CrmIndividualInfoType>;
  CalendarEvent: ResolversTypes['ClubSearchResultMeeting'];
  CancelSubscriptionResponse: ResolverTypeWrapper<CancelSubscriptionResponse>;
  CategoriesByLanguage: ResolverTypeWrapper<CategoriesByLanguage>;
  CategoriesResult: ResolverTypeWrapper<CategoriesResult>;
  Category: ResolverTypeWrapper<Category>;
  ChildReport: ResolverTypeWrapper<ChildReport>;
  Club: ResolverTypeWrapper<Omit<Club, 'members'> & { members: ResolversTypes['MemberSearchResults'] }>;
  ClubAffiliation: ResolverTypeWrapper<Omit<ClubAffiliation, 'affiliations'> & { affiliations: Array<ResolversTypes['Affiliation']> }>;
  ClubAffiliationFilters: ClubAffiliationFilters;
  ClubAssignmentsResponse: ResolverTypeWrapper<ClubAssignmentsResponse>;
  ClubAssignmentsResult: ResolverTypeWrapper<ClubAssignmentsResult>;
  ClubCredits: ResolverTypeWrapper<ClubCredits>;
  ClubCreditsDeltas: ResolverTypeWrapper<ClubCreditsDeltas>;
  ClubDetails: ResolverTypeWrapper<ClubDetails>;
  ClubDetailsResult: ResolverTypeWrapper<ClubDetailsResult>;
  ClubDevelopment: ResolverTypeWrapper<ClubDevelopment>;
  ClubInvoiceResult: ResolverTypeWrapper<ClubInvoiceResult>;
  ClubLeadership: ResolverTypeWrapper<ClubLeadership>;
  ClubLeadershipItem: ResolverTypeWrapper<ClubLeadershipItem>;
  ClubLocation: ResolverTypeWrapper<ClubLocation>;
  ClubMemberDetails: ResolverTypeWrapper<ClubMemberDetails>;
  ClubMembersResult: ResolverTypeWrapper<ClubMembersResult>;
  ClubMembership: ResolverTypeWrapper<ClubMembership>;
  ClubOperationsAccess: ResolverTypeWrapper<ClubOperationsAccess>;
  ClubRelationship: ResolverTypeWrapper<ClubRelationship>;
  ClubSearchConstants: ResolverTypeWrapper<ClubSearchConstants>;
  ClubSearchFilterConstants: ResolverTypeWrapper<ClubSearchFilterConstants>;
  ClubSearchResult: ResolverTypeWrapper<ClubSearchResult>;
  ClubSearchResultClubRelationship: ResolverTypeWrapper<ClubSearchResultClubRelationship>;
  ClubSearchResultMeeting: ResolverTypeWrapper<ClubSearchResultMeeting>;
  ClubSearchResults: ResolverTypeWrapper<ClubSearchResults>;
  ClubSearchResutLocation: ResolverTypeWrapper<ClubSearchResutLocation>;
  ClubStatus: ClubStatus;
  ClubStatusResponse: ResolverTypeWrapper<ClubStatusResponse>;
  ClubType: ClubType;
  ClubTypeFilterValue: ClubTypeFilterValue;
  ClubVendor: ResolverTypeWrapper<ClubVendor>;
  CommitteeNominations: ResolverTypeWrapper<CommitteeNominations>;
  CommitteeRolesResult: ResolverTypeWrapper<CommitteeRolesResult>;
  ConferenceAddress: ResolverTypeWrapper<ConferenceAddress>;
  ConferenceFeedbackResult: ResolverTypeWrapper<ConferenceFeedbackResult>;
  Constants: ResolverTypeWrapper<Constants>;
  ContactFormInput: ContactFormInput;
  Content: ResolverTypeWrapper<Content>;
  ContentConfig: ResolverTypeWrapper<ContentConfig>;
  Contribution: ResolverTypeWrapper<Contribution>;
  ContributionDeltas: ResolverTypeWrapper<ContributionDeltas>;
  ContributionItem: ResolverTypeWrapper<ContributionItem>;
  Country: ResolverTypeWrapper<Country>;
  CountryCurrency: ResolverTypeWrapper<CountryCurrency>;
  CountryCurrencyMapped: ResolverTypeWrapper<CountryCurrencyMapped>;
  CountryDetails: ResolverTypeWrapper<CountryDetails>;
  CountryTNames: ResolverTypeWrapper<CountryTNames>;
  CountryTranslations: ResolverTypeWrapper<CountryTranslations>;
  CreateIndividualInput: CreateIndividualInput;
  CreateIndividualProgramInput: CreateIndividualProgramInput;
  CreateOrUpdateIndividualResult: ResolverTypeWrapper<CreateOrUpdateIndividualResult>;
  CreditOrg: ResolverTypeWrapper<CreditOrg>;
  CreditOrgDetails: ResolverTypeWrapper<CreditOrgDetails>;
  Currency: ResolverTypeWrapper<Currency>;
  CurrencyDetails: ResolverTypeWrapper<CurrencyDetails>;
  CurrencyId: ResolverTypeWrapper<CurrencyId>;
  CurrencyTNames: ResolverTypeWrapper<CurrencyTNames>;
  CurrencyTranslations: ResolverTypeWrapper<CurrencyTranslations>;
  DESDistrictInfo: ResolverTypeWrapper<DesDistrictInfo>;
  DGResult: ResolverTypeWrapper<DgResult>;
  DashboardClubType: DashboardClubType;
  DaySpecifier: ResolverTypeWrapper<DaySpecifier>;
  Delegates: ResolverTypeWrapper<Delegates>;
  DelegationRoles: ResolverTypeWrapper<DelegationRoles>;
  Delegations: ResolverTypeWrapper<Delegations>;
  DeleteClubMeetingInput: DeleteClubMeetingInput;
  DeleteClubMeetingResult: ResolverTypeWrapper<DeleteClubMeetingResult>;
  DeleteDelegationInput: DeleteDelegationInput;
  DeleteIndividualProgramInput: DeleteIndividualProgramInput;
  Demographic: ResolverTypeWrapper<Demographic>;
  DisplayNames: ResolverTypeWrapper<DisplayNames>;
  District: ResolverTypeWrapper<District>;
  DistrictAddress: ResolverTypeWrapper<DistrictAddress>;
  DistrictAddressInput: DistrictAddressInput;
  DistrictClub: ResolverTypeWrapper<DistrictClub>;
  DistrictClubsResults: ResolverTypeWrapper<DistrictClubsResults>;
  DistrictClubsStatistics: ResolverTypeWrapper<DistrictClubsStatistics>;
  DistrictDIS3: ResolverTypeWrapper<DistrictDis3>;
  DistrictDashboardsResults: ResolverTypeWrapper<DistrictDashboardsResults>;
  DistrictData: ResolverTypeWrapper<DistrictData>;
  DistrictDetailsInput: DistrictDetailsInput;
  DistrictEmailInput: DistrictEmailInput;
  DistrictEmails: ResolverTypeWrapper<DistrictEmails>;
  DistrictEmailsInput: DistrictEmailsInput;
  DistrictFax: ResolverTypeWrapper<DistrictFax>;
  DistrictFaxInput: DistrictFaxInput;
  DistrictLeadership: ResolverTypeWrapper<DistrictLeadership>;
  DistrictLeadershipResult: ResolverTypeWrapper<DistrictLeadershipResult>;
  DistrictMembersStatistics: ResolverTypeWrapper<DistrictMembersStatistics>;
  DistrictMembershipClub: ResolverTypeWrapper<DistrictMembershipClub>;
  DistrictOfficer: ResolverTypeWrapper<DistrictOfficer>;
  DistrictOfficersResults: ResolverTypeWrapper<DistrictOfficersResults>;
  DistrictPhone: ResolverTypeWrapper<DistrictPhone>;
  DistrictPhoneInput: DistrictPhoneInput;
  DistrictType: ResolverTypeWrapper<DistrictType>;
  DistrictWebsiteInput: DistrictWebsiteInput;
  DistrictWebsites: ResolverTypeWrapper<DistrictWebsites>;
  DistrictsResult: ResolverTypeWrapper<DistrictsResult>;
  DominoContactInfo: DominoContactInfo;
  DominoContactInfoAddress: DominoContactInfoAddress;
  DominoContactInfoEmail: DominoContactInfoEmail;
  DominoContactInfoPhone: DominoContactInfoPhone;
  DominoEmailInput: DominoEmailInput;
  DominoEmailType: ResolverTypeWrapper<DominoEmailType>;
  ECardDetailsResult: ResolverTypeWrapper<ECardDetailsResult>;
  EditDelegationInput: EditDelegationInput;
  EditMeetingInput: EditMeetingInput;
  EditMeetingResult: ResolverTypeWrapper<EditMeetingResult>;
  Email: ResolverTypeWrapper<Email>;
  EmailAddress: ResolverTypeWrapper<EmailAddress>;
  EmailInput: EmailInput;
  EmailType: EmailType;
  ErrorAssignmentsResult: ResolverTypeWrapper<ErrorAssignmentsResult>;
  ErrorBatch: ResolverTypeWrapper<ErrorBatch>;
  ErrorBody: ResolverTypeWrapper<ErrorBody>;
  ExchangeRates: ResolverTypeWrapper<ExchangeRates>;
  Expertise: ResolverTypeWrapper<Expertise>;
  ExpertiseArea: ResolverTypeWrapper<ExpertiseArea>;
  ExpertiseAreaItem: ResolverTypeWrapper<ExpertiseAreaItem>;
  ExpertiseInput: ExpertiseInput;
  ExpertiseLevel: ResolverTypeWrapper<ExpertiseLevel>;
  ExpertiseLevelItem: ResolverTypeWrapper<ExpertiseLevelItem>;
  Fax: ResolverTypeWrapper<Fax>;
  FaxInput: FaxInput;
  FaxType: FaxType;
  Feedback: ResolverTypeWrapper<Feedback>;
  FeedbackQuestionsResponse: ResolverTypeWrapper<FeedbackQuestionsResponse>;
  FeedbackSection: ResolverTypeWrapper<FeedbackSection>;
  FieldCharSet: FieldCharSet;
  FieldConstraints: ResolverTypeWrapper<FieldConstraints>;
  FieldOption: ResolverTypeWrapper<FieldOption>;
  FieldType: FieldType;
  FileContentResult: ResolverTypeWrapper<FileContentResult>;
  FinancialReprentatives: ResolverTypeWrapper<FinancialReprentatives>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  FormattedClub: ResolverTypeWrapper<FormattedClub>;
  FormattedLocation: ResolverTypeWrapper<FormattedLocation>;
  FormattedMembership: ResolverTypeWrapper<FormattedMembership>;
  FundSubtitle: ResolverTypeWrapper<FundSubtitle>;
  FundTitle: ResolverTypeWrapper<FundTitle>;
  Funding: ResolverTypeWrapper<Funding>;
  Funds: ResolverTypeWrapper<Funds>;
  Gender: Gender;
  GeoLocation: ResolverTypeWrapper<GeoLocation>;
  GetAccessLevelsResults: ResolverTypeWrapper<GetAccessLevelsResults>;
  GetAddDelegationResult: ResolverTypeWrapper<GetAddDelegationResult>;
  GetAllDistrictOfficersOptions: GetAllDistrictOfficersOptions;
  GetConferenceDetailsResult: ResolverTypeWrapper<GetConferenceDetailsResult>;
  GetConferencesResults: ResolverTypeWrapper<GetConferencesResults>;
  GetDelegationProfileResult: ResolverTypeWrapper<GetDelegationProfileResult>;
  GetEditedDelegationResult: ResolverTypeWrapper<GetEditedDelegationResult>;
  GetPresRepConferencesResult: ResolverTypeWrapper<GetPresRepConferencesResult>;
  GinResult: ResolverTypeWrapper<GinResult>;
  GrantDetailsResult: ResolverTypeWrapper<GrantDetailsResult>;
  GrantLocation: ResolverTypeWrapper<GrantLocation>;
  GroupApplicationRolesRelationshipType: ResolverTypeWrapper<GroupApplicationRolesRelationshipType>;
  GroupApplicationsType: ResolverTypeWrapper<GroupApplicationsType>;
  GroupRolesType: ResolverTypeWrapper<GroupRolesType>;
  HATEOASLink: ResolverTypeWrapper<HateoasLink>;
  Individual: ResolverTypeWrapper<Individual>;
  IndividualAddress: ResolverTypeWrapper<IndividualAddress>;
  IndividualAffiliationInfo: ResolverTypeWrapper<IndividualAffiliationInfo>;
  IndividualDESResult: ResolverTypeWrapper<IndividualDesResult>;
  IndividualDetails: ResolverTypeWrapper<IndividualDetails>;
  IndividualEditPermissions: ResolverTypeWrapper<IndividualEditPermissions>;
  IndividualEmail: ResolverTypeWrapper<IndividualEmail>;
  IndividualInfo: ResolverTypeWrapper<IndividualInfo>;
  IndividualLanguage: ResolverTypeWrapper<IndividualLanguage>;
  IndividualMemberDetailsResult: ResolverTypeWrapper<IndividualMemberDetailsResult>;
  IndividualOperationsAccessGql: ResolverTypeWrapper<IndividualOperationsAccessGql>;
  IndividualOrganization: ResolverTypeWrapper<IndividualOrganization>;
  IndividualPhone: ResolverTypeWrapper<IndividualPhone>;
  IndividualPhoneInput: IndividualPhoneInput;
  IndividualProfileDetailsResult: ResolverTypeWrapper<IndividualProfileDetailsResult>;
  IndividualRole: ResolverTypeWrapper<IndividualRole>;
  IndividualSearchResult: ResolverTypeWrapper<DIS.SearchIndividualRow>;
  IndividualSearchResults: ResolverTypeWrapper<Omit<IndividualSearchResults, 'results'> & { results: Array<ResolversTypes['IndividualSearchResult']> }>;
  IndividualSecurityGroupsResult: ResolverTypeWrapper<IndividualSecurityGroupsResult>;
  InputAccomodationPhone: InputAccomodationPhone;
  InputConferenceAddress: InputConferenceAddress;
  InputCreateConference: InputCreateConference;
  InputCreateFeedback: InputCreateFeedback;
  InputFeedbackSection: InputFeedbackSection;
  InputOptionDetail: InputOptionDetail;
  InputOptionType: InputOptionType;
  InputQuestion: InputQuestion;
  InputQuestionResponse: InputQuestionResponse;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  InvoiceItem: ResolverTypeWrapper<InvoiceItem>;
  LELegalEntityTranslations: ResolverTypeWrapper<LeLegalEntityTranslations>;
  Language: ResolverTypeWrapper<Language>;
  LanguageDetails: ResolverTypeWrapper<LanguageDetails>;
  LanguageExtended: ResolverTypeWrapper<LanguageExtended>;
  LanguageInput: LanguageInput;
  Leadership: ResolverTypeWrapper<Leadership>;
  LeadershipAffiliation: ResolverTypeWrapper<LeadershipAffiliation>;
  LeadershipEmbeddedDataBase: ResolverTypeWrapper<LeadershipEmbeddedDataBase>;
  LeadershipResponseResult: ResolverTypeWrapper<LeadershipResponseResult>;
  LegalEntity: ResolverTypeWrapper<LegalEntity>;
  LegalEntityResult: ResolverTypeWrapper<LegalEntityResult>;
  LegalEntityTranslations: ResolverTypeWrapper<LegalEntityTranslations>;
  Link: ResolverTypeWrapper<Link>;
  Links: ResolverTypeWrapper<Links>;
  Location: ResolverTypeWrapper<Location>;
  LocationData: ResolverTypeWrapper<LocationData>;
  LocationDetails: ResolverTypeWrapper<LocationDetails>;
  LocationInfo: ResolverTypeWrapper<LocationInfo>;
  LogLevel: LogLevel;
  Meeting: ResolverTypeWrapper<Meeting>;
  MeetingInput: MeetingInput;
  MeetingType: MeetingType;
  MeetingTypeFilterValue: MeetingTypeFilterValue;
  MemberOrganizationDetailsResult: ResolverTypeWrapper<MemberOrganizationDetailsResult>;
  MemberSearchResult: ResolverTypeWrapper<MemberSearchResultProxy>;
  MemberSearchResults: ResolverTypeWrapper<Omit<MemberSearchResults, 'results'> & { results: Array<ResolversTypes['MemberSearchResult']> }>;
  MembersTrends: ResolverTypeWrapper<MembersTrends>;
  Membership: ResolverTypeWrapper<MembershipProxy>;
  MembershipAffiliation: ResolverTypeWrapper<MembershipAffiliation>;
  MembershipEmbeddedDataBase: ResolverTypeWrapper<MembershipEmbeddedDataBase>;
  MembershipForAddMember: ResolverTypeWrapper<MembershipForAddMember>;
  MembershipHistoryBase: ResolverTypeWrapper<MembershipHistoryBase>;
  MembershipStatusFilter: MembershipStatusFilter;
  MembershipType: MembershipType;
  MembershipsList: ResolverTypeWrapper<MembershipsList>;
  MonthlyData: ResolverTypeWrapper<MonthlyData>;
  MovedItem: MovedItem;
  Mutation: ResolverTypeWrapper<{}>;
  OfficerIndividual: ResolverTypeWrapper<OfficerIndividual>;
  OfficerMembership: ResolverTypeWrapper<OfficerMembership>;
  OktaProfileDataType: ResolverTypeWrapper<OktaProfileDataType>;
  OpenInvoiceListResult: ResolverTypeWrapper<OpenInvoiceListResult>;
  OptionDetail: ResolverTypeWrapper<OptionDetail>;
  OptionType: ResolverTypeWrapper<OptionType>;
  OrgType: OrgType;
  OrganisationDataBase: ResolverTypeWrapper<OrganisationDataBase>;
  Organization: ResolverTypeWrapper<Organization>;
  OrganizationAddress: ResolverTypeWrapper<OrganizationAddress>;
  OrganizationBase: OrganizationBase;
  OrganizationData: ResolverTypeWrapper<OrganizationData>;
  OrganizationDetailsResult: ResolverTypeWrapper<OrganizationDetailsResult>;
  ParticipatingDistricts: ResolverTypeWrapper<ParticipatingDistricts>;
  ParticipatingDistrictsInput: ParticipatingDistrictsInput;
  Payment: ResolverTypeWrapper<Payment>;
  PaymentProfileNameResult: ResolverTypeWrapper<PaymentProfileNameResult>;
  PaymentProfileResult: ResolverTypeWrapper<PaymentProfileResult>;
  PhoneInput: PhoneInput;
  PhoneNumber: ResolverTypeWrapper<PhoneNumber>;
  PhoneNumbers: ResolverTypeWrapper<PhoneNumbers>;
  PhoneType: PhoneType;
  PhysicalLocation: ResolverTypeWrapper<PhysicalLocation>;
  PresidentialRepresentative: ResolverTypeWrapper<PresidentialRepresentative>;
  PrimaryPhoneInput: PrimaryPhoneInput;
  PrivacyPolicyTMessages: ResolverTypeWrapper<PrivacyPolicyTMessages>;
  Profession: ResolverTypeWrapper<Profession>;
  ProfessionInput: ProfessionInput;
  Program: ResolverTypeWrapper<Program>;
  ProgramDefinition: ResolverTypeWrapper<ProgramDefinition>;
  ProgramField: ResolverTypeWrapper<ProgramField>;
  ProgramFieldDefinition: ResolverTypeWrapper<ProgramFieldDefinition>;
  ProgramFieldGroup: ResolverTypeWrapper<ProgramFieldGroup>;
  ProgramFieldInput: ProgramFieldInput;
  Query: ResolverTypeWrapper<{}>;
  Question: ResolverTypeWrapper<Question>;
  QuestionResponse: ResolverTypeWrapper<QuestionResponse>;
  Reason: Reason;
  Recognition: ResolverTypeWrapper<Recognition>;
  RecognitionClub: ResolverTypeWrapper<RecognitionClub>;
  RecognitionDataInfo: ResolverTypeWrapper<RecognitionDataInfo>;
  RecognitionIndividual: ResolverTypeWrapper<RecognitionIndividual>;
  RecognitionItem: ResolverTypeWrapper<RecognitionItem>;
  RecognitionItemInfo: ResolverTypeWrapper<RecognitionItemInfo>;
  RecognitionMembership: ResolverTypeWrapper<RecognitionMembership>;
  Recurrence: ResolverTypeWrapper<Recurrence>;
  RecurrenceFrequency: RecurrenceFrequency;
  RecurrenceRule: ResolverTypeWrapper<RecurrenceRule>;
  RecurrenceSpecifiers: ResolverTypeWrapper<RecurrenceSpecifiers>;
  RegionalGroupDistricts: ResolverTypeWrapper<RegionalGroupDistricts>;
  Report: ResolverTypeWrapper<Report>;
  RequestSource: ResolverTypeWrapper<RequestSource>;
  ResumeItem: ResolverTypeWrapper<ResumeItem>;
  ResumeRole: ResolverTypeWrapper<ResumeRole>;
  ResumeRoles: ResolverTypeWrapper<ResumeRoles>;
  Role: ResolverTypeWrapper<Role>;
  RoleAccess: ResolverTypeWrapper<RoleAccess>;
  RolesAuthenticationTicketDataType: ResolverTypeWrapper<RolesAuthenticationTicketDataType>;
  SecurityQuestion: ResolverTypeWrapper<SecurityQuestion>;
  SharingPermission: ResolverTypeWrapper<SharingPermission>;
  SharingPermissionResult: ResolverTypeWrapper<SharingPermissionResult>;
  SharingPermissionsExtended: ResolverTypeWrapper<SharingPermissionsExtended>;
  SharingPermissionsInput: SharingPermissionsInput;
  SharingPermissionsRegular: ResolverTypeWrapper<SharingPermissionsRegular>;
  SharingPermissionsResult: ResolverTypeWrapper<SharingPermissionsResult>;
  ShortClubInfo: ResolverTypeWrapper<ShortClubInfo>;
  SiteSearchResults: ResolverTypeWrapper<SiteSearchResults>;
  SitecoreReportTypeEnum: SitecoreReportTypeEnum;
  SolrFacetFieldOption: ResolverTypeWrapper<SolrFacetFieldOption>;
  SolrFacetFields: ResolverTypeWrapper<SolrFacetFields>;
  SolrFacets: ResolverTypeWrapper<SolrFacets>;
  SolrHighlighting: ResolverTypeWrapper<SolrHighlighting>;
  SolrResponse: ResolverTypeWrapper<SolrResponse>;
  SolrResponseHeader: ResolverTypeWrapper<SolrResponseHeader>;
  SolrResultItem: ResolverTypeWrapper<SolrResultItem>;
  SponsorshipItem: ResolverTypeWrapper<SponsorshipItem>;
  SponsorshipResult: ResolverTypeWrapper<SponsorshipResult>;
  State: ResolverTypeWrapper<State>;
  StatesByCountryResult: ResolverTypeWrapper<StatesByCountryResult>;
  String: ResolverTypeWrapper<Scalars['String']>;
  SubReport: ResolverTypeWrapper<SubReport>;
  SubSponser: ResolverTypeWrapper<SubSponser>;
  Subscriptions: ResolverTypeWrapper<Subscriptions>;
  SuccessAssignmentsResult: ResolverTypeWrapper<SuccessAssignmentsResult>;
  SuccessBody: ResolverTypeWrapper<SuccessBody>;
  Term: ResolverTypeWrapper<Term>;
  TerminateClubVendorInput: TerminateClubVendorInput;
  TerminateClubVendorResult: ResolverTypeWrapper<TerminateClubVendorResult>;
  TerminationReason: ResolverTypeWrapper<TerminationReason>;
  TimeZone: ResolverTypeWrapper<TimeZone>;
  TrackedGiftDetailsResult: ResolverTypeWrapper<TrackedGiftDetailsResult>;
  TransactionTypeResult: ResolverTypeWrapper<TransactionTypeResult>;
  TransactionTypeTranslations: ResolverTypeWrapper<TransactionTypeTranslations>;
  UpdateClubInput: UpdateClubInput;
  UpdateClubResult: ResolverTypeWrapper<UpdateClubResult>;
  UpdateContactInfoInput: UpdateContactInfoInput;
  UpdateIndividualInput: UpdateIndividualInput;
  UpdateIndividualProgramInput: UpdateIndividualProgramInput;
  User: ResolverTypeWrapper<User>;
  UserProfile: ResolverTypeWrapper<UserProfile>;
  Vendor: ResolverTypeWrapper<Vendor>;
  VendorRequestor: ResolverTypeWrapper<VendorRequestor>;
  Void: ResolverTypeWrapper<Scalars['Void']>;
  Website: ResolverTypeWrapper<Website>;
  WebsiteInput: WebsiteInput;
  WebsiteType: ResolverTypeWrapper<WebsiteType>;
  WeekDay: WeekDay;
  WeekdayFull: WeekdayFull;
  addFiscalCodeError: ResolverTypeWrapper<AddFiscalCodeError>;
  addPanError: ResolverTypeWrapper<AddPanError>;
  addRrnError: ResolverTypeWrapper<AddRrnError>;
  addSponsorError: ResolverTypeWrapper<AddSponsorError>;
  createLeadershipError: ResolverTypeWrapper<CreateLeadershipError>;
  createLeadershipResult: ResolverTypeWrapper<CreateLeadershipResult>;
  createMembershipError: ResolverTypeWrapper<CreateMembershipError>;
  createMembershipResult: ResolverTypeWrapper<CreateMembershipResult>;
  ecardAddResult: ResolverTypeWrapper<EcardAddResult>;
  fiscalCodeInfoResult: ResolverTypeWrapper<FiscalCodeInfoResult>;
  leadershipResult: ResolverTypeWrapper<LeadershipResult>;
  membershipResult: ResolverTypeWrapper<MembershipResult>;
  panInfoResult: ResolverTypeWrapper<PanInfoResult>;
  rrnInfoResult: ResolverTypeWrapper<RrnInfoResult>;
  terminateLeadershipError: ResolverTypeWrapper<TerminateLeadershipError>;
  terminateMembershipError: ResolverTypeWrapper<TerminateMembershipError>;
  updateMembershipTypeResult: ResolverTypeWrapper<UpdateMembershipTypeResult>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AGResult: AgResult;
  AccPhone: AccPhone;
  AccessLevelsArguments: AccessLevelsArguments;
  AccessLevelsOptions: AccessLevelsOptions;
  AccountInfoByToken: AccountInfoByToken;
  ActiveMembership: ActiveMembership;
  AddClubMeetingInput: AddClubMeetingInput;
  AddClubMeetingResult: AddClubMeetingResult;
  AddClubVendorInput: AddClubVendorInput;
  AddClubVendorResult: AddClubVendorResult;
  AddDelegationInput: AddDelegationInput;
  AddMemberIndividualResult: AddMemberIndividualResult;
  Address: Address;
  AddressDetails: AddressDetails;
  AddressInfo: AddressInfo;
  AddressInput: AddressInput;
  Affiliation: ResolversParentTypes['LeadershipAffiliation'] | ResolversParentTypes['MembershipAffiliation'];
  AgClubAffiliation: AgClubAffiliation;
  ApplicationModeResult: ApplicationModeResult;
  AssistantGovernor: AssistantGovernor;
  AssistantGovernorByDate: AssistantGovernorByDate;
  AssistantGovernorTermInfo: AssistantGovernorTermInfo;
  AssistantGovernorsByDateResults: AssistantGovernorsByDateResults;
  AssistantGovernorsResults: AssistantGovernorsResults;
  BACountry: BaCountry;
  BankAccount: BankAccount;
  BankAssignmentResult: BankAssignmentResult;
  BaseAddress: BaseAddress;
  BaseAddressInput: BaseAddressInput;
  BasicInfo: BasicInfo;
  BasicLanguageInput: BasicLanguageInput;
  Boolean: Scalars['Boolean'];
  CDSRepresentatives: CdsRepresentatives;
  CRMIndividualInfoType: CrmIndividualInfoType;
  CalendarEvent: ResolversParentTypes['ClubSearchResultMeeting'];
  CancelSubscriptionResponse: CancelSubscriptionResponse;
  CategoriesByLanguage: CategoriesByLanguage;
  CategoriesResult: CategoriesResult;
  Category: Category;
  ChildReport: ChildReport;
  Club: Omit<Club, 'members'> & { members: ResolversParentTypes['MemberSearchResults'] };
  ClubAffiliation: Omit<ClubAffiliation, 'affiliations'> & { affiliations: Array<ResolversParentTypes['Affiliation']> };
  ClubAffiliationFilters: ClubAffiliationFilters;
  ClubAssignmentsResponse: ClubAssignmentsResponse;
  ClubAssignmentsResult: ClubAssignmentsResult;
  ClubCredits: ClubCredits;
  ClubCreditsDeltas: ClubCreditsDeltas;
  ClubDetails: ClubDetails;
  ClubDetailsResult: ClubDetailsResult;
  ClubDevelopment: ClubDevelopment;
  ClubInvoiceResult: ClubInvoiceResult;
  ClubLeadership: ClubLeadership;
  ClubLeadershipItem: ClubLeadershipItem;
  ClubLocation: ClubLocation;
  ClubMemberDetails: ClubMemberDetails;
  ClubMembersResult: ClubMembersResult;
  ClubMembership: ClubMembership;
  ClubOperationsAccess: ClubOperationsAccess;
  ClubRelationship: ClubRelationship;
  ClubSearchConstants: ClubSearchConstants;
  ClubSearchFilterConstants: ClubSearchFilterConstants;
  ClubSearchResult: ClubSearchResult;
  ClubSearchResultClubRelationship: ClubSearchResultClubRelationship;
  ClubSearchResultMeeting: ClubSearchResultMeeting;
  ClubSearchResults: ClubSearchResults;
  ClubSearchResutLocation: ClubSearchResutLocation;
  ClubStatusResponse: ClubStatusResponse;
  ClubVendor: ClubVendor;
  CommitteeNominations: CommitteeNominations;
  CommitteeRolesResult: CommitteeRolesResult;
  ConferenceAddress: ConferenceAddress;
  ConferenceFeedbackResult: ConferenceFeedbackResult;
  Constants: Constants;
  ContactFormInput: ContactFormInput;
  Content: Content;
  ContentConfig: ContentConfig;
  Contribution: Contribution;
  ContributionDeltas: ContributionDeltas;
  ContributionItem: ContributionItem;
  Country: Country;
  CountryCurrency: CountryCurrency;
  CountryCurrencyMapped: CountryCurrencyMapped;
  CountryDetails: CountryDetails;
  CountryTNames: CountryTNames;
  CountryTranslations: CountryTranslations;
  CreateIndividualInput: CreateIndividualInput;
  CreateIndividualProgramInput: CreateIndividualProgramInput;
  CreateOrUpdateIndividualResult: CreateOrUpdateIndividualResult;
  CreditOrg: CreditOrg;
  CreditOrgDetails: CreditOrgDetails;
  Currency: Currency;
  CurrencyDetails: CurrencyDetails;
  CurrencyId: CurrencyId;
  CurrencyTNames: CurrencyTNames;
  CurrencyTranslations: CurrencyTranslations;
  DESDistrictInfo: DesDistrictInfo;
  DGResult: DgResult;
  DaySpecifier: DaySpecifier;
  Delegates: Delegates;
  DelegationRoles: DelegationRoles;
  Delegations: Delegations;
  DeleteClubMeetingInput: DeleteClubMeetingInput;
  DeleteClubMeetingResult: DeleteClubMeetingResult;
  DeleteDelegationInput: DeleteDelegationInput;
  DeleteIndividualProgramInput: DeleteIndividualProgramInput;
  Demographic: Demographic;
  DisplayNames: DisplayNames;
  District: District;
  DistrictAddress: DistrictAddress;
  DistrictAddressInput: DistrictAddressInput;
  DistrictClub: DistrictClub;
  DistrictClubsResults: DistrictClubsResults;
  DistrictClubsStatistics: DistrictClubsStatistics;
  DistrictDIS3: DistrictDis3;
  DistrictDashboardsResults: DistrictDashboardsResults;
  DistrictData: DistrictData;
  DistrictDetailsInput: DistrictDetailsInput;
  DistrictEmailInput: DistrictEmailInput;
  DistrictEmails: DistrictEmails;
  DistrictEmailsInput: DistrictEmailsInput;
  DistrictFax: DistrictFax;
  DistrictFaxInput: DistrictFaxInput;
  DistrictLeadership: DistrictLeadership;
  DistrictLeadershipResult: DistrictLeadershipResult;
  DistrictMembersStatistics: DistrictMembersStatistics;
  DistrictMembershipClub: DistrictMembershipClub;
  DistrictOfficer: DistrictOfficer;
  DistrictOfficersResults: DistrictOfficersResults;
  DistrictPhone: DistrictPhone;
  DistrictPhoneInput: DistrictPhoneInput;
  DistrictType: DistrictType;
  DistrictWebsiteInput: DistrictWebsiteInput;
  DistrictWebsites: DistrictWebsites;
  DistrictsResult: DistrictsResult;
  DominoContactInfo: DominoContactInfo;
  DominoContactInfoAddress: DominoContactInfoAddress;
  DominoContactInfoEmail: DominoContactInfoEmail;
  DominoContactInfoPhone: DominoContactInfoPhone;
  DominoEmailInput: DominoEmailInput;
  DominoEmailType: DominoEmailType;
  ECardDetailsResult: ECardDetailsResult;
  EditDelegationInput: EditDelegationInput;
  EditMeetingInput: EditMeetingInput;
  EditMeetingResult: EditMeetingResult;
  Email: Email;
  EmailAddress: EmailAddress;
  EmailInput: EmailInput;
  ErrorAssignmentsResult: ErrorAssignmentsResult;
  ErrorBatch: ErrorBatch;
  ErrorBody: ErrorBody;
  ExchangeRates: ExchangeRates;
  Expertise: Expertise;
  ExpertiseArea: ExpertiseArea;
  ExpertiseAreaItem: ExpertiseAreaItem;
  ExpertiseInput: ExpertiseInput;
  ExpertiseLevel: ExpertiseLevel;
  ExpertiseLevelItem: ExpertiseLevelItem;
  Fax: Fax;
  FaxInput: FaxInput;
  Feedback: Feedback;
  FeedbackQuestionsResponse: FeedbackQuestionsResponse;
  FeedbackSection: FeedbackSection;
  FieldConstraints: FieldConstraints;
  FieldOption: FieldOption;
  FileContentResult: FileContentResult;
  FinancialReprentatives: FinancialReprentatives;
  Float: Scalars['Float'];
  FormattedClub: FormattedClub;
  FormattedLocation: FormattedLocation;
  FormattedMembership: FormattedMembership;
  FundSubtitle: FundSubtitle;
  FundTitle: FundTitle;
  Funding: Funding;
  Funds: Funds;
  GeoLocation: GeoLocation;
  GetAccessLevelsResults: GetAccessLevelsResults;
  GetAddDelegationResult: GetAddDelegationResult;
  GetAllDistrictOfficersOptions: GetAllDistrictOfficersOptions;
  GetConferenceDetailsResult: GetConferenceDetailsResult;
  GetConferencesResults: GetConferencesResults;
  GetDelegationProfileResult: GetDelegationProfileResult;
  GetEditedDelegationResult: GetEditedDelegationResult;
  GetPresRepConferencesResult: GetPresRepConferencesResult;
  GinResult: GinResult;
  GrantDetailsResult: GrantDetailsResult;
  GrantLocation: GrantLocation;
  GroupApplicationRolesRelationshipType: GroupApplicationRolesRelationshipType;
  GroupApplicationsType: GroupApplicationsType;
  GroupRolesType: GroupRolesType;
  HATEOASLink: HateoasLink;
  Individual: Individual;
  IndividualAddress: IndividualAddress;
  IndividualAffiliationInfo: IndividualAffiliationInfo;
  IndividualDESResult: IndividualDesResult;
  IndividualDetails: IndividualDetails;
  IndividualEditPermissions: IndividualEditPermissions;
  IndividualEmail: IndividualEmail;
  IndividualInfo: IndividualInfo;
  IndividualLanguage: IndividualLanguage;
  IndividualMemberDetailsResult: IndividualMemberDetailsResult;
  IndividualOperationsAccessGql: IndividualOperationsAccessGql;
  IndividualOrganization: IndividualOrganization;
  IndividualPhone: IndividualPhone;
  IndividualPhoneInput: IndividualPhoneInput;
  IndividualProfileDetailsResult: IndividualProfileDetailsResult;
  IndividualRole: IndividualRole;
  IndividualSearchResult: DIS.SearchIndividualRow;
  IndividualSearchResults: Omit<IndividualSearchResults, 'results'> & { results: Array<ResolversParentTypes['IndividualSearchResult']> };
  IndividualSecurityGroupsResult: IndividualSecurityGroupsResult;
  InputAccomodationPhone: InputAccomodationPhone;
  InputConferenceAddress: InputConferenceAddress;
  InputCreateConference: InputCreateConference;
  InputCreateFeedback: InputCreateFeedback;
  InputFeedbackSection: InputFeedbackSection;
  InputOptionDetail: InputOptionDetail;
  InputOptionType: InputOptionType;
  InputQuestion: InputQuestion;
  InputQuestionResponse: InputQuestionResponse;
  Int: Scalars['Int'];
  InvoiceItem: InvoiceItem;
  LELegalEntityTranslations: LeLegalEntityTranslations;
  Language: Language;
  LanguageDetails: LanguageDetails;
  LanguageExtended: LanguageExtended;
  LanguageInput: LanguageInput;
  Leadership: Leadership;
  LeadershipAffiliation: LeadershipAffiliation;
  LeadershipEmbeddedDataBase: LeadershipEmbeddedDataBase;
  LeadershipResponseResult: LeadershipResponseResult;
  LegalEntity: LegalEntity;
  LegalEntityResult: LegalEntityResult;
  LegalEntityTranslations: LegalEntityTranslations;
  Link: Link;
  Links: Links;
  Location: Location;
  LocationData: LocationData;
  LocationDetails: LocationDetails;
  LocationInfo: LocationInfo;
  Meeting: Meeting;
  MeetingInput: MeetingInput;
  MemberOrganizationDetailsResult: MemberOrganizationDetailsResult;
  MemberSearchResult: MemberSearchResultProxy;
  MemberSearchResults: Omit<MemberSearchResults, 'results'> & { results: Array<ResolversParentTypes['MemberSearchResult']> };
  MembersTrends: MembersTrends;
  Membership: MembershipProxy;
  MembershipAffiliation: MembershipAffiliation;
  MembershipEmbeddedDataBase: MembershipEmbeddedDataBase;
  MembershipForAddMember: MembershipForAddMember;
  MembershipHistoryBase: MembershipHistoryBase;
  MembershipsList: MembershipsList;
  MonthlyData: MonthlyData;
  MovedItem: MovedItem;
  Mutation: {};
  OfficerIndividual: OfficerIndividual;
  OfficerMembership: OfficerMembership;
  OktaProfileDataType: OktaProfileDataType;
  OpenInvoiceListResult: OpenInvoiceListResult;
  OptionDetail: OptionDetail;
  OptionType: OptionType;
  OrganisationDataBase: OrganisationDataBase;
  Organization: Organization;
  OrganizationAddress: OrganizationAddress;
  OrganizationData: OrganizationData;
  OrganizationDetailsResult: OrganizationDetailsResult;
  ParticipatingDistricts: ParticipatingDistricts;
  ParticipatingDistrictsInput: ParticipatingDistrictsInput;
  Payment: Payment;
  PaymentProfileNameResult: PaymentProfileNameResult;
  PaymentProfileResult: PaymentProfileResult;
  PhoneInput: PhoneInput;
  PhoneNumber: PhoneNumber;
  PhoneNumbers: PhoneNumbers;
  PhysicalLocation: PhysicalLocation;
  PresidentialRepresentative: PresidentialRepresentative;
  PrimaryPhoneInput: PrimaryPhoneInput;
  PrivacyPolicyTMessages: PrivacyPolicyTMessages;
  Profession: Profession;
  ProfessionInput: ProfessionInput;
  Program: Program;
  ProgramDefinition: ProgramDefinition;
  ProgramField: ProgramField;
  ProgramFieldDefinition: ProgramFieldDefinition;
  ProgramFieldGroup: ProgramFieldGroup;
  ProgramFieldInput: ProgramFieldInput;
  Query: {};
  Question: Question;
  QuestionResponse: QuestionResponse;
  Recognition: Recognition;
  RecognitionClub: RecognitionClub;
  RecognitionDataInfo: RecognitionDataInfo;
  RecognitionIndividual: RecognitionIndividual;
  RecognitionItem: RecognitionItem;
  RecognitionItemInfo: RecognitionItemInfo;
  RecognitionMembership: RecognitionMembership;
  Recurrence: Recurrence;
  RecurrenceRule: RecurrenceRule;
  RecurrenceSpecifiers: RecurrenceSpecifiers;
  RegionalGroupDistricts: RegionalGroupDistricts;
  Report: Report;
  RequestSource: RequestSource;
  ResumeItem: ResumeItem;
  ResumeRole: ResumeRole;
  ResumeRoles: ResumeRoles;
  Role: Role;
  RoleAccess: RoleAccess;
  RolesAuthenticationTicketDataType: RolesAuthenticationTicketDataType;
  SecurityQuestion: SecurityQuestion;
  SharingPermission: SharingPermission;
  SharingPermissionResult: SharingPermissionResult;
  SharingPermissionsExtended: SharingPermissionsExtended;
  SharingPermissionsInput: SharingPermissionsInput;
  SharingPermissionsRegular: SharingPermissionsRegular;
  SharingPermissionsResult: SharingPermissionsResult;
  ShortClubInfo: ShortClubInfo;
  SiteSearchResults: SiteSearchResults;
  SolrFacetFieldOption: SolrFacetFieldOption;
  SolrFacetFields: SolrFacetFields;
  SolrFacets: SolrFacets;
  SolrHighlighting: SolrHighlighting;
  SolrResponse: SolrResponse;
  SolrResponseHeader: SolrResponseHeader;
  SolrResultItem: SolrResultItem;
  SponsorshipItem: SponsorshipItem;
  SponsorshipResult: SponsorshipResult;
  State: State;
  StatesByCountryResult: StatesByCountryResult;
  String: Scalars['String'];
  SubReport: SubReport;
  SubSponser: SubSponser;
  Subscriptions: Subscriptions;
  SuccessAssignmentsResult: SuccessAssignmentsResult;
  SuccessBody: SuccessBody;
  Term: Term;
  TerminateClubVendorInput: TerminateClubVendorInput;
  TerminateClubVendorResult: TerminateClubVendorResult;
  TerminationReason: TerminationReason;
  TimeZone: TimeZone;
  TrackedGiftDetailsResult: TrackedGiftDetailsResult;
  TransactionTypeResult: TransactionTypeResult;
  TransactionTypeTranslations: TransactionTypeTranslations;
  UpdateClubInput: UpdateClubInput;
  UpdateClubResult: UpdateClubResult;
  UpdateContactInfoInput: UpdateContactInfoInput;
  UpdateIndividualInput: UpdateIndividualInput;
  UpdateIndividualProgramInput: UpdateIndividualProgramInput;
  User: User;
  UserProfile: UserProfile;
  Vendor: Vendor;
  VendorRequestor: VendorRequestor;
  Void: Scalars['Void'];
  Website: Website;
  WebsiteInput: WebsiteInput;
  WebsiteType: WebsiteType;
  addFiscalCodeError: AddFiscalCodeError;
  addPanError: AddPanError;
  addRrnError: AddRrnError;
  addSponsorError: AddSponsorError;
  createLeadershipError: CreateLeadershipError;
  createLeadershipResult: CreateLeadershipResult;
  createMembershipError: CreateMembershipError;
  createMembershipResult: CreateMembershipResult;
  ecardAddResult: EcardAddResult;
  fiscalCodeInfoResult: FiscalCodeInfoResult;
  leadershipResult: LeadershipResult;
  membershipResult: MembershipResult;
  panInfoResult: PanInfoResult;
  rrnInfoResult: RrnInfoResult;
  terminateLeadershipError: TerminateLeadershipError;
  terminateMembershipError: TerminateMembershipError;
  updateMembershipTypeResult: UpdateMembershipTypeResult;
};

export type AgResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['AGResult'] = ResolversParentTypes['AGResult']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccPhoneResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['AccPhone'] = ResolversParentTypes['AccPhone']> = {
  countryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessLevelsArgumentsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['AccessLevelsArguments'] = ResolversParentTypes['AccessLevelsArguments']> = {
  targetDistrictId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  targetTermYear?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountInfoByTokenResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['AccountInfoByToken'] = ResolversParentTypes['AccountInfoByToken']> = {
  accountId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  availableSecurityQuestions?: Resolver<Array<ResolversTypes['SecurityQuestion']>, ParentType, ContextType>;
  currentSecurityQuestion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActiveMembershipResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ActiveMembership'] = ResolversParentTypes['ActiveMembership']> = {
  admissionDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clubId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clubName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clubType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  districtId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  terminationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['MembershipType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddClubMeetingResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['AddClubMeetingResult'] = ResolversParentTypes['AddClubMeetingResult']> = {
  meeting?: Resolver<Maybe<ResolversTypes['Meeting']>, ParentType, ContextType>;
  meetingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddClubVendorResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['AddClubVendorResult'] = ResolversParentTypes['AddClubVendorResult']> = {
  vendorId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddMemberIndividualResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['AddMemberIndividualResult'] = ResolversParentTypes['AddMemberIndividualResult']> = {
  individual?: Resolver<ResolversTypes['Individual'], ParentType, ContextType>;
  membershipList?: Resolver<Maybe<Array<ResolversTypes['MembershipForAddMember']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  internationalProvince?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineOne?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineThree?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineTwo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stateId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AddressType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressDetailsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['AddressDetails'] = ResolversParentTypes['AddressDetails']> = {
  AddressLine1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AddressLine2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AddressLine3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InternationalProvince?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  State?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressInfoResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['AddressInfo'] = ResolversParentTypes['AddressInfo']> = {
  AddressLine1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AddressLine2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AddressLine3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AddressType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InternationalProvince?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsPrimary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastUpdated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MeetingComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MeetingDay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MeetingLocationName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MeetingName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MeetingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MeetingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MeetinglocationVariesFlag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OnlineLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  State?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AffiliationResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Affiliation'] = ResolversParentTypes['Affiliation']> = {
  __resolveType: TypeResolveFn<'LeadershipAffiliation' | 'MembershipAffiliation', ParentType, ContextType>;
};

export type AgClubAffiliationResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['AgClubAffiliation'] = ResolversParentTypes['AgClubAffiliation']> = {
  clubId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clubName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clubType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['LocationData']>, ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationModeResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ApplicationModeResult'] = ResolversParentTypes['ApplicationModeResult']> = {
  Config?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateCreated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsDeleted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModeKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssistantGovernorResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['AssistantGovernor'] = ResolversParentTypes['AssistantGovernor']> = {
  agId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clubsAffiliations?: Resolver<Maybe<Array<Maybe<ResolversTypes['AgClubAffiliation']>>>, ParentType, ContextType>;
  districtLeaderships?: Resolver<Maybe<Array<ResolversTypes['DistrictLeadership']>>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDeceased?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  localizedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  membershipClubs?: Resolver<Maybe<Array<Maybe<ResolversTypes['DistrictMembershipClub']>>>, ParentType, ContextType>;
  middleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photoUri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prefix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  suffix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  termInfo?: Resolver<Maybe<ResolversTypes['AssistantGovernorTermInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssistantGovernorByDateResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['AssistantGovernorByDate'] = ResolversParentTypes['AssistantGovernorByDate']> = {
  agId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clubsAffiliations?: Resolver<Maybe<Array<Maybe<ResolversTypes['AgClubAffiliation']>>>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  localizedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  middleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prefix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  suffix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssistantGovernorTermInfoResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['AssistantGovernorTermInfo'] = ResolversParentTypes['AssistantGovernorTermInfo']> = {
  endDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssistantGovernorsByDateResultsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['AssistantGovernorsByDateResults'] = ResolversParentTypes['AssistantGovernorsByDateResults']> = {
  ags?: Resolver<Array<Maybe<ResolversTypes['AssistantGovernorByDate']>>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssistantGovernorsResultsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['AssistantGovernorsResults'] = ResolversParentTypes['AssistantGovernorsResults']> = {
  ags?: Resolver<Array<Maybe<ResolversTypes['AssistantGovernor']>>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaCountryResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['BACountry'] = ResolversParentTypes['BACountry']> = {
  CountryISO2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountryISO3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountryKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountryTranslations?: Resolver<Maybe<Array<Maybe<ResolversTypes['CountryTranslations']>>>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateCreated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BankAccountResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['BankAccount'] = ResolversParentTypes['BankAccount']> = {
  AlternateBankAccName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BankAccountKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BankCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateCreated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BankAssignmentResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['BankAssignmentResult'] = ResolversParentTypes['BankAssignmentResult']> = {
  BankAccount?: Resolver<Maybe<ResolversTypes['BankAccount']>, ParentType, ContextType>;
  BankAccountAssignmentKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountryCurrency?: Resolver<Maybe<ResolversTypes['CountryCurrency']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateCreated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LegalEntity?: Resolver<Maybe<ResolversTypes['LegalEntity']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseAddressResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['BaseAddress'] = ResolversParentTypes['BaseAddress']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  internationalProvince?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineOne?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineThree?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineTwo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stateId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BasicInfoResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['BasicInfo'] = ResolversParentTypes['BasicInfo']> = {
  crmIndividualInfo?: Resolver<Maybe<ResolversTypes['CRMIndividualInfoType']>, ParentType, ContextType>;
  groupApplicationRolesRelationship?: Resolver<Maybe<Array<Maybe<ResolversTypes['GroupApplicationRolesRelationshipType']>>>, ParentType, ContextType>;
  oktaProfileData?: Resolver<Maybe<ResolversTypes['OktaProfileDataType']>, ParentType, ContextType>;
  rolesAuthenticationTicketData?: Resolver<Maybe<Array<Maybe<ResolversTypes['RolesAuthenticationTicketDataType']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CdsRepresentativesResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['CDSRepresentatives'] = ResolversParentTypes['CDSRepresentatives']> = {
  District?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Email_1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Email_2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Email_3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Fax_Number_1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Job_Title_1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Job_Title_2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Job_Title_3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Location_1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name_1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name_2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name_3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Phone_Number_1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Phone_Number_2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Phone_Number_3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrmIndividualInfoTypeResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['CRMIndividualInfoType'] = ResolversParentTypes['CRMIndividualInfoType']> = {
  individualKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  memberId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferredLanguage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userSortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarEventResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['CalendarEvent'] = ResolversParentTypes['CalendarEvent']> = {
  __resolveType: TypeResolveFn<'ClubSearchResultMeeting', ParentType, ContextType>;
  categories?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  comment?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dtend?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dtstamp?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dtstart?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  geo?: Resolver<Maybe<ResolversTypes['GeoLocation']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recurrence?: Resolver<Maybe<ResolversTypes['Recurrence']>, ParentType, ContextType>;
  uid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
};

export type CancelSubscriptionResponseResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['CancelSubscriptionResponse'] = ResolversParentTypes['CancelSubscriptionResponse']> = {
  Message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesByLanguageResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['CategoriesByLanguage'] = ResolversParentTypes['CategoriesByLanguage']> = {
  de?: Resolver<Maybe<Array<Maybe<ResolversTypes['Category']>>>, ParentType, ContextType>;
  en?: Resolver<Maybe<Array<Maybe<ResolversTypes['Category']>>>, ParentType, ContextType>;
  es?: Resolver<Maybe<Array<Maybe<ResolversTypes['Category']>>>, ParentType, ContextType>;
  fr?: Resolver<Maybe<Array<Maybe<ResolversTypes['Category']>>>, ParentType, ContextType>;
  it?: Resolver<Maybe<Array<Maybe<ResolversTypes['Category']>>>, ParentType, ContextType>;
  ja?: Resolver<Maybe<Array<Maybe<ResolversTypes['Category']>>>, ParentType, ContextType>;
  ko?: Resolver<Maybe<Array<Maybe<ResolversTypes['Category']>>>, ParentType, ContextType>;
  pt?: Resolver<Maybe<Array<Maybe<ResolversTypes['Category']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['CategoriesResult'] = ResolversParentTypes['CategoriesResult']> = {
  categories?: Resolver<Maybe<ResolversTypes['CategoriesByLanguage']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Category'] = ResolversParentTypes['Category']> = {
  blurb?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  content?: Resolver<Maybe<Array<Maybe<ResolversTypes['Content']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roleAccess?: Resolver<Maybe<ResolversTypes['RoleAccess']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChildReportResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ChildReport'] = ResolversParentTypes['ChildReport']> = {
  dataSelector?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  oktaId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  path?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subReports?: Resolver<Array<ResolversTypes['SubReport']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Club'] = ResolversParentTypes['Club']> = {
  charteredDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  childClubRelationships?: Resolver<Maybe<Array<ResolversTypes['ClubRelationship']>>, ParentType, ContextType>;
  clubId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clubName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clubType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  district?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  districtId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['DominoEmailType']>, ParentType, ContextType>;
  leaders?: Resolver<Maybe<Array<ResolversTypes['ClubLeadershipItem']>>, ParentType, ContextType, RequireFields<ClubLeadersArgs, 'page' | 'pageSize'>>;
  mailingAddress?: Resolver<Maybe<ResolversTypes['BaseAddress']>, ParentType, ContextType>;
  meetings?: Resolver<Array<ResolversTypes['Meeting']>, ParentType, ContextType>;
  members?: Resolver<ResolversTypes['MemberSearchResults'], ParentType, ContextType, RequireFields<ClubMembersArgs, 'isMultifilterAllowed' | 'page' | 'pageSize'>>;
  officialLanguage?: Resolver<Maybe<ResolversTypes['Language']>, ParentType, ContextType>;
  operationsAccess?: Resolver<ResolversTypes['ClubOperationsAccess'], ParentType, ContextType>;
  organizationBase?: Resolver<Maybe<ResolversTypes['OrganizationBase']>, ParentType, ContextType>;
  parentClubRelationships?: Resolver<Maybe<Array<ResolversTypes['ClubRelationship']>>, ParentType, ContextType>;
  physicalLocation?: Resolver<Maybe<ResolversTypes['BaseAddress']>, ParentType, ContextType>;
  primaryEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryFax?: Resolver<Maybe<ResolversTypes['Fax']>, ParentType, ContextType>;
  primaryPhone?: Resolver<Maybe<ResolversTypes['PhoneNumber']>, ParentType, ContextType>;
  primaryWebsite?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  riClubId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ClubStatus'], ParentType, ContextType>;
  vendors?: Resolver<Array<ResolversTypes['ClubVendor']>, ParentType, ContextType>;
  website?: Resolver<Maybe<ResolversTypes['WebsiteType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubAffiliationResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubAffiliation'] = ResolversParentTypes['ClubAffiliation']> = {
  affiliations?: Resolver<Array<ResolversTypes['Affiliation']>, ParentType, ContextType>;
  clubFullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clubId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clubLocation?: Resolver<Maybe<ResolversTypes['BaseAddress']>, ParentType, ContextType>;
  clubName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clubType?: Resolver<ResolversTypes['ClubType'], ParentType, ContextType>;
  district?: Resolver<Maybe<ResolversTypes['DistrictType']>, ParentType, ContextType>;
  meetings?: Resolver<Maybe<Array<Maybe<ResolversTypes['Meeting']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubAssignmentsResponseResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubAssignmentsResponse'] = ResolversParentTypes['ClubAssignmentsResponse']> = {
  errorBatch?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  finalResult?: Resolver<Maybe<ResolversTypes['ClubAssignmentsResult']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubAssignmentsResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubAssignmentsResult'] = ResolversParentTypes['ClubAssignmentsResult']> = {
  errorResults?: Resolver<Array<ResolversTypes['ErrorAssignmentsResult']>, ParentType, ContextType>;
  successResults?: Resolver<Array<ResolversTypes['SuccessAssignmentsResult']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubCreditsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubCredits'] = ResolversParentTypes['ClubCredits']> = {
  annualFund?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  asOfDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  polioPlus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  prevYearDeltas?: Resolver<ResolversTypes['ClubCreditsDeltas'], ParentType, ContextType>;
  rotaryYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubCreditsDeltasResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubCreditsDeltas'] = ResolversParentTypes['ClubCreditsDeltas']> = {
  annualFund?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  polioPlus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  prevYearAsOfDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubDetailsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubDetails'] = ResolversParentTypes['ClubDetails']> = {
  ClubCountry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ClubId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ClubName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MemberType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubDetailsResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubDetailsResult'] = ResolversParentTypes['ClubDetailsResult']> = {
  Address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CharterDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ClubId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ClubLanguage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ClubName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ClubSubType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ClubType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  District?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FaxCountryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FaxCountryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FaxExtension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FaxKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FaxLastUpdated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FaxNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FaxType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsPrimaryFax?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IsPrimaryPhone?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Latitude?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Location?: Resolver<Maybe<ResolversTypes['ClubLocation']>, ParentType, ContextType>;
  Longitude?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  NumberOfActiveMembers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  PhoneCountryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneCountryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneExtension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneLastUpdated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubDevelopmentResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubDevelopment'] = ResolversParentTypes['ClubDevelopment']> = {
  july1st?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  newClubs?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  newMembers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  terminatedClubs?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  terminatedMembers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  year?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubInvoiceResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubInvoiceResult'] = ResolversParentTypes['ClubInvoiceResult']> = {
  fileContent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubLeadershipResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubLeadership'] = ResolversParentTypes['ClubLeadership']> = {
  clubId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clubName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clubType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  districtId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  riClubId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  riDistrictId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roleId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubLeadershipItemResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubLeadershipItem'] = ResolversParentTypes['ClubLeadershipItem']> = {
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  individualId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  individualName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isDelegated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  photoUri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['Role'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubLocationResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubLocation'] = ResolversParentTypes['ClubLocation']> = {
  ClubCountry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ClubInternationalProvince?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ClubState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubMemberDetailsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubMemberDetails'] = ResolversParentTypes['ClubMemberDetails']> = {
  AddressCountry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AddressKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AddressLine1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AddressLine2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AddressLine3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AddressType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AdmissionDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BadgeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BirthYear?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Classification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateOfBirth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EmailAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EmailKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EmailType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FaxCountryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FaxCountryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FaxExtension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FaxKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FaxNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FaxType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Gender?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InternationalProvince?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsOnlineEmail?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IsPrimaryAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsPrimaryEmail?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IsSatelliteMember?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocalizedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MemberId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  MemberType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MiddleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OriginalAdmissionDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneCountryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneCountryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneExtension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Prefix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  State?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Suffix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TerminationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TerminationReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WebsiteAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WebsiteKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WebsiteType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubMembersResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubMembersResult'] = ResolversParentTypes['ClubMembersResult']> = {
  ClubMembers?: Resolver<Maybe<Array<Maybe<ResolversTypes['ClubMemberDetails']>>>, ParentType, ContextType>;
  ClubName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubMembershipResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubMembership'] = ResolversParentTypes['ClubMembership']> = {
  admissionDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clubId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clubName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clubType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  districtId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  riClubId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  riDistrictId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  terminationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubOperationsAccessResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubOperationsAccess'] = ResolversParentTypes['ClubOperationsAccess']> = {
  club?: Resolver<ResolversTypes['AccessLevel'], ParentType, ContextType>;
  clubId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  financesGoalsReports?: Resolver<ResolversTypes['AccessLevel'], ParentType, ContextType>;
  leaderships?: Resolver<ResolversTypes['AccessLevel'], ParentType, ContextType>;
  memberships?: Resolver<ResolversTypes['AccessLevel'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubRelationshipResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubRelationship'] = ResolversParentTypes['ClubRelationship']> = {
  childClubId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  childDisId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clubName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parentClubId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parentDisId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ClubType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubSearchConstantsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubSearchConstants'] = ResolversParentTypes['ClubSearchConstants']> = {
  filter?: Resolver<ResolversTypes['ClubSearchFilterConstants'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubSearchFilterConstantsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubSearchFilterConstants'] = ResolversParentTypes['ClubSearchFilterConstants']> = {
  meetingType?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubSearchResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubSearchResult'] = ResolversParentTypes['ClubSearchResult']> = {
  districtId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mailingAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  meetings?: Resolver<Array<ResolversTypes['ClubSearchResultMeeting']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  otherWebsites?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  physicalLocation?: Resolver<Maybe<ResolversTypes['ClubSearchResutLocation']>, ParentType, ContextType>;
  primaryWebsite?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  riClubId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sponsorClubs?: Resolver<Maybe<Array<ResolversTypes['ClubSearchResultClubRelationship']>>, ParentType, ContextType>;
  sponsoredClubs?: Resolver<Maybe<Array<ResolversTypes['ClubSearchResultClubRelationship']>>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ClubType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubSearchResultClubRelationshipResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubSearchResultClubRelationship'] = ResolversParentTypes['ClubSearchResultClubRelationship']> = {
  clubName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubSearchResultMeetingResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubSearchResultMeeting'] = ResolversParentTypes['ClubSearchResultMeeting']> = {
  categories?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  comment?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dtend?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dtstamp?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dtstart?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  geo?: Resolver<Maybe<ResolversTypes['GeoLocation']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recurrence?: Resolver<Maybe<ResolversTypes['Recurrence']>, ParentType, ContextType>;
  uid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  xRiAddress?: Resolver<Maybe<ResolversTypes['BaseAddress']>, ParentType, ContextType>;
  xRiLanguageId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  xRiLanguageName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  xRiLocationVaries?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  xRiTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  xRiWeekday?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubSearchResultsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubSearchResults'] = ResolversParentTypes['ClubSearchResults']> = {
  results?: Resolver<Array<ResolversTypes['ClubSearchResult']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubSearchResutLocationResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubSearchResutLocation'] = ResolversParentTypes['ClubSearchResutLocation']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  internationalProvince?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineOne?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineThree?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineTwo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stateId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubStatusResponseResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubStatusResponse'] = ResolversParentTypes['ClubStatusResponse']> = {
  status?: Resolver<Array<Maybe<ResolversTypes['ClubStatus']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubVendorResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ClubVendor'] = ResolversParentTypes['ClubVendor']> = {
  clubId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clubType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isPreferred?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requestors?: Resolver<Array<ResolversTypes['VendorRequestor']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vendorKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vendorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommitteeNominationsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['CommitteeNominations'] = ResolversParentTypes['CommitteeNominations']> = {
  cmt_asn_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cmt_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cpo_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nms_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nom_cst_key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nom_end_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nom_key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nom_nms_key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nom_start_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  org_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rc5_end_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rc5_location_city_state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rc5_start_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommitteeRolesResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['CommitteeRolesResult'] = ResolversParentTypes['CommitteeRolesResult']> = {
  endDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConferenceAddressResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ConferenceAddress'] = ResolversParentTypes['ConferenceAddress']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  internationalProvince?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineOne?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineThree?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineTwo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConferenceFeedbackResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ConferenceFeedbackResult'] = ResolversParentTypes['ConferenceFeedbackResult']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConstantsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Constants'] = ResolversParentTypes['Constants']> = {
  clubSearch?: Resolver<ResolversTypes['ClubSearchConstants'], ParentType, ContextType>;
  weekdayNames?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Content'] = ResolversParentTypes['Content']> = {
  component?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  config?: Resolver<Maybe<ResolversTypes['ContentConfig']>, ParentType, ContextType>;
  defaultFund?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentConfigResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ContentConfig'] = ResolversParentTypes['ContentConfig']> = {
  funds?: Resolver<Maybe<Array<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContributionResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Contribution'] = ResolversParentTypes['Contribution']> = {
  annualFund?: Resolver<Array<Maybe<ResolversTypes['ContributionItem']>>, ParentType, ContextType>;
  clubCredits?: Resolver<Array<Maybe<ResolversTypes['ClubCredits']>>, ParentType, ContextType>;
  polioPlus?: Resolver<Array<Maybe<ResolversTypes['ContributionItem']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContributionDeltasResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ContributionDeltas'] = ResolversParentTypes['ContributionDeltas']> = {
  prevYearAsOfDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priorMonthEnd?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalFor30June?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContributionItemResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ContributionItem'] = ResolversParentTypes['ContributionItem']> = {
  asOfDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prevYearDeltas?: Resolver<ResolversTypes['ContributionDeltas'], ParentType, ContextType>;
  priorMonthEnd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rotaryYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalFor30June?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Country'] = ResolversParentTypes['Country']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shortCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryCurrencyResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['CountryCurrency'] = ResolversParentTypes['CountryCurrency']> = {
  Country?: Resolver<Maybe<ResolversTypes['BACountry']>, ParentType, ContextType>;
  CountryCurrencyKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountryKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Currency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  CurrencyKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateCreated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IsPrimary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryCurrencyMappedResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['CountryCurrencyMapped'] = ResolversParentTypes['CountryCurrencyMapped']> = {
  default?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  list?: Resolver<Array<ResolversTypes['CurrencyId']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryDetailsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['CountryDetails'] = ResolversParentTypes['CountryDetails']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iso2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['CountryTNames']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryTNamesResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['CountryTNames'] = ResolversParentTypes['CountryTNames']> = {
  de?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  default?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  en?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  es?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  it?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ja?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ko?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sv?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryTranslationsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['CountryTranslations'] = ResolversParentTypes['CountryTranslations']> = {
  CountryKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountryTranslateKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateCreated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LanguageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TranslatedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateOrUpdateIndividualResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['CreateOrUpdateIndividualResult'] = ResolversParentTypes['CreateOrUpdateIndividualResult']> = {
  individualId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditOrgResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['CreditOrg'] = ResolversParentTypes['CreditOrg']> = {
  Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LegacyTypeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditOrgDetailsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['CreditOrgDetails'] = ResolversParentTypes['CreditOrgDetails']> = {
  District?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Currency'] = ResolversParentTypes['Currency']> = {
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyTranslations?: Resolver<Maybe<Array<Maybe<ResolversTypes['CurrencyTranslations']>>>, ParentType, ContextType>;
  DateCreated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyDetailsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['CurrencyDetails'] = ResolversParentTypes['CurrencyDetails']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['CurrencyTNames']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyIdResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['CurrencyId'] = ResolversParentTypes['CurrencyId']> = {
  currencyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyTNamesResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['CurrencyTNames'] = ResolversParentTypes['CurrencyTNames']> = {
  de?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  en?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  es?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  it?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ja?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ko?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sv?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  und?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyTranslationsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['CurrencyTranslations'] = ResolversParentTypes['CurrencyTranslations']> = {
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyTranslateKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateCreated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LanguageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TranslatedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DesDistrictInfoResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DESDistrictInfo'] = ResolversParentTypes['DESDistrictInfo']> = {
  districtId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  riDistrictId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  zone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DgResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DGResult'] = ResolversParentTypes['DGResult']> = {
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  riDistrictId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roleName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DaySpecifierResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DaySpecifier'] = ResolversParentTypes['DaySpecifier']> = {
  offset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  weekday?: Resolver<ResolversTypes['WeekDay'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DelegatesResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Delegates'] = ResolversParentTypes['Delegates']> = {
  DelegationEndDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DelegationStartDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DelegatorFullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DelegatorMemberId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MemberId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrganizationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrganizationName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrganizationType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RoleEndDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RoleStartDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DelegationRolesResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DelegationRoles'] = ResolversParentTypes['DelegationRoles']> = {
  Committee?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CommitteeType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EndDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsDelegationEligible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  Key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  LastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastUpdated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  MemberId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MiddleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Organization?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrganizationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrganizationType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  RoleKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  StartDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  TerminationReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DelegationsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Delegations'] = ResolversParentTypes['Delegations']> = {
  DelegateeFullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DelegateeMemberId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DelegationEndDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DelegationStartDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MemberId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrganizationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrganizationName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrganizationType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RoleEndDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RoleStartDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteClubMeetingResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DeleteClubMeetingResult'] = ResolversParentTypes['DeleteClubMeetingResult']> = {
  isMeetingDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemographicResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Demographic'] = ResolversParentTypes['Demographic']> = {
  otherGendersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  over40?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  period?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  under40?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unreported?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  womenCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DisplayNamesResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DisplayNames'] = ResolversParentTypes['DisplayNames']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shortCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistrictResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['District'] = ResolversParentTypes['District']> = {
  DistrictName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  DistrictNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistrictAddressResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DistrictAddress'] = ResolversParentTypes['DistrictAddress']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  internationalProvince?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineOne?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineThree?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineTwo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stateId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistrictClubResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DistrictClub'] = ResolversParentTypes['DistrictClub']> = {
  activeMembersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  district?: Resolver<Maybe<ResolversTypes['DistrictData']>, ParentType, ContextType>;
  governor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['LocationData']>, ParentType, ContextType>;
  meetings?: Resolver<Maybe<Array<Maybe<ResolversTypes['Meeting']>>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  riClubId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ClubType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistrictClubsResultsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DistrictClubsResults'] = ResolversParentTypes['DistrictClubsResults']> = {
  clubs?: Resolver<Array<ResolversTypes['DistrictClub']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistrictClubsStatisticsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DistrictClubsStatistics'] = ResolversParentTypes['DistrictClubsStatistics']> = {
  clubType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistrictDis3Resolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DistrictDIS3'] = ResolversParentTypes['DistrictDIS3']> = {
  businessAddress?: Resolver<Maybe<Array<Maybe<ResolversTypes['DistrictAddress']>>>, ParentType, ContextType>;
  districtLegalName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emails?: Resolver<Maybe<Array<Maybe<ResolversTypes['DistrictEmails']>>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  localizedAddress?: Resolver<Maybe<ResolversTypes['DistrictAddress']>, ParentType, ContextType>;
  mailingAddress?: Resolver<Maybe<ResolversTypes['DistrictAddress']>, ParentType, ContextType>;
  otherEmails?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  otherFaxes?: Resolver<Maybe<Array<Maybe<ResolversTypes['DistrictFax']>>>, ParentType, ContextType>;
  otherPhones?: Resolver<Maybe<Array<Maybe<ResolversTypes['DistrictPhone']>>>, ParentType, ContextType>;
  otherWebsites?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  primaryEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryFax?: Resolver<Maybe<ResolversTypes['DistrictFax']>, ParentType, ContextType>;
  primaryPhone?: Resolver<Maybe<ResolversTypes['DistrictPhone']>, ParentType, ContextType>;
  primaryWebsite?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  riDistrictId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  websites?: Resolver<Maybe<ResolversTypes['DistrictWebsites']>, ParentType, ContextType>;
  zone?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zoneSection?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistrictDashboardsResultsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DistrictDashboardsResults'] = ResolversParentTypes['DistrictDashboardsResults']> = {
  asOfDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clubDevelopment?: Resolver<Array<Maybe<ResolversTypes['ClubDevelopment']>>, ParentType, ContextType>;
  contribution?: Resolver<ResolversTypes['Contribution'], ParentType, ContextType>;
  demographics?: Resolver<Array<Maybe<ResolversTypes['Demographic']>>, ParentType, ContextType>;
  membersTrends?: Resolver<Array<Maybe<ResolversTypes['MembersTrends']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistrictDataResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DistrictData'] = ResolversParentTypes['DistrictData']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  riDistrictId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistrictEmailsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DistrictEmails'] = ResolversParentTypes['DistrictEmails']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isPrimary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistrictFaxResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DistrictFax'] = ResolversParentTypes['DistrictFax']> = {
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  extension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistrictLeadershipResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DistrictLeadership'] = ResolversParentTypes['DistrictLeadership']> = {
  districtId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  riDistrictId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  termYears?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistrictLeadershipResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DistrictLeadershipResult'] = ResolversParentTypes['DistrictLeadershipResult']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistrictMembersStatisticsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DistrictMembersStatistics'] = ResolversParentTypes['DistrictMembersStatistics']> = {
  memberType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistrictMembershipClubResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DistrictMembershipClub'] = ResolversParentTypes['DistrictMembershipClub']> = {
  clubId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clubName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clubType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['LocationData']>, ParentType, ContextType>;
  membershipType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  terminationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistrictOfficerResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DistrictOfficer'] = ResolversParentTypes['DistrictOfficer']> = {
  endDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  individual?: Resolver<ResolversTypes['OfficerIndividual'], ParentType, ContextType>;
  isDelegated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistrictOfficersResultsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DistrictOfficersResults'] = ResolversParentTypes['DistrictOfficersResults']> = {
  leadership?: Resolver<Array<Maybe<ResolversTypes['DistrictOfficer']>>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistrictPhoneResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DistrictPhone'] = ResolversParentTypes['DistrictPhone']> = {
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  countryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  extension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistrictTypeResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DistrictType'] = ResolversParentTypes['DistrictType']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isHomeDistrict?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  riDistrictId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  zone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistrictWebsitesResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DistrictWebsites'] = ResolversParentTypes['DistrictWebsites']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  otherWebsites?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryWebsite?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistrictsResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DistrictsResult'] = ResolversParentTypes['DistrictsResult']> = {
  districts?: Resolver<Array<ResolversTypes['DistrictType']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DominoEmailTypeResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['DominoEmailType'] = ResolversParentTypes['DominoEmailType']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isPrimary?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ECardDetailsResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ECardDetailsResult'] = ResolversParentTypes['ECardDetailsResult']> = {
  Created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DedicatedTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DedicationType?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  DonorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ImageFid?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  LookupKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NotificationType?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  PersonalMsg?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditMeetingResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['EditMeetingResult'] = ResolversParentTypes['EditMeetingResult']> = {
  meeting?: Resolver<Maybe<ResolversTypes['Meeting']>, ParentType, ContextType>;
  meetingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Email'] = ResolversParentTypes['Email']> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isSignin?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EmailType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailAddressResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['EmailAddress'] = ResolversParentTypes['EmailAddress']> = {
  EmailAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EmailType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsPrimary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastUpdated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrorAssignmentsResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ErrorAssignmentsResult'] = ResolversParentTypes['ErrorAssignmentsResult']> = {
  body?: Resolver<Maybe<ResolversTypes['ErrorBody']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  statusCode?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrorBatchResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ErrorBatch'] = ResolversParentTypes['ErrorBatch']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  source?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrorBodyResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ErrorBody'] = ResolversParentTypes['ErrorBody']> = {
  detail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<ResolversTypes['ErrorBatch']>>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExchangeRatesResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ExchangeRates'] = ResolversParentTypes['ExchangeRates']> = {
  FromCurrency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RateDivisor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RateMultiplier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ToCurrency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExpertiseResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Expertise'] = ResolversParentTypes['Expertise']> = {
  area?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  areaId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  level?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  levelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExpertiseAreaResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ExpertiseArea'] = ResolversParentTypes['ExpertiseArea']> = {
  areaId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  primary?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExpertiseAreaItemResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ExpertiseAreaItem'] = ResolversParentTypes['ExpertiseAreaItem']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExpertiseLevelResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ExpertiseLevel'] = ResolversParentTypes['ExpertiseLevel']> = {
  areas?: Resolver<Array<ResolversTypes['ExpertiseArea']>, ParentType, ContextType>;
  level?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  levelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExpertiseLevelItemResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ExpertiseLevelItem'] = ResolversParentTypes['ExpertiseLevelItem']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FaxResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Fax'] = ResolversParentTypes['Fax']> = {
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  extension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['FaxType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeedbackResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Feedback'] = ResolversParentTypes['Feedback']> = {
  feedbackType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDistrictPresRep?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  questions?: Resolver<Array<ResolversTypes['Question']>, ParentType, ContextType>;
  responses?: Resolver<Array<ResolversTypes['QuestionResponse']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeedbackQuestionsResponseResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['FeedbackQuestionsResponse'] = ResolversParentTypes['FeedbackQuestionsResponse']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  questions?: Resolver<Array<ResolversTypes['Question']>, ParentType, ContextType>;
  sectionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeedbackSectionResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['FeedbackSection'] = ResolversParentTypes['FeedbackSection']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sectionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldConstraintsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['FieldConstraints'] = ResolversParentTypes['FieldConstraints']> = {
  charSet?: Resolver<Maybe<ResolversTypes['FieldCharSet']>, ParentType, ContextType>;
  maxLength?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldOptionResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['FieldOption'] = ResolversParentTypes['FieldOption']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileContentResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['FileContentResult'] = ResolversParentTypes['FileContentResult']> = {
  FileContent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FinancialReprentativesResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['FinancialReprentatives'] = ResolversParentTypes['FinancialReprentatives']> = {
  Collector?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Department?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  District?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Fax?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Office?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormattedClubResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['FormattedClub'] = ResolversParentTypes['FormattedClub']> = {
  clubId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['FormattedLocation'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormattedLocationResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['FormattedLocation'] = ResolversParentTypes['FormattedLocation']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  internationalProvince?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormattedMembershipResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['FormattedMembership'] = ResolversParentTypes['FormattedMembership']> = {
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  individualId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isTerminated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundSubtitleResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['FundSubtitle'] = ResolversParentTypes['FundSubtitle']> = {
  de?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  en?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  es?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  it?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ja?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ko?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundTitleResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['FundTitle'] = ResolversParentTypes['FundTitle']> = {
  de?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  en?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  es?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  it?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ja?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ko?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundingResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Funding'] = ResolversParentTypes['Funding']> = {
  AmountExpected?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmountReceived?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmountRemaining?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Budget?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Paid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Funds'] = ResolversParentTypes['Funds']> = {
  alias?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fundId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fundSubId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fundSubtitle?: Resolver<Maybe<ResolversTypes['FundSubtitle']>, ParentType, ContextType>;
  fundTitle?: Resolver<Maybe<ResolversTypes['FundTitle']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isPaymentType?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  recurring?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  rnwPurposeText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  typeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['GeoLocation'] = ResolversParentTypes['GeoLocation']> = {
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAccessLevelsResultsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['GetAccessLevelsResults'] = ResolversParentTypes['GetAccessLevelsResults']> = {
  arguments?: Resolver<Maybe<ResolversTypes['AccessLevelsArguments']>, ParentType, ContextType>;
  level?: Resolver<ResolversTypes['AccessLevel'], ParentType, ContextType>;
  target?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAddDelegationResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['GetAddDelegationResult'] = ResolversParentTypes['GetAddDelegationResult']> = {
  DelegationKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetConferenceDetailsResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['GetConferenceDetailsResult'] = ResolversParentTypes['GetConferenceDetailsResult']> = {
  comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conferenceAddress?: Resolver<Maybe<ResolversTypes['ConferenceAddress']>, ParentType, ContextType>;
  createdByRiDistrictId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  detailForJoiningRemotely?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  districtId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isPresRepRequested?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locationNameOrDetail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  onlineLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  participatingDistricts?: Resolver<Maybe<Array<ResolversTypes['ParticipatingDistricts']>>, ParentType, ContextType>;
  prAccPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prAccommodation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prAddress?: Resolver<Maybe<ResolversTypes['ConferenceAddress']>, ParentType, ContextType>;
  presRepAccPhone?: Resolver<Maybe<ResolversTypes['AccPhone']>, ParentType, ContextType>;
  presidentialRepresentative?: Resolver<Maybe<ResolversTypes['PresidentialRepresentative']>, ParentType, ContextType>;
  relatedRotaryYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stateProvince?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeZone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  venueName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  venueType?: Resolver<ResolversTypes['MeetingType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetConferencesResultsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['GetConferencesResults'] = ResolversParentTypes['GetConferencesResults']> = {
  conferenceAddress?: Resolver<Maybe<ResolversTypes['ConferenceAddress']>, ParentType, ContextType>;
  createdByRiDistrictId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  districtId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  feedbacks?: Resolver<Array<ResolversTypes['ConferenceFeedbackResult']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDistrictPresRep?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  participatingDistricts?: Resolver<Maybe<Array<ResolversTypes['ParticipatingDistricts']>>, ParentType, ContextType>;
  presidentialRepresentative?: Resolver<Maybe<ResolversTypes['PresidentialRepresentative']>, ParentType, ContextType>;
  relatedRotaryYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  venueType?: Resolver<ResolversTypes['MeetingType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetDelegationProfileResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['GetDelegationProfileResult'] = ResolversParentTypes['GetDelegationProfileResult']> = {
  Delegates?: Resolver<Maybe<Array<Maybe<ResolversTypes['Delegates']>>>, ParentType, ContextType>;
  Delegations?: Resolver<Maybe<Array<Maybe<ResolversTypes['Delegations']>>>, ParentType, ContextType>;
  Roles?: Resolver<Maybe<Array<ResolversTypes['DelegationRoles']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetEditedDelegationResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['GetEditedDelegationResult'] = ResolversParentTypes['GetEditedDelegationResult']> = {
  DelegationKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetPresRepConferencesResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['GetPresRepConferencesResult'] = ResolversParentTypes['GetPresRepConferencesResult']> = {
  conferences?: Resolver<Array<ResolversTypes['GetConferencesResults']>, ParentType, ContextType>;
  districtId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  riDistrictId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  term?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GinResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['GinResult'] = ResolversParentTypes['GinResult']> = {
  FiscalCode_c?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PAN_c?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RRN_c?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GrantDetailsResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['GrantDetailsResult'] = ResolversParentTypes['GrantDetailsResult']> = {
  EndDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Funding?: Resolver<Maybe<ResolversTypes['Funding']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Location?: Resolver<Maybe<ResolversTypes['GrantLocation']>, ParentType, ContextType>;
  Message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProgramYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GrantLocationResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['GrantLocation'] = ResolversParentTypes['GrantLocation']> = {
  GrantCountry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupApplicationRolesRelationshipTypeResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['GroupApplicationRolesRelationshipType'] = ResolversParentTypes['GroupApplicationRolesRelationshipType']> = {
  accessFlag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  applications?: Resolver<Maybe<Array<Maybe<ResolversTypes['GroupApplicationsType']>>>, ParentType, ContextType>;
  containErrors?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  errorCode?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  errorId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<Maybe<ResolversTypes['GroupRolesType']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupApplicationsTypeResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['GroupApplicationsType'] = ResolversParentTypes['GroupApplicationsType']> = {
  appInstanceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  linkUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupRolesTypeResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['GroupRolesType'] = ResolversParentTypes['GroupRolesType']> = {
  roleKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HateoasLinkResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['HATEOASLink'] = ResolversParentTypes['HATEOASLink']> = {
  href?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  method?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IndividualResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Individual'] = ResolversParentTypes['Individual']> = {
  aboutMe?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clubAffiliations?: Resolver<Array<ResolversTypes['ClubAffiliation']>, ParentType, ContextType, RequireFields<IndividualClubAffiliationsArgs, never>>;
  dateOfBirth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  editPermissions?: Resolver<Maybe<ResolversTypes['IndividualEditPermissions']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['Gender']>, ParentType, ContextType>;
  genderDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupedExpertise?: Resolver<Array<ResolversTypes['ExpertiseLevel']>, ParentType, ContextType>;
  hateoasLinks?: Resolver<Maybe<Array<ResolversTypes['HATEOASLink']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isClubOfficer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isClubOrDistrictOfficer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  localizedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  middleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nfKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  onlineId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  otherAddresses?: Resolver<Maybe<Array<ResolversTypes['Address']>>, ParentType, ContextType>;
  otherEmails?: Resolver<Maybe<Array<ResolversTypes['Email']>>, ParentType, ContextType>;
  otherExpertise?: Resolver<Maybe<Array<ResolversTypes['Expertise']>>, ParentType, ContextType>;
  otherLanguages?: Resolver<Maybe<Array<ResolversTypes['IndividualLanguage']>>, ParentType, ContextType>;
  otherPhones?: Resolver<Maybe<Array<ResolversTypes['PhoneNumber']>>, ParentType, ContextType>;
  otherProfessions?: Resolver<Maybe<Array<ResolversTypes['Profession']>>, ParentType, ContextType>;
  phoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  photoUri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferredLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prefix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  primaryEmail?: Resolver<Maybe<ResolversTypes['Email']>, ParentType, ContextType>;
  primaryExpertise?: Resolver<Maybe<ResolversTypes['Expertise']>, ParentType, ContextType>;
  primaryLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  primaryPhone?: Resolver<Maybe<ResolversTypes['PhoneNumber']>, ParentType, ContextType>;
  primaryProfession?: Resolver<Maybe<ResolversTypes['Profession']>, ParentType, ContextType>;
  programs?: Resolver<Array<ResolversTypes['Program']>, ParentType, ContextType>;
  recognitionOptOutFlag?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  restrictInfo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  restrictPhoto?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  resumeRecognitions?: Resolver<Array<ResolversTypes['RecognitionItem']>, ParentType, ContextType>;
  resumeRoles?: Resolver<ResolversTypes['ResumeRoles'], ParentType, ContextType>;
  resumeSponsorships?: Resolver<ResolversTypes['SponsorshipResult'], ParentType, ContextType, RequireFields<IndividualResumeSponsorshipsArgs, 'profileIndividualId'>>;
  riIndividualId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sharingPermissionsExtended?: Resolver<Maybe<ResolversTypes['SharingPermissionsExtended']>, ParentType, ContextType>;
  sharingPermissionsRegular?: Resolver<Maybe<ResolversTypes['SharingPermissionsRegular']>, ParentType, ContextType>;
  suffix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  yearOfBirth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  youthFlag?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IndividualAddressResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['IndividualAddress'] = ResolversParentTypes['IndividualAddress']> = {
  AddressLine1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AddressLine2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AddressLine3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AddressType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InternationalProvince?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsPrimary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastUpdated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MemberId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  PostalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  State?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IndividualAffiliationInfoResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['IndividualAffiliationInfo'] = ResolversParentTypes['IndividualAffiliationInfo']> = {
  clubs?: Resolver<Array<ResolversTypes['ShortClubInfo']>, ParentType, ContextType>;
  districts?: Resolver<Array<ResolversTypes['DESDistrictInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IndividualDesResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['IndividualDESResult'] = ResolversParentTypes['IndividualDESResult']> = {
  affiliationInfo?: Resolver<Maybe<ResolversTypes['IndividualAffiliationInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IndividualDetailsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['IndividualDetails'] = ResolversParentTypes['IndividualDetails']> = {
  FirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocalizedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MemberToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IndividualEditPermissionsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['IndividualEditPermissions'] = ResolversParentTypes['IndividualEditPermissions']> = {
  areasOfExpertise?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  background?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  contactInformation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  photo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  professionalExperience?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  recognition?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  rotaryPrograms?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IndividualEmailResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['IndividualEmail'] = ResolversParentTypes['IndividualEmail']> = {
  EmailAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EmailType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsOnlineId?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IsPrimary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastUpdated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MemberId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IndividualInfoResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['IndividualInfo'] = ResolversParentTypes['IndividualInfo']> = {
  BadgeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Classification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateOfBirth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Gender?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenderDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastUpdated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocalizedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MemberId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  MiddleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Prefix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrimaryLanguage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Suffix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  YouthFlag?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IndividualLanguageResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['IndividualLanguage'] = ResolversParentTypes['IndividualLanguage']> = {
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  languageId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IndividualMemberDetailsResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['IndividualMemberDetailsResult'] = ResolversParentTypes['IndividualMemberDetailsResult']> = {
  BadgeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Classification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateOfBirth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Gender?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenderDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastUpdated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocalizedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MemberId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  MiddleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Prefix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrimaryLanguage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Suffix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  YouthFlag?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IndividualOperationsAccessGqlResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['IndividualOperationsAccessGql'] = ResolversParentTypes['IndividualOperationsAccessGql']> = {
  assignRole?: Resolver<Maybe<ResolversTypes['AccessLevel']>, ParentType, ContextType>;
  editMember?: Resolver<Maybe<ResolversTypes['AccessLevel']>, ParentType, ContextType>;
  editMembershipType?: Resolver<Maybe<ResolversTypes['AccessLevel']>, ParentType, ContextType>;
  terminateMembership?: Resolver<Maybe<ResolversTypes['AccessLevel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IndividualOrganizationResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['IndividualOrganization'] = ResolversParentTypes['IndividualOrganization']> = {
  Id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IndividualPhoneResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['IndividualPhone'] = ResolversParentTypes['IndividualPhone']> = {
  CountryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsPrimary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastUpdated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MemberId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  PhoneCountryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneExtension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IndividualProfileDetailsResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['IndividualProfileDetailsResult'] = ResolversParentTypes['IndividualProfileDetailsResult']> = {
  Address?: Resolver<Maybe<Array<Maybe<ResolversTypes['IndividualAddress']>>>, ParentType, ContextType>;
  Email?: Resolver<Maybe<Array<Maybe<ResolversTypes['IndividualEmail']>>>, ParentType, ContextType>;
  Individual?: Resolver<Maybe<ResolversTypes['IndividualInfo']>, ParentType, ContextType>;
  Language?: Resolver<Maybe<Array<Maybe<ResolversTypes['LanguageDetails']>>>, ParentType, ContextType>;
  Phone?: Resolver<Maybe<Array<Maybe<ResolversTypes['IndividualPhone']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IndividualRoleResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['IndividualRole'] = ResolversParentTypes['IndividualRole']> = {
  IsDelegated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IsUnverified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RoleEventKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IndividualSearchResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['IndividualSearchResult'] = ResolversParentTypes['IndividualSearchResult']> = {
  activeLeaderships?: Resolver<Array<ResolversTypes['Leadership']>, ParentType, ContextType, RequireFields<IndividualSearchResultActiveLeadershipsArgs, never>>;
  activeMemberships?: Resolver<Array<ResolversTypes['ActiveMembership']>, ParentType, ContextType, RequireFields<IndividualSearchResultActiveMembershipsArgs, never>>;
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photoUri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  riIndividualId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sharingPermission?: Resolver<Maybe<ResolversTypes['SharingPermissionResult']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IndividualSearchResultsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['IndividualSearchResults'] = ResolversParentTypes['IndividualSearchResults']> = {
  results?: Resolver<Array<ResolversTypes['IndividualSearchResult']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IndividualSecurityGroupsResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['IndividualSecurityGroupsResult'] = ResolversParentTypes['IndividualSecurityGroupsResult']> = {
  Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Organizations?: Resolver<Maybe<Array<Maybe<ResolversTypes['IndividualOrganization']>>>, ParentType, ContextType>;
  Roles?: Resolver<Maybe<Array<Maybe<ResolversTypes['IndividualRole']>>>, ParentType, ContextType>;
  TermAccessStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceItemResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['InvoiceItem'] = ResolversParentTypes['InvoiceItem']> = {
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  checked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exchangeRateBillingtoUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exchangeRateLocaltoUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exchangeRateUSDtoBilling?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exchangeRateUSDtoLocal?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  itemNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeLegalEntityTranslationsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['LELegalEntityTranslations'] = ResolversParentTypes['LELegalEntityTranslations']> = {
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateCreated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LanguageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LegalEntityKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LegalEntityTranslationsKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TranslatedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LanguageResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Language'] = ResolversParentTypes['Language']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LanguageDetailsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['LanguageDetails'] = ResolversParentTypes['LanguageDetails']> = {
  IsPrimary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LanguageName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastUpdated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MemberId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Proficiency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Read?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Speak?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LanguageExtendedResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['LanguageExtended'] = ResolversParentTypes['LanguageExtended']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shortCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeadershipResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Leadership'] = ResolversParentTypes['Leadership']> = {
  clubId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clubName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clubType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  districtId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeadershipAffiliationResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['LeadershipAffiliation'] = ResolversParentTypes['LeadershipAffiliation']> = {
  isDelegated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  leadershipRole?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeadershipEmbeddedDataBaseResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['LeadershipEmbeddedDataBase'] = ResolversParentTypes['LeadershipEmbeddedDataBase']> = {
  organization?: Resolver<Maybe<ResolversTypes['OrganisationDataBase']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeadershipResponseResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['LeadershipResponseResult'] = ResolversParentTypes['LeadershipResponseResult']> = {
  _embedded?: Resolver<Maybe<ResolversTypes['LeadershipEmbeddedDataBase']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDelegated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegalEntityResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['LegalEntity'] = ResolversParentTypes['LegalEntity']> = {
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateCreated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LegalEntityAbbr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LegalEntityKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LegalEntityName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LegalEntityTranslations?: Resolver<Maybe<Array<Maybe<ResolversTypes['LegalEntityTranslations']>>>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegalEntityResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['LegalEntityResult'] = ResolversParentTypes['LegalEntityResult']> = {
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateCreated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LegalEntityAbbr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LegalEntityKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LegalEntityName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LegalEntityTranslations?: Resolver<Maybe<Array<Maybe<ResolversTypes['LELegalEntityTranslations']>>>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegalEntityTranslationsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['LegalEntityTranslations'] = ResolversParentTypes['LegalEntityTranslations']> = {
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateCreated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LanguageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LegalEntityKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LegalEntityTranslationsKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TranslatedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Link'] = ResolversParentTypes['Link']> = {
  href?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  method?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinksResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Links'] = ResolversParentTypes['Links']> = {
  individual?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = {
  Country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InternationalProvince?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  State?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationDataResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['LocationData'] = ResolversParentTypes['LocationData']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  internationalProvince?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineOne?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineThree?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineTwo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stateId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationDetailsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['LocationDetails'] = ResolversParentTypes['LocationDetails']> = {
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InternationalProvince?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  State?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationInfoResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['LocationInfo'] = ResolversParentTypes['LocationInfo']> = {
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InternationalProvince?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MeetingResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Meeting'] = ResolversParentTypes['Meeting']> = {
  address?: Resolver<Maybe<ResolversTypes['BaseAddress']>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  detailForJoiningRemotely?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  frequency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  languageId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  locationNameOrDetail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locationVaries?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  meetingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  onlineLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  time?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['MeetingType'], ParentType, ContextType>;
  weekday?: Resolver<ResolversTypes['WeekdayFull'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MemberOrganizationDetailsResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['MemberOrganizationDetailsResult'] = ResolversParentTypes['MemberOrganizationDetailsResult']> = {
  BadgeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Clubs?: Resolver<Maybe<Array<Maybe<ResolversTypes['ClubDetails']>>>, ParentType, ContextType>;
  CreditOrg?: Resolver<Maybe<ResolversTypes['CreditOrgDetails']>, ParentType, ContextType>;
  DigitalLanguage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsDeceased?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IsOnline?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IsYouth?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Location?: Resolver<Maybe<ResolversTypes['LocationDetails']>, ParentType, ContextType>;
  MemberId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  MiddleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Prefix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Suffix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MemberSearchResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['MemberSearchResult'] = ResolversParentTypes['MemberSearchResult']> = {
  activeLeaderships?: Resolver<Array<ResolversTypes['Leadership']>, ParentType, ContextType, RequireFields<MemberSearchResultActiveLeadershipsArgs, never>>;
  activeMembershipInAllDistricts?: Resolver<Maybe<ResolversTypes['ActiveMembership']>, ParentType, ContextType, RequireFields<MemberSearchResultActiveMembershipInAllDistrictsArgs, never>>;
  activeMemberships?: Resolver<Array<ResolversTypes['ActiveMembership']>, ParentType, ContextType, RequireFields<MemberSearchResultActiveMembershipsArgs, never>>;
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  associatedClubsInfo?: Resolver<Array<ResolversTypes['Club']>, ParentType, ContextType, RequireFields<MemberSearchResultAssociatedClubsInfoArgs, never>>;
  clubMemberships?: Resolver<Array<ResolversTypes['ActiveMembership']>, ParentType, ContextType, RequireFields<MemberSearchResultClubMembershipsArgs, never>>;
  deceasedDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDeceased?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  localizedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  membershipId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameWithPrefixSuffix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  onlineId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  operationsAccess?: Resolver<ResolversTypes['IndividualOperationsAccessGql'], ParentType, ContextType>;
  otherEmails?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photoUri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferredLanguage?: Resolver<Maybe<ResolversTypes['Language']>, ParentType, ContextType>;
  riIndividualId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sharingPermissionsResult?: Resolver<ResolversTypes['SharingPermissionsResult'], ParentType, ContextType>;
  thisClubLeadership?: Resolver<Array<ResolversTypes['Leadership']>, ParentType, ContextType, RequireFields<MemberSearchResultThisClubLeadershipArgs, never>>;
  thisDistrictLeadership?: Resolver<Array<ResolversTypes['DistrictLeadership']>, ParentType, ContextType, RequireFields<MemberSearchResultThisDistrictLeadershipArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MemberSearchResultsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['MemberSearchResults'] = ResolversParentTypes['MemberSearchResults']> = {
  results?: Resolver<Array<ResolversTypes['MemberSearchResult']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembersTrendsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['MembersTrends'] = ResolversParentTypes['MembersTrends']> = {
  july1st?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  monthlyData?: Resolver<Array<ResolversTypes['MonthlyData']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  year?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Membership'] = ResolversParentTypes['Membership']> = {
  admissionDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  club?: Resolver<ResolversTypes['Club'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  individual?: Resolver<ResolversTypes['Individual'], ParentType, ContextType>;
  terminationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['MembershipType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipAffiliationResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['MembershipAffiliation'] = ResolversParentTypes['MembershipAffiliation']> = {
  admissionDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  membershipType?: Resolver<ResolversTypes['MembershipType'], ParentType, ContextType>;
  terminationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipEmbeddedDataBaseResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['MembershipEmbeddedDataBase'] = ResolversParentTypes['MembershipEmbeddedDataBase']> = {
  sponsors?: Resolver<Maybe<Array<Maybe<ResolversTypes['SubSponser']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipForAddMemberResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['MembershipForAddMember'] = ResolversParentTypes['MembershipForAddMember']> = {
  clubId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clubType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  terminationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['MembershipType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipHistoryBaseResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['MembershipHistoryBase'] = ResolversParentTypes['MembershipHistoryBase']> = {
  _embedded?: Resolver<Maybe<ResolversTypes['MembershipEmbeddedDataBase']>, ParentType, ContextType>;
  _links?: Resolver<Maybe<ResolversTypes['Links']>, ParentType, ContextType>;
  admissionDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  terminationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  terminationReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  terminationReasonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipsListResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['MembershipsList'] = ResolversParentTypes['MembershipsList']> = {
  admissionDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  terminationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  terminationReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  terminationReasonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MonthlyDataResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['MonthlyData'] = ResolversParentTypes['MonthlyData']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fiscalMonth?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  month?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  accountInfoByToken?: Resolver<ResolversTypes['AccountInfoByToken'], ParentType, ContextType, RequireFields<MutationAccountInfoByTokenArgs, 'token'>>;
  addClubMeeting?: Resolver<ResolversTypes['AddClubMeetingResult'], ParentType, ContextType, RequireFields<MutationAddClubMeetingArgs, 'input'>>;
  addClubVendor?: Resolver<ResolversTypes['AddClubVendorResult'], ParentType, ContextType, RequireFields<MutationAddClubVendorArgs, 'input'>>;
  addDelegation?: Resolver<ResolversTypes['GetAddDelegationResult'], ParentType, ContextType, RequireFields<MutationAddDelegationArgs, 'input'>>;
  addEcardDetails?: Resolver<Maybe<ResolversTypes['ecardAddResult']>, ParentType, ContextType, RequireFields<MutationAddEcardDetailsArgs, 'DedicatedTo' | 'DedicationType' | 'DonorName' | 'ImageFid' | 'NotificationType' | 'PersonalMsg'>>;
  addFiscalCode?: Resolver<Maybe<ResolversTypes['fiscalCodeInfoResult']>, ParentType, ContextType, RequireFields<MutationAddFiscalCodeArgs, 'collectionDate' | 'fiscalCode' | 'netForumMemberId'>>;
  addPan?: Resolver<Maybe<ResolversTypes['panInfoResult']>, ParentType, ContextType, RequireFields<MutationAddPanArgs, 'netForumMemberId' | 'panId'>>;
  addRRN?: Resolver<Maybe<ResolversTypes['rrnInfoResult']>, ParentType, ContextType, RequireFields<MutationAddRrnArgs, 'collectionDate' | 'netForumMemberId' | 'rrnId'>>;
  addSponsor?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationAddSponsorArgs, 'clubId' | 'membershipId'>>;
  assignDistrictLeadership?: Resolver<ResolversTypes['DistrictLeadershipResult'], ParentType, ContextType, RequireFields<MutationAssignDistrictLeadershipArgs, 'districtId' | 'individualId' | 'riDistrictId' | 'roleId' | 'startDate'>>;
  cancelSubscriptionForMember?: Resolver<Maybe<ResolversTypes['CancelSubscriptionResponse']>, ParentType, ContextType, RequireFields<MutationCancelSubscriptionForMemberArgs, 'MerchantConfig' | 'SubscriptionId'>>;
  changePassword?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationChangePasswordArgs, 'currentPassword' | 'newPassword'>>;
  changeSecurityQuestion?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationChangeSecurityQuestionArgs, 'answer' | 'currentPassword' | 'question'>>;
  changeSigninEmail?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationChangeSigninEmailArgs, 'currentPassword' | 'langcode' | 'newEmail'>>;
  contactUs?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationContactUsArgs, 'contactForm' | 'email' | 'langcode' | 'supportCenterEmail'>>;
  createAccount?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationCreateAccountArgs, 'email' | 'firstName' | 'langcode' | 'lastName' | 'youthFlag'>>;
  createConference?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationCreateConferenceArgs, 'input'>>;
  createFeedback?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationCreateFeedbackArgs, 'input'>>;
  createIndividual?: Resolver<ResolversTypes['CreateOrUpdateIndividualResult'], ParentType, ContextType, RequireFields<MutationCreateIndividualArgs, 'input'>>;
  createIndividualFromSession?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationCreateIndividualFromSessionArgs, 'langcode'>>;
  createIndividualProgram?: Resolver<ResolversTypes['Program'], ParentType, ContextType, RequireFields<MutationCreateIndividualProgramArgs, 'input'>>;
  createLeadership?: Resolver<ResolversTypes['createLeadershipResult'], ParentType, ContextType, RequireFields<MutationCreateLeadershipArgs, 'clubId' | 'endDate' | 'individualId' | 'roleId' | 'startDate'>>;
  createMembership?: Resolver<ResolversTypes['createMembershipResult'], ParentType, ContextType, RequireFields<MutationCreateMembershipArgs, 'admissionDate' | 'clubId' | 'individualId' | 'type'>>;
  deleteClubMeeting?: Resolver<ResolversTypes['DeleteClubMeetingResult'], ParentType, ContextType, RequireFields<MutationDeleteClubMeetingArgs, 'input'>>;
  deleteConference?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationDeleteConferenceArgs, 'conferenceId'>>;
  deleteDelegation?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationDeleteDelegationArgs, 'input'>>;
  deleteDistrictLeadership?: Resolver<ResolversTypes['DistrictLeadershipResult'], ParentType, ContextType, RequireFields<MutationDeleteDistrictLeadershipArgs, 'districtId' | 'leadershipId'>>;
  deleteIndividualProgram?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationDeleteIndividualProgramArgs, 'input'>>;
  deletePhoto?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationDeletePhotoArgs, 'individualId'>>;
  editConference?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationEditConferenceArgs, 'conferenceId' | 'input'>>;
  editDelegation?: Resolver<ResolversTypes['GetEditedDelegationResult'], ParentType, ContextType, RequireFields<MutationEditDelegationArgs, 'input'>>;
  editFeedback?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationEditFeedbackArgs, 'feedbackId' | 'input'>>;
  editMeeting?: Resolver<ResolversTypes['EditMeetingResult'], ParentType, ContextType, RequireFields<MutationEditMeetingArgs, 'input'>>;
  finalizeAccountCreation?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationFinalizeAccountCreationArgs, 'accountId' | 'answer' | 'password' | 'question' | 'token'>>;
  finalizeChangeOfSigninEmail?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationFinalizeChangeOfSigninEmailArgs, 'token'>>;
  mapByDisEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationMapByDisEmailArgs, 'disEmail'>>;
  moveAgClubAffiliations?: Resolver<ResolversTypes['ClubAssignmentsResponse'], ParentType, ContextType, RequireFields<MutationMoveAgClubAffiliationsArgs, 'districtId' | 'movedItems' | 'startDate'>>;
  replaceAgClubAffiliations?: Resolver<ResolversTypes['AGResult'], ParentType, ContextType, RequireFields<MutationReplaceAgClubAffiliationsArgs, 'districtId' | 'individualId' | 'newIndividualId' | 'startDate'>>;
  resendActivationEmail?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationResendActivationEmailArgs, 'email' | 'langcode'>>;
  reset?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType>;
  sendLog?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationSendLogArgs, 'level' | 'message'>>;
  sendPasswordResetMessage?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationSendPasswordResetMessageArgs, 'email' | 'langcode'>>;
  terminateClubVendor?: Resolver<ResolversTypes['TerminateClubVendorResult'], ParentType, ContextType, RequireFields<MutationTerminateClubVendorArgs, 'input'>>;
  terminateLeadership?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationTerminateLeadershipArgs, 'clubId' | 'leadershipId' | 'terminationDate'>>;
  terminateMembership?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationTerminateMembershipArgs, 'clubId' | 'membershipId' | 'terminationDate'>>;
  updateAgClubAffiliations?: Resolver<ResolversTypes['AGResult'], ParentType, ContextType, RequireFields<MutationUpdateAgClubAffiliationsArgs, 'districtId' | 'individualId' | 'newIndividualId'>>;
  updateClub?: Resolver<ResolversTypes['UpdateClubResult'], ParentType, ContextType, RequireFields<MutationUpdateClubArgs, 'input'>>;
  updateContactInfo?: Resolver<ResolversTypes['CreateOrUpdateIndividualResult'], ParentType, ContextType, RequireFields<MutationUpdateContactInfoArgs, 'input'>>;
  updateDistrictDetails?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationUpdateDistrictDetailsArgs, 'district'>>;
  updateDistrictLeadership?: Resolver<ResolversTypes['DistrictLeadershipResult'], ParentType, ContextType, RequireFields<MutationUpdateDistrictLeadershipArgs, 'districtId' | 'leadershipId' | 'terminationDate'>>;
  updateIndividual?: Resolver<ResolversTypes['CreateOrUpdateIndividualResult'], ParentType, ContextType, RequireFields<MutationUpdateIndividualArgs, 'input'>>;
  updateIndividualProgram?: Resolver<ResolversTypes['Program'], ParentType, ContextType, RequireFields<MutationUpdateIndividualProgramArgs, 'input'>>;
  updateMembershipType?: Resolver<ResolversTypes['updateMembershipTypeResult'], ParentType, ContextType, RequireFields<MutationUpdateMembershipTypeArgs, 'admissionDate' | 'clubId' | 'membershipId' | 'type'>>;
  updatePassword?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationUpdatePasswordArgs, 'answer' | 'password' | 'token'>>;
  uploadPhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationUploadPhotoArgs, 'fileContentsBase64Encoded' | 'fileMimeType' | 'fileName' | 'fileSize' | 'individualId'>>;
};

export type OfficerIndividualResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['OfficerIndividual'] = ResolversParentTypes['OfficerIndividual']> = {
  clubId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clubLocation?: Resolver<Maybe<ResolversTypes['BaseAddress']>, ParentType, ContextType>;
  clubName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clubType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  localizedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  membership?: Resolver<ResolversTypes['OfficerMembership'], ParentType, ContextType>;
  middleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photoUri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prefix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  suffix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OfficerMembershipResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['OfficerMembership'] = ResolversParentTypes['OfficerMembership']> = {
  admissionDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  districtId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  terminationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OktaProfileDataTypeResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['OktaProfileDataType'] = ResolversParentTypes['OktaProfileDataType']> = {
  emailAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobileNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  registrationForm?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  registrationType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OpenInvoiceListResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['OpenInvoiceListResult'] = ResolversParentTypes['OpenInvoiceListResult']> = {
  billingToUsd?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  list?: Resolver<Array<ResolversTypes['InvoiceItem']>, ParentType, ContextType>;
  usdToBilling?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionDetailResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['OptionDetail'] = ResolversParentTypes['OptionDetail']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  optionValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionTypeResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['OptionType'] = ResolversParentTypes['OptionType']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  optionType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganisationDataBaseResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['OrganisationDataBase'] = ResolversParentTypes['OrganisationDataBase']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Organization'] = ResolversParentTypes['Organization']> = {
  Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrganizationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrganizationName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrganizationType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationAddressResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['OrganizationAddress'] = ResolversParentTypes['OrganizationAddress']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  province?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stateCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationDataResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['OrganizationData'] = ResolversParentTypes['OrganizationData']> = {
  dominoId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationDetailsResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['OrganizationDetailsResult'] = ResolversParentTypes['OrganizationDetailsResult']> = {
  Addresses?: Resolver<Maybe<Array<Maybe<ResolversTypes['AddressInfo']>>>, ParentType, ContextType>;
  CreditOrg?: Resolver<Maybe<ResolversTypes['CreditOrg']>, ParentType, ContextType>;
  EmailAddresses?: Resolver<Maybe<Array<Maybe<ResolversTypes['EmailAddress']>>>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LegacyTypeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocalizedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Location?: Resolver<Maybe<ResolversTypes['LocationInfo']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneNumbers?: Resolver<Maybe<Array<Maybe<ResolversTypes['PhoneNumbers']>>>, ParentType, ContextType>;
  SponsoredByOrganizations?: Resolver<Maybe<Array<Maybe<ResolversTypes['Organization']>>>, ParentType, ContextType>;
  SponsoringOrganizations?: Resolver<Maybe<Array<Maybe<ResolversTypes['Organization']>>>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Websites?: Resolver<Maybe<Array<Maybe<ResolversTypes['Website']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParticipatingDistrictsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ParticipatingDistricts'] = ResolversParentTypes['ParticipatingDistricts']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  riDistrictId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Payment'] = ResolversParentTypes['Payment']> = {
  ExpirationMonth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExpirationYear?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MaskedCC?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentProfileNameResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['PaymentProfileNameResult'] = ResolversParentTypes['PaymentProfileNameResult']> = {
  PaymentProfileName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentProfileResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['PaymentProfileResult'] = ResolversParentTypes['PaymentProfileResult']> = {
  FoundationID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  FoundationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  MerchantApiKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  MerchantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  WidgetId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentProfileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhoneNumberResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['PhoneNumber'] = ResolversParentTypes['PhoneNumber']> = {
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  countryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  extension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['PhoneType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhoneNumbersResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['PhoneNumbers'] = ResolversParentTypes['PhoneNumbers']> = {
  CountryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsPrimary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastUpdated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneCountryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneExtension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhysicalLocationResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['PhysicalLocation'] = ResolversParentTypes['PhysicalLocation']> = {
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PresidentialRepresentativeResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['PresidentialRepresentative'] = ResolversParentTypes['PresidentialRepresentative']> = {
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  middleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrivacyPolicyTMessagesResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['PrivacyPolicyTMessages'] = ResolversParentTypes['PrivacyPolicyTMessages']> = {
  DE?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EN?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ES?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FR?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IT?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JA?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  KO?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PT?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfessionResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Profession'] = ResolversParentTypes['Profession']> = {
  employer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  occupation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Program'] = ResolversParentTypes['Program']> = {
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fields?: Resolver<Array<ResolversTypes['ProgramField']>, ParentType, ContextType>;
  individualProgramId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  programId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramDefinitionResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ProgramDefinition'] = ResolversParentTypes['ProgramDefinition']> = {
  fieldGroups?: Resolver<Array<ResolversTypes['ProgramFieldGroup']>, ParentType, ContextType>;
  programId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramFieldResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ProgramField'] = ResolversParentTypes['ProgramField']> = {
  fieldId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramFieldDefinitionResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ProgramFieldDefinition'] = ResolversParentTypes['ProgramFieldDefinition']> = {
  constraints?: Resolver<Maybe<ResolversTypes['FieldConstraints']>, ParentType, ContextType>;
  fieldId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isRequired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<Maybe<Array<ResolversTypes['FieldOption']>>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['FieldType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramFieldGroupResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ProgramFieldGroup'] = ResolversParentTypes['ProgramFieldGroup']> = {
  defaultTranslation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fields?: Resolver<Array<ResolversTypes['ProgramFieldDefinition']>, ParentType, ContextType>;
  translationKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  addMemberGetIndividual?: Resolver<ResolversTypes['AddMemberIndividualResult'], ParentType, ContextType, RequireFields<QueryAddMemberGetIndividualArgs, never>>;
  availableSponsors?: Resolver<Maybe<ResolversTypes['MemberSearchResults']>, ParentType, ContextType, RequireFields<QueryAvailableSponsorsArgs, 'clubId' | 'name' | 'page' | 'pageSize'>>;
  basicInfo?: Resolver<Maybe<ResolversTypes['BasicInfo']>, ParentType, ContextType, RequireFields<QueryBasicInfoArgs, 'emailId'>>;
  cdsRepresentatives?: Resolver<Maybe<Array<Maybe<ResolversTypes['CDSRepresentatives']>>>, ParentType, ContextType>;
  clubById?: Resolver<ResolversTypes['Club'], ParentType, ContextType, RequireFields<QueryClubByIdArgs, 'clubId'>>;
  clubStatusByIds?: Resolver<Array<ResolversTypes['ClubStatus']>, ParentType, ContextType, RequireFields<QueryClubStatusByIdsArgs, 'clubIds'>>;
  constants?: Resolver<ResolversTypes['Constants'], ParentType, ContextType>;
  countries?: Resolver<Array<ResolversTypes['Country']>, ParentType, ContextType>;
  currentUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  displayNames?: Resolver<Array<ResolversTypes['DisplayNames']>, ParentType, ContextType, RequireFields<QueryDisplayNamesArgs, 'displayLanguage' | 'displayType'>>;
  districts?: Resolver<Maybe<Array<ResolversTypes['District']>>, ParentType, ContextType>;
  expertiseAreas?: Resolver<Array<ResolversTypes['ExpertiseAreaItem']>, ParentType, ContextType>;
  expertiseLevels?: Resolver<Array<ResolversTypes['ExpertiseLevelItem']>, ParentType, ContextType>;
  financialReprentatives?: Resolver<Maybe<Array<Maybe<ResolversTypes['FinancialReprentatives']>>>, ParentType, ContextType>;
  getAccessLevels?: Resolver<Array<ResolversTypes['GetAccessLevelsResults']>, ParentType, ContextType, RequireFields<QueryGetAccessLevelsArgs, 'options'>>;
  getAllAssistantGovernors?: Resolver<Array<ResolversTypes['AssistantGovernor']>, ParentType, ContextType, RequireFields<QueryGetAllAssistantGovernorsArgs, 'districtId'>>;
  getAllDistrictAGsByDate?: Resolver<ResolversTypes['AssistantGovernorsByDateResults'], ParentType, ContextType, RequireFields<QueryGetAllDistrictAGsByDateArgs, 'districtId'>>;
  getAllDistrictClubs?: Resolver<ResolversTypes['DistrictClubsResults'], ParentType, ContextType, RequireFields<QueryGetAllDistrictClubsArgs, 'districtId'>>;
  getAllDistrictOfficers?: Resolver<ResolversTypes['DistrictOfficersResults'], ParentType, ContextType, RequireFields<QueryGetAllDistrictOfficersArgs, 'districtId' | 'page' | 'pageSize' | 'riDistrictId' | 'rotaryYear'>>;
  getAllDistricts?: Resolver<Array<ResolversTypes['DistrictDIS3']>, ParentType, ContextType, RequireFields<QueryGetAllDistrictsArgs, 'page'>>;
  getAllSharingPermissions?: Resolver<Array<ResolversTypes['SharingPermission']>, ParentType, ContextType>;
  getApplicationMode?: Resolver<Maybe<Array<Maybe<ResolversTypes['ApplicationModeResult']>>>, ParentType, ContextType>;
  getAssistantGovernorsPaginate?: Resolver<ResolversTypes['AssistantGovernorsResults'], ParentType, ContextType, RequireFields<QueryGetAssistantGovernorsPaginateArgs, 'currentPage' | 'districtId' | 'pageSize'>>;
  getBankAssignmentInfo?: Resolver<Maybe<Array<Maybe<ResolversTypes['BankAssignmentResult']>>>, ParentType, ContextType>;
  getCategories?: Resolver<Maybe<ResolversTypes['CategoriesResult']>, ParentType, ContextType>;
  getClubBalanceReport?: Resolver<Maybe<ResolversTypes['FileContentResult']>, ParentType, ContextType, RequireFields<QueryGetClubBalanceReportArgs, 'orgId' | 'orgType'>>;
  getClubDetails?: Resolver<Maybe<Array<Maybe<ResolversTypes['ClubDetailsResult']>>>, ParentType, ContextType, RequireFields<QueryGetClubDetailsArgs, 'ClubId' | 'ClubType'>>;
  getClubInvoiceReport?: Resolver<ResolversTypes['ClubInvoiceResult'], ParentType, ContextType, RequireFields<QueryGetClubInvoiceReportArgs, 'clubId' | 'clubType' | 'docType' | 'isCurrentInvoice'>>;
  getClubMembers?: Resolver<Maybe<ResolversTypes['ClubMembersResult']>, ParentType, ContextType, RequireFields<QueryGetClubMembersArgs, 'clubId' | 'clubType' | 'currentRecord' | 'recordCount' | 'status'>>;
  getCommitteeInvitations?: Resolver<Maybe<Array<Maybe<ResolversTypes['CommitteeNominations']>>>, ParentType, ContextType>;
  getCommitteeRoles?: Resolver<Array<ResolversTypes['CommitteeRolesResult']>, ParentType, ContextType, RequireFields<QueryGetCommitteeRolesArgs, 'individualId'>>;
  getConference?: Resolver<Maybe<ResolversTypes['GetConferenceDetailsResult']>, ParentType, ContextType, RequireFields<QueryGetConferenceArgs, 'conferenceId'>>;
  getConferences?: Resolver<Array<ResolversTypes['GetConferencesResults']>, ParentType, ContextType, RequireFields<QueryGetConferencesArgs, 'districtIds'>>;
  getCountries?: Resolver<Maybe<Array<Maybe<ResolversTypes['CountryDetails']>>>, ParentType, ContextType>;
  getCountryCurrencyMapping?: Resolver<Maybe<Array<Maybe<ResolversTypes['CountryCurrencyMapped']>>>, ParentType, ContextType>;
  getCurrencies?: Resolver<Maybe<Array<Maybe<ResolversTypes['CurrencyDetails']>>>, ParentType, ContextType>;
  getDelegationProfile?: Resolver<ResolversTypes['GetDelegationProfileResult'], ParentType, ContextType, RequireFields<QueryGetDelegationProfileArgs, 'memberId' | 'page'>>;
  getDistrictClubs?: Resolver<ResolversTypes['DistrictClubsResults'], ParentType, ContextType, RequireFields<QueryGetDistrictClubsArgs, 'districtId' | 'page' | 'pageSize'>>;
  getDistrictClubsStatistics?: Resolver<Array<ResolversTypes['DistrictClubsStatistics']>, ParentType, ContextType, RequireFields<QueryGetDistrictClubsStatisticsArgs, 'districtId' | 'types'>>;
  getDistrictDashboards?: Resolver<ResolversTypes['DistrictDashboardsResults'], ParentType, ContextType, RequireFields<QueryGetDistrictDashboardsArgs, 'districtId' | 'years'>>;
  getDistrictDetails?: Resolver<ResolversTypes['DistrictDIS3'], ParentType, ContextType, RequireFields<QueryGetDistrictDetailsArgs, 'districtId' | 'userId'>>;
  getDistrictMembersStatistics?: Resolver<Array<ResolversTypes['DistrictMembersStatistics']>, ParentType, ContextType, RequireFields<QueryGetDistrictMembersStatisticsArgs, 'riDistrictId' | 'types'>>;
  getDistrictsForLeadership?: Resolver<ResolversTypes['DistrictsResult'], ParentType, ContextType, RequireFields<QueryGetDistrictsForLeadershipArgs, 'role' | 'rotaryYear'>>;
  getDominoOrganizationId?: Resolver<ResolversTypes['OrganizationData'], ParentType, ContextType, RequireFields<QueryGetDominoOrganizationIdArgs, 'idType' | 'value'>>;
  getEcardDetails?: Resolver<Maybe<Array<Maybe<ResolversTypes['ECardDetailsResult']>>>, ParentType, ContextType, RequireFields<QueryGetEcardDetailsArgs, 'eCardId'>>;
  getExchangeRates?: Resolver<Maybe<Array<Maybe<ResolversTypes['ExchangeRates']>>>, ParentType, ContextType, RequireFields<QueryGetExchangeRatesArgs, 'EffectiveDate'>>;
  getFeedbackQuestions?: Resolver<Array<ResolversTypes['FeedbackQuestionsResponse']>, ParentType, ContextType, RequireFields<QueryGetFeedbackQuestionsArgs, 'conferenceYear' | 'feedbackType'>>;
  getFunds?: Resolver<Maybe<Array<Maybe<ResolversTypes['Funds']>>>, ParentType, ContextType>;
  getGin?: Resolver<Maybe<ResolversTypes['GinResult']>, ParentType, ContextType, RequireFields<QueryGetGinArgs, 'netForumMemberId'>>;
  getGrantDetails?: Resolver<Maybe<ResolversTypes['GrantDetailsResult']>, ParentType, ContextType, RequireFields<QueryGetGrantDetailsArgs, 'GrantId'>>;
  getIndividual?: Resolver<ResolversTypes['Individual'], ParentType, ContextType, RequireFields<QueryGetIndividualArgs, 'delayAfterResponse'>>;
  getIndividualMemberDetails?: Resolver<Maybe<ResolversTypes['IndividualMemberDetailsResult']>, ParentType, ContextType, RequireFields<QueryGetIndividualMemberDetailsArgs, 'MemberId'>>;
  getIndividualProfileDetails?: Resolver<Maybe<ResolversTypes['IndividualProfileDetailsResult']>, ParentType, ContextType, RequireFields<QueryGetIndividualProfileDetailsArgs, 'MemberId'>>;
  getIndividualSecurityGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['IndividualSecurityGroupsResult']>>>, ParentType, ContextType, RequireFields<QueryGetIndividualSecurityGroupsArgs, 'memberId'>>;
  getLeadershipHistory?: Resolver<Array<ResolversTypes['LeadershipResponseResult']>, ParentType, ContextType, RequireFields<QueryGetLeadershipHistoryArgs, never>>;
  getLegalEntities?: Resolver<Maybe<Array<Maybe<ResolversTypes['LegalEntityResult']>>>, ParentType, ContextType>;
  getMemberOrganizationDetails?: Resolver<Maybe<ResolversTypes['MemberOrganizationDetailsResult']>, ParentType, ContextType, RequireFields<QueryGetMemberOrganizationDetailsArgs, 'MemberId'>>;
  getMembership?: Resolver<Maybe<ResolversTypes['Membership']>, ParentType, ContextType, RequireFields<QueryGetMembershipArgs, 'clubId' | 'membershipId'>>;
  getMembershipHistory?: Resolver<Array<ResolversTypes['MembershipHistoryBase']>, ParentType, ContextType, RequireFields<QueryGetMembershipHistoryArgs, never>>;
  getNonMemberDES?: Resolver<Array<ResolversTypes['IndividualSearchResult']>, ParentType, ContextType, RequireFields<QueryGetNonMemberDesArgs, 'riDistrictId' | 'rotaryYear'>>;
  getOneFeedback?: Resolver<Maybe<ResolversTypes['Feedback']>, ParentType, ContextType, RequireFields<QueryGetOneFeedbackArgs, 'conferenceId' | 'feedbackType'>>;
  getOnlineIdEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryGetOnlineIdEmailArgs, 'individualId'>>;
  getOpenInvoiceList?: Resolver<Maybe<ResolversTypes['OpenInvoiceListResult']>, ParentType, ContextType, RequireFields<QueryGetOpenInvoiceListArgs, 'Id' | 'Type'>>;
  getOrganizationDetails?: Resolver<Maybe<ResolversTypes['OrganizationDetailsResult']>, ParentType, ContextType, RequireFields<QueryGetOrganizationDetailsArgs, 'OrganizationId' | 'OrganizationType'>>;
  getPaymentProfile?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaymentProfileResult']>>>, ParentType, ContextType>;
  getPaymentProfileName?: Resolver<Maybe<ResolversTypes['PaymentProfileNameResult']>, ParentType, ContextType, RequireFields<QueryGetPaymentProfileNameArgs, 'CountryISO2' | 'CurrencyCode' | 'CustomerType' | 'TransactionFlowName' | 'TransactionTypeAlias'>>;
  getPresRepConferences?: Resolver<Array<ResolversTypes['GetPresRepConferencesResult']>, ParentType, ContextType, RequireFields<QueryGetPresRepConferencesArgs, 'individualId'>>;
  getPreviousDES?: Resolver<Array<ResolversTypes['DistrictOfficer']>, ParentType, ContextType, RequireFields<QueryGetPreviousDesArgs, 'districtId' | 'page' | 'pageSize' | 'riDistrictId' | 'year'>>;
  getPrivacyPolicyMessages?: Resolver<Maybe<ResolversTypes['PrivacyPolicyTMessages']>, ParentType, ContextType>;
  getPrograms?: Resolver<Array<ResolversTypes['ProgramDefinition']>, ParentType, ContextType>;
  getRecognitionInfo?: Resolver<ResolversTypes['RecognitionDataInfo'], ParentType, ContextType, RequireFields<QueryGetRecognitionInfoArgs, 'individualId'>>;
  getRegionalGroupDistricts?: Resolver<Array<ResolversTypes['RegionalGroupDistricts']>, ParentType, ContextType, RequireFields<QueryGetRegionalGroupDistrictsArgs, 'regionalGroupId'>>;
  getRequestSource?: Resolver<Maybe<Array<Maybe<ResolversTypes['RequestSource']>>>, ParentType, ContextType>;
  getSitecoreReport?: Resolver<Array<ResolversTypes['Report']>, ParentType, ContextType, RequireFields<QueryGetSitecoreReportArgs, 'reportType'>>;
  getStatesByCountry?: Resolver<Maybe<Array<Maybe<ResolversTypes['StatesByCountryResult']>>>, ParentType, ContextType, RequireFields<QueryGetStatesByCountryArgs, 'CountryId'>>;
  getSubscriptionsForMember?: Resolver<Maybe<Array<Maybe<ResolversTypes['Subscriptions']>>>, ParentType, ContextType, RequireFields<QueryGetSubscriptionsForMemberArgs, 'MemberID'>>;
  getTimeZones?: Resolver<Array<ResolversTypes['TimeZone']>, ParentType, ContextType>;
  getTrackedGiftDetails?: Resolver<Maybe<ResolversTypes['TrackedGiftDetailsResult']>, ParentType, ContextType, RequireFields<QueryGetTrackedGiftDetailsArgs, 'DonorID' | 'DonorType' | 'LanguageCode' | 'TrackedGiftID'>>;
  getTransactionType?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransactionTypeResult']>>>, ParentType, ContextType>;
  languages?: Resolver<Array<ResolversTypes['LanguageExtended']>, ParentType, ContextType>;
  memberSearchAdvanced?: Resolver<ResolversTypes['MemberSearchResults'], ParentType, ContextType, RequireFields<QueryMemberSearchAdvancedArgs, 'allowRotaractors' | 'isDeceased' | 'isIncludedDistrictLeadership' | 'isIncludedDistrictMembership' | 'page' | 'pageSize'>>;
  roles?: Resolver<Maybe<Array<ResolversTypes['Role']>>, ParentType, ContextType, RequireFields<QueryRolesArgs, never>>;
  searchClub?: Resolver<ResolversTypes['ClubSearchResults'], ParentType, ContextType, RequireFields<QuerySearchClubArgs, 'advanced' | 'isSearchByClubName' | 'orderBy' | 'page' | 'pageSize' | 'status'>>;
  searchClubsInDistrict?: Resolver<ResolversTypes['DistrictClubsResults'], ParentType, ContextType, RequireFields<QuerySearchClubsInDistrictArgs, 'page' | 'pageSize' | 'riDistrictId'>>;
  searchDESByIndividualId?: Resolver<ResolversTypes['IndividualDESResult'], ParentType, ContextType, RequireFields<QuerySearchDesByIndividualIdArgs, 'individualId'>>;
  searchDGByRY?: Resolver<ResolversTypes['DGResult'], ParentType, ContextType, RequireFields<QuerySearchDgByRyArgs, 'conferenceDistrictId' | 'conferenceEndDate' | 'conferenceStartDate' | 'relatedRY'>>;
  searchIndividual?: Resolver<ResolversTypes['IndividualSearchResults'], ParentType, ContextType, RequireFields<QuerySearchIndividualArgs, 'advanced' | 'clubTypes' | 'countryId' | 'emailExactMatch' | 'page' | 'pageSize' | 'riClubId' | 'searchNonMembers'>>;
  searchPreviousOfficers?: Resolver<ResolversTypes['MemberSearchResults'], ParentType, ContextType, RequireFields<QuerySearchPreviousOfficersArgs, 'clubRole' | 'page' | 'pageSize' | 'yearRange'>>;
  searchSolr?: Resolver<ResolversTypes['SiteSearchResults'], ParentType, ContextType, RequireFields<QuerySearchSolrArgs, 'keywords'>>;
  states?: Resolver<Array<ResolversTypes['State']>, ParentType, ContextType, RequireFields<QueryStatesArgs, never>>;
  terminationReasons?: Resolver<Array<ResolversTypes['TerminationReason']>, ParentType, ContextType, RequireFields<QueryTerminationReasonsArgs, never>>;
  vendors?: Resolver<Maybe<Array<ResolversTypes['Vendor']>>, ParentType, ContextType>;
};

export type QuestionResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Question'] = ResolversParentTypes['Question']> = {
  applicationFeature?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  applicationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  feedbackSection?: Resolver<ResolversTypes['FeedbackSection'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  option?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  optionDataType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  optionDetail?: Resolver<ResolversTypes['OptionDetail'], ParentType, ContextType>;
  optionSeq?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  optionType?: Resolver<ResolversTypes['OptionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionResponseResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['QuestionResponse'] = ResolversParentTypes['QuestionResponse']> = {
  questionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecognitionResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Recognition'] = ResolversParentTypes['Recognition']> = {
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecognitionClubResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['RecognitionClub'] = ResolversParentTypes['RecognitionClub']> = {
  clubId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clubName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clubType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  physicalLocation?: Resolver<Maybe<ResolversTypes['PhysicalLocation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecognitionDataInfoResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['RecognitionDataInfo'] = ResolversParentTypes['RecognitionDataInfo']> = {
  bronze?: Resolver<Maybe<Array<Maybe<ResolversTypes['RecognitionItemInfo']>>>, ParentType, ContextType>;
  gold?: Resolver<Maybe<Array<Maybe<ResolversTypes['RecognitionItemInfo']>>>, ParentType, ContextType>;
  platinum?: Resolver<Maybe<Array<Maybe<ResolversTypes['RecognitionItemInfo']>>>, ParentType, ContextType>;
  silver?: Resolver<Maybe<Array<Maybe<ResolversTypes['RecognitionItemInfo']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecognitionIndividualResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['RecognitionIndividual'] = ResolversParentTypes['RecognitionIndividual']> = {
  club?: Resolver<ResolversTypes['RecognitionClub'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  individualId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  localizedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  membership?: Resolver<ResolversTypes['RecognitionMembership'], ParentType, ContextType>;
  middleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photoUri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prefix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sponsorships?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  suffix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecognitionItemResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['RecognitionItem'] = ResolversParentTypes['RecognitionItem']> = {
  recognitions?: Resolver<Array<ResolversTypes['Recognition']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecognitionItemInfoResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['RecognitionItemInfo'] = ResolversParentTypes['RecognitionItemInfo']> = {
  districtId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  individuals?: Resolver<Array<ResolversTypes['RecognitionIndividual']>, ParentType, ContextType>;
  riDistrictId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecognitionMembershipResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['RecognitionMembership'] = ResolversParentTypes['RecognitionMembership']> = {
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecurrenceResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Recurrence'] = ResolversParentTypes['Recurrence']> = {
  exdate?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  rdate?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  rrule?: Resolver<Maybe<Array<ResolversTypes['RecurrenceRule']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecurrenceRuleResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['RecurrenceRule'] = ResolversParentTypes['RecurrenceRule']> = {
  by?: Resolver<Maybe<ResolversTypes['RecurrenceSpecifiers']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  freq?: Resolver<ResolversTypes['RecurrenceFrequency'], ParentType, ContextType>;
  interval?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  until?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wkst?: Resolver<Maybe<ResolversTypes['WeekDay']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecurrenceSpecifiersResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['RecurrenceSpecifiers'] = ResolversParentTypes['RecurrenceSpecifiers']> = {
  day?: Resolver<Maybe<Array<ResolversTypes['DaySpecifier']>>, ParentType, ContextType>;
  hour?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  minute?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  second?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegionalGroupDistrictsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['RegionalGroupDistricts'] = ResolversParentTypes['RegionalGroupDistricts']> = {
  DistrictId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DistrictKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EndDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RegionalGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RegionalGroupName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Report'] = ResolversParentTypes['Report']> = {
  children?: Resolver<Array<ResolversTypes['ChildReport']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequestSourceResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['RequestSource'] = ResolversParentTypes['RequestSource']> = {
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateCreated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShowPrivacyPolicy?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SourceGUID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SourceName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResumeItemResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ResumeItem'] = ResolversParentTypes['ResumeItem']> = {
  address?: Resolver<ResolversTypes['OrganizationAddress'], ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roles?: Resolver<Array<ResolversTypes['ResumeRole']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResumeRoleResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ResumeRole'] = ResolversParentTypes['ResumeRole']> = {
  committeeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isShowOnline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResumeRolesResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ResumeRoles'] = ResolversParentTypes['ResumeRoles']> = {
  currentClubJoinDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalJoinDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resumeRolesGroups?: Resolver<Maybe<Array<ResolversTypes['ResumeItem']>>, ParentType, ContextType>;
  yearsOfService?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Role'] = ResolversParentTypes['Role']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isOfficer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  termLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  termsOfAccessAfter?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  termsOfAccessBefore?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleAccessResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['RoleAccess'] = ResolversParentTypes['RoleAccess']> = {
  allow?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  deny?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RolesAuthenticationTicketDataTypeResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['RolesAuthenticationTicketDataType'] = ResolversParentTypes['RolesAuthenticationTicketDataType']> = {
  clubGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clubGroupKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clubGroupName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clubId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  delegation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  delegationEndDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  delegationStartDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  delegatorIndividualKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  delegatorMemberId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  delegatorSortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  districtId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  districtKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  individualKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  memberId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  positionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferredLanguage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  regionalGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  regionalGroupKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  regionalGroupName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roleKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rotaractClubId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rotaractClubKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rotaryClubKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  termAfterDuration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  termBeforeDuration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  termEndDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  termStartDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userSortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SecurityQuestionResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['SecurityQuestion'] = ResolversParentTypes['SecurityQuestion']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SharingPermissionResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['SharingPermission'] = ResolversParentTypes['SharingPermission']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SharingPermissionResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['SharingPermissionResult'] = ResolversParentTypes['SharingPermissionResult']> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  memberId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SharingPermissionsExtendedResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['SharingPermissionsExtended'] = ResolversParentTypes['SharingPermissionsExtended']> = {
  aboutMe?: Resolver<ResolversTypes['SharingPermission'], ParentType, ContextType>;
  address?: Resolver<ResolversTypes['SharingPermission'], ParentType, ContextType>;
  dateOfBirth?: Resolver<ResolversTypes['SharingPermission'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['SharingPermission'], ParentType, ContextType>;
  expertise?: Resolver<ResolversTypes['SharingPermission'], ParentType, ContextType>;
  fax?: Resolver<ResolversTypes['SharingPermission'], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['SharingPermission'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['SharingPermission'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['SharingPermission'], ParentType, ContextType>;
  phone?: Resolver<ResolversTypes['SharingPermission'], ParentType, ContextType>;
  photo?: Resolver<ResolversTypes['SharingPermission'], ParentType, ContextType>;
  profession?: Resolver<ResolversTypes['SharingPermission'], ParentType, ContextType>;
  program?: Resolver<ResolversTypes['SharingPermission'], ParentType, ContextType>;
  recognition?: Resolver<ResolversTypes['SharingPermission'], ParentType, ContextType>;
  sponsors?: Resolver<ResolversTypes['SharingPermission'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SharingPermissionsRegularResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['SharingPermissionsRegular'] = ResolversParentTypes['SharingPermissionsRegular']> = {
  aboutMe?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  address?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  dateOfBirth?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  expertise?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  fax?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  phone?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  photo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  profession?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SharingPermissionsResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['SharingPermissionsResult'] = ResolversParentTypes['SharingPermissionsResult']> = {
  address?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  phone?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  photo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShortClubInfoResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ShortClubInfo'] = ResolversParentTypes['ShortClubInfo']> = {
  clubId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clubName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clubType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  meetings?: Resolver<Maybe<Array<Maybe<ResolversTypes['Meeting']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SiteSearchResultsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['SiteSearchResults'] = ResolversParentTypes['SiteSearchResults']> = {
  facet_counts?: Resolver<ResolversTypes['SolrFacets'], ParentType, ContextType>;
  highlighting?: Resolver<Maybe<Array<ResolversTypes['SolrHighlighting']>>, ParentType, ContextType>;
  response?: Resolver<ResolversTypes['SolrResponse'], ParentType, ContextType>;
  responseHeader?: Resolver<ResolversTypes['SolrResponseHeader'], ParentType, ContextType>;
  spellcheckSuggestion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SolrFacetFieldOptionResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['SolrFacetFieldOption'] = ResolversParentTypes['SolrFacetFieldOption']> = {
  count?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SolrFacetFieldsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['SolrFacetFields'] = ResolversParentTypes['SolrFacetFields']> = {
  content_type?: Resolver<Array<Maybe<ResolversTypes['SolrFacetFieldOption']>>, ParentType, ContextType>;
  document_mimetype?: Resolver<Maybe<Array<Maybe<ResolversTypes['SolrFacetFieldOption']>>>, ParentType, ContextType>;
  langcode?: Resolver<Maybe<Array<Maybe<ResolversTypes['SolrFacetFieldOption']>>>, ParentType, ContextType>;
  topic?: Resolver<Array<Maybe<ResolversTypes['SolrFacetFieldOption']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SolrFacetsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['SolrFacets'] = ResolversParentTypes['SolrFacets']> = {
  facet_fields?: Resolver<ResolversTypes['SolrFacetFields'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SolrHighlightingResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['SolrHighlighting'] = ResolversParentTypes['SolrHighlighting']> = {
  content_fulltext?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SolrResponseResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['SolrResponse'] = ResolversParentTypes['SolrResponse']> = {
  docs?: Resolver<Array<ResolversTypes['SolrResultItem']>, ParentType, ContextType>;
  numFound?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SolrResponseHeaderResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['SolrResponseHeader'] = ResolversParentTypes['SolrResponseHeader']> = {
  QTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  zkConnected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SolrResultItemResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['SolrResultItem'] = ResolversParentTypes['SolrResultItem']> = {
  _version_?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bundle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  content_fulltext?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  content_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date_created?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date_modified?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  document_file_size?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  document_mimetype?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  langcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  site?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  source_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  source_path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sticky?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  title_fulltext?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  topic?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SponsorshipItemResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['SponsorshipItem'] = ResolversParentTypes['SponsorshipItem']> = {
  club?: Resolver<ResolversTypes['FormattedClub'], ParentType, ContextType>;
  membershipList?: Resolver<Array<ResolversTypes['FormattedMembership']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SponsorshipResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['SponsorshipResult'] = ResolversParentTypes['SponsorshipResult']> = {
  sponsoredByMe?: Resolver<Array<ResolversTypes['SponsorshipItem']>, ParentType, ContextType>;
  sponsoredBySomeone?: Resolver<Array<ResolversTypes['SponsorshipItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StateResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['State'] = ResolversParentTypes['State']> = {
  countryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatesByCountryResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['StatesByCountryResult'] = ResolversParentTypes['StatesByCountryResult']> = {
  CountryKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateCreated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StateAbbreviation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StateKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubReportResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['SubReport'] = ResolversParentTypes['SubReport']> = {
  reportId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reportTechnicalName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reportTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubSponserResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['SubSponser'] = ResolversParentTypes['SubSponser']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Subscriptions'] = ResolversParentTypes['Subscriptions']> = {
  Address?: Resolver<Maybe<ResolversTypes['AddressDetails']>, ParentType, ContextType>;
  Amount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EmailAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Frequency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FundName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FundType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Individual?: Resolver<Maybe<ResolversTypes['IndividualDetails']>, ParentType, ContextType>;
  MerchantConfig?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NextChargeTimestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NextCharged?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Payment?: Resolver<Maybe<ResolversTypes['Payment']>, ParentType, ContextType>;
  PhoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SubFundType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SubscriptionCreatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SubscriptionCreationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SubscriptionCreationSource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SubscriptionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SubscriptionLastModificationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TranslatedFrequency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WidgetID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SuccessAssignmentsResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['SuccessAssignmentsResult'] = ResolversParentTypes['SuccessAssignmentsResult']> = {
  body?: Resolver<Maybe<ResolversTypes['SuccessBody']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  statusCode?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SuccessBodyResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['SuccessBody'] = ResolversParentTypes['SuccessBody']> = {
  endDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TermResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Term'] = ResolversParentTypes['Term']> = {
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TerminateClubVendorResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['TerminateClubVendorResult'] = ResolversParentTypes['TerminateClubVendorResult']> = {
  vendorId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TerminationReasonResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['TerminationReason'] = ResolversParentTypes['TerminationReason']> = {
  clubType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isNotAllowed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  reason?: Resolver<ResolversTypes['Reason'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeZoneResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['TimeZone'] = ResolversParentTypes['TimeZone']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offset?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timeZoneName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrackedGiftDetailsResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['TrackedGiftDetailsResult'] = ResolversParentTypes['TrackedGiftDetailsResult']> = {
  ALIAS_CODE?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ALIAS_DESCR?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ALIAS_DESCR_LANG?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DESIGNATION_DESCR?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ENDOWMENT_TYPE?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FUND?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GIFT_NAME?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GIFT_TYPE?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  STATUS?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SUB_FUND?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TRACKED_GIFT_ID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionTypeResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['TransactionTypeResult'] = ResolversParentTypes['TransactionTypeResult']> = {
  Alias?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateCreated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FundId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsDeleted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsRecurring?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SubFundId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionTypeKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionTypeTranslations?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransactionTypeTranslations']>>>, ParentType, ContextType>;
  TypeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionTypeTranslationsResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['TransactionTypeTranslations'] = ResolversParentTypes['TransactionTypeTranslations']> = {
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateCreated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsDeleted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LanguageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionTypeKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionTypeTranslationKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TranslatedLearnMore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TranslatedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateClubResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['UpdateClubResult'] = ResolversParentTypes['UpdateClubResult']> = {
  clubId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  availableSecurityQuestions?: Resolver<Array<ResolversTypes['SecurityQuestion']>, ParentType, ContextType>;
  currentSecurityQuestion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  impersonationExpiration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  impersonatorId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  individualId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  login?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nfKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  oktaId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profile?: Resolver<Maybe<ResolversTypes['UserProfile']>, ParentType, ContextType>;
  riIndividualId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserProfileResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['UserProfile'] = ResolversParentTypes['UserProfile']> = {
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Vendor'] = ResolversParentTypes['Vendor']> = {
  Key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorRequestorResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['VendorRequestor'] = ResolversParentTypes['VendorRequestor']> = {
  add?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  edit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  get?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isPreferred?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastUpdated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface VoidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Void'], any> {
  name: 'Void';
}

export type WebsiteResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['Website'] = ResolversParentTypes['Website']> = {
  IsPrimary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastUpdated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WebsiteAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WebsiteType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebsiteTypeResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['WebsiteType'] = ResolversParentTypes['WebsiteType']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  otherWebsites?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryWebsite?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddFiscalCodeErrorResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['addFiscalCodeError'] = ResolversParentTypes['addFiscalCodeError']> = {
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  statusText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddPanErrorResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['addPanError'] = ResolversParentTypes['addPanError']> = {
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  statusText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddRrnErrorResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['addRrnError'] = ResolversParentTypes['addRrnError']> = {
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  statusText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddSponsorErrorResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['addSponsorError'] = ResolversParentTypes['addSponsorError']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  source?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateLeadershipErrorResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['createLeadershipError'] = ResolversParentTypes['createLeadershipError']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  source?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateLeadershipResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['createLeadershipResult'] = ResolversParentTypes['createLeadershipResult']> = {
  leadership?: Resolver<Maybe<ResolversTypes['leadershipResult']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateMembershipErrorResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['createMembershipError'] = ResolversParentTypes['createMembershipError']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  source?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateMembershipResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['createMembershipResult'] = ResolversParentTypes['createMembershipResult']> = {
  membership?: Resolver<Maybe<ResolversTypes['membershipResult']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EcardAddResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['ecardAddResult'] = ResolversParentTypes['ecardAddResult']> = {
  ecardId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FiscalCodeInfoResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['fiscalCodeInfoResult'] = ResolversParentTypes['fiscalCodeInfoResult']> = {
  errorMsg?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeadershipResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['leadershipResult'] = ResolversParentTypes['leadershipResult']> = {
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  individualId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDelegated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['membershipResult'] = ResolversParentTypes['membershipResult']> = {
  admissionDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  individualId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  terminationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  terminationReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  terminationReasonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PanInfoResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['panInfoResult'] = ResolversParentTypes['panInfoResult']> = {
  pan?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RrnInfoResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['rrnInfoResult'] = ResolversParentTypes['rrnInfoResult']> = {
  rrn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TerminateLeadershipErrorResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['terminateLeadershipError'] = ResolversParentTypes['terminateLeadershipError']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  source?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TerminateMembershipErrorResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['terminateMembershipError'] = ResolversParentTypes['terminateMembershipError']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  source?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateMembershipTypeResultResolvers<ContextType = ApolloContext, ParentType extends ResolversParentTypes['updateMembershipTypeResult'] = ResolversParentTypes['updateMembershipTypeResult']> = {
  membership?: Resolver<Maybe<ResolversTypes['membershipResult']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = ApolloContext> = {
  AGResult?: AgResultResolvers<ContextType>;
  AccPhone?: AccPhoneResolvers<ContextType>;
  AccessLevelsArguments?: AccessLevelsArgumentsResolvers<ContextType>;
  AccountInfoByToken?: AccountInfoByTokenResolvers<ContextType>;
  ActiveMembership?: ActiveMembershipResolvers<ContextType>;
  AddClubMeetingResult?: AddClubMeetingResultResolvers<ContextType>;
  AddClubVendorResult?: AddClubVendorResultResolvers<ContextType>;
  AddMemberIndividualResult?: AddMemberIndividualResultResolvers<ContextType>;
  Address?: AddressResolvers<ContextType>;
  AddressDetails?: AddressDetailsResolvers<ContextType>;
  AddressInfo?: AddressInfoResolvers<ContextType>;
  Affiliation?: AffiliationResolvers<ContextType>;
  AgClubAffiliation?: AgClubAffiliationResolvers<ContextType>;
  ApplicationModeResult?: ApplicationModeResultResolvers<ContextType>;
  AssistantGovernor?: AssistantGovernorResolvers<ContextType>;
  AssistantGovernorByDate?: AssistantGovernorByDateResolvers<ContextType>;
  AssistantGovernorTermInfo?: AssistantGovernorTermInfoResolvers<ContextType>;
  AssistantGovernorsByDateResults?: AssistantGovernorsByDateResultsResolvers<ContextType>;
  AssistantGovernorsResults?: AssistantGovernorsResultsResolvers<ContextType>;
  BACountry?: BaCountryResolvers<ContextType>;
  BankAccount?: BankAccountResolvers<ContextType>;
  BankAssignmentResult?: BankAssignmentResultResolvers<ContextType>;
  BaseAddress?: BaseAddressResolvers<ContextType>;
  BasicInfo?: BasicInfoResolvers<ContextType>;
  CDSRepresentatives?: CdsRepresentativesResolvers<ContextType>;
  CRMIndividualInfoType?: CrmIndividualInfoTypeResolvers<ContextType>;
  CalendarEvent?: CalendarEventResolvers<ContextType>;
  CancelSubscriptionResponse?: CancelSubscriptionResponseResolvers<ContextType>;
  CategoriesByLanguage?: CategoriesByLanguageResolvers<ContextType>;
  CategoriesResult?: CategoriesResultResolvers<ContextType>;
  Category?: CategoryResolvers<ContextType>;
  ChildReport?: ChildReportResolvers<ContextType>;
  Club?: ClubResolvers<ContextType>;
  ClubAffiliation?: ClubAffiliationResolvers<ContextType>;
  ClubAssignmentsResponse?: ClubAssignmentsResponseResolvers<ContextType>;
  ClubAssignmentsResult?: ClubAssignmentsResultResolvers<ContextType>;
  ClubCredits?: ClubCreditsResolvers<ContextType>;
  ClubCreditsDeltas?: ClubCreditsDeltasResolvers<ContextType>;
  ClubDetails?: ClubDetailsResolvers<ContextType>;
  ClubDetailsResult?: ClubDetailsResultResolvers<ContextType>;
  ClubDevelopment?: ClubDevelopmentResolvers<ContextType>;
  ClubInvoiceResult?: ClubInvoiceResultResolvers<ContextType>;
  ClubLeadership?: ClubLeadershipResolvers<ContextType>;
  ClubLeadershipItem?: ClubLeadershipItemResolvers<ContextType>;
  ClubLocation?: ClubLocationResolvers<ContextType>;
  ClubMemberDetails?: ClubMemberDetailsResolvers<ContextType>;
  ClubMembersResult?: ClubMembersResultResolvers<ContextType>;
  ClubMembership?: ClubMembershipResolvers<ContextType>;
  ClubOperationsAccess?: ClubOperationsAccessResolvers<ContextType>;
  ClubRelationship?: ClubRelationshipResolvers<ContextType>;
  ClubSearchConstants?: ClubSearchConstantsResolvers<ContextType>;
  ClubSearchFilterConstants?: ClubSearchFilterConstantsResolvers<ContextType>;
  ClubSearchResult?: ClubSearchResultResolvers<ContextType>;
  ClubSearchResultClubRelationship?: ClubSearchResultClubRelationshipResolvers<ContextType>;
  ClubSearchResultMeeting?: ClubSearchResultMeetingResolvers<ContextType>;
  ClubSearchResults?: ClubSearchResultsResolvers<ContextType>;
  ClubSearchResutLocation?: ClubSearchResutLocationResolvers<ContextType>;
  ClubStatusResponse?: ClubStatusResponseResolvers<ContextType>;
  ClubVendor?: ClubVendorResolvers<ContextType>;
  CommitteeNominations?: CommitteeNominationsResolvers<ContextType>;
  CommitteeRolesResult?: CommitteeRolesResultResolvers<ContextType>;
  ConferenceAddress?: ConferenceAddressResolvers<ContextType>;
  ConferenceFeedbackResult?: ConferenceFeedbackResultResolvers<ContextType>;
  Constants?: ConstantsResolvers<ContextType>;
  Content?: ContentResolvers<ContextType>;
  ContentConfig?: ContentConfigResolvers<ContextType>;
  Contribution?: ContributionResolvers<ContextType>;
  ContributionDeltas?: ContributionDeltasResolvers<ContextType>;
  ContributionItem?: ContributionItemResolvers<ContextType>;
  Country?: CountryResolvers<ContextType>;
  CountryCurrency?: CountryCurrencyResolvers<ContextType>;
  CountryCurrencyMapped?: CountryCurrencyMappedResolvers<ContextType>;
  CountryDetails?: CountryDetailsResolvers<ContextType>;
  CountryTNames?: CountryTNamesResolvers<ContextType>;
  CountryTranslations?: CountryTranslationsResolvers<ContextType>;
  CreateOrUpdateIndividualResult?: CreateOrUpdateIndividualResultResolvers<ContextType>;
  CreditOrg?: CreditOrgResolvers<ContextType>;
  CreditOrgDetails?: CreditOrgDetailsResolvers<ContextType>;
  Currency?: CurrencyResolvers<ContextType>;
  CurrencyDetails?: CurrencyDetailsResolvers<ContextType>;
  CurrencyId?: CurrencyIdResolvers<ContextType>;
  CurrencyTNames?: CurrencyTNamesResolvers<ContextType>;
  CurrencyTranslations?: CurrencyTranslationsResolvers<ContextType>;
  DESDistrictInfo?: DesDistrictInfoResolvers<ContextType>;
  DGResult?: DgResultResolvers<ContextType>;
  DaySpecifier?: DaySpecifierResolvers<ContextType>;
  Delegates?: DelegatesResolvers<ContextType>;
  DelegationRoles?: DelegationRolesResolvers<ContextType>;
  Delegations?: DelegationsResolvers<ContextType>;
  DeleteClubMeetingResult?: DeleteClubMeetingResultResolvers<ContextType>;
  Demographic?: DemographicResolvers<ContextType>;
  DisplayNames?: DisplayNamesResolvers<ContextType>;
  District?: DistrictResolvers<ContextType>;
  DistrictAddress?: DistrictAddressResolvers<ContextType>;
  DistrictClub?: DistrictClubResolvers<ContextType>;
  DistrictClubsResults?: DistrictClubsResultsResolvers<ContextType>;
  DistrictClubsStatistics?: DistrictClubsStatisticsResolvers<ContextType>;
  DistrictDIS3?: DistrictDis3Resolvers<ContextType>;
  DistrictDashboardsResults?: DistrictDashboardsResultsResolvers<ContextType>;
  DistrictData?: DistrictDataResolvers<ContextType>;
  DistrictEmails?: DistrictEmailsResolvers<ContextType>;
  DistrictFax?: DistrictFaxResolvers<ContextType>;
  DistrictLeadership?: DistrictLeadershipResolvers<ContextType>;
  DistrictLeadershipResult?: DistrictLeadershipResultResolvers<ContextType>;
  DistrictMembersStatistics?: DistrictMembersStatisticsResolvers<ContextType>;
  DistrictMembershipClub?: DistrictMembershipClubResolvers<ContextType>;
  DistrictOfficer?: DistrictOfficerResolvers<ContextType>;
  DistrictOfficersResults?: DistrictOfficersResultsResolvers<ContextType>;
  DistrictPhone?: DistrictPhoneResolvers<ContextType>;
  DistrictType?: DistrictTypeResolvers<ContextType>;
  DistrictWebsites?: DistrictWebsitesResolvers<ContextType>;
  DistrictsResult?: DistrictsResultResolvers<ContextType>;
  DominoEmailType?: DominoEmailTypeResolvers<ContextType>;
  ECardDetailsResult?: ECardDetailsResultResolvers<ContextType>;
  EditMeetingResult?: EditMeetingResultResolvers<ContextType>;
  Email?: EmailResolvers<ContextType>;
  EmailAddress?: EmailAddressResolvers<ContextType>;
  ErrorAssignmentsResult?: ErrorAssignmentsResultResolvers<ContextType>;
  ErrorBatch?: ErrorBatchResolvers<ContextType>;
  ErrorBody?: ErrorBodyResolvers<ContextType>;
  ExchangeRates?: ExchangeRatesResolvers<ContextType>;
  Expertise?: ExpertiseResolvers<ContextType>;
  ExpertiseArea?: ExpertiseAreaResolvers<ContextType>;
  ExpertiseAreaItem?: ExpertiseAreaItemResolvers<ContextType>;
  ExpertiseLevel?: ExpertiseLevelResolvers<ContextType>;
  ExpertiseLevelItem?: ExpertiseLevelItemResolvers<ContextType>;
  Fax?: FaxResolvers<ContextType>;
  Feedback?: FeedbackResolvers<ContextType>;
  FeedbackQuestionsResponse?: FeedbackQuestionsResponseResolvers<ContextType>;
  FeedbackSection?: FeedbackSectionResolvers<ContextType>;
  FieldConstraints?: FieldConstraintsResolvers<ContextType>;
  FieldOption?: FieldOptionResolvers<ContextType>;
  FileContentResult?: FileContentResultResolvers<ContextType>;
  FinancialReprentatives?: FinancialReprentativesResolvers<ContextType>;
  FormattedClub?: FormattedClubResolvers<ContextType>;
  FormattedLocation?: FormattedLocationResolvers<ContextType>;
  FormattedMembership?: FormattedMembershipResolvers<ContextType>;
  FundSubtitle?: FundSubtitleResolvers<ContextType>;
  FundTitle?: FundTitleResolvers<ContextType>;
  Funding?: FundingResolvers<ContextType>;
  Funds?: FundsResolvers<ContextType>;
  GeoLocation?: GeoLocationResolvers<ContextType>;
  GetAccessLevelsResults?: GetAccessLevelsResultsResolvers<ContextType>;
  GetAddDelegationResult?: GetAddDelegationResultResolvers<ContextType>;
  GetConferenceDetailsResult?: GetConferenceDetailsResultResolvers<ContextType>;
  GetConferencesResults?: GetConferencesResultsResolvers<ContextType>;
  GetDelegationProfileResult?: GetDelegationProfileResultResolvers<ContextType>;
  GetEditedDelegationResult?: GetEditedDelegationResultResolvers<ContextType>;
  GetPresRepConferencesResult?: GetPresRepConferencesResultResolvers<ContextType>;
  GinResult?: GinResultResolvers<ContextType>;
  GrantDetailsResult?: GrantDetailsResultResolvers<ContextType>;
  GrantLocation?: GrantLocationResolvers<ContextType>;
  GroupApplicationRolesRelationshipType?: GroupApplicationRolesRelationshipTypeResolvers<ContextType>;
  GroupApplicationsType?: GroupApplicationsTypeResolvers<ContextType>;
  GroupRolesType?: GroupRolesTypeResolvers<ContextType>;
  HATEOASLink?: HateoasLinkResolvers<ContextType>;
  Individual?: IndividualResolvers<ContextType>;
  IndividualAddress?: IndividualAddressResolvers<ContextType>;
  IndividualAffiliationInfo?: IndividualAffiliationInfoResolvers<ContextType>;
  IndividualDESResult?: IndividualDesResultResolvers<ContextType>;
  IndividualDetails?: IndividualDetailsResolvers<ContextType>;
  IndividualEditPermissions?: IndividualEditPermissionsResolvers<ContextType>;
  IndividualEmail?: IndividualEmailResolvers<ContextType>;
  IndividualInfo?: IndividualInfoResolvers<ContextType>;
  IndividualLanguage?: IndividualLanguageResolvers<ContextType>;
  IndividualMemberDetailsResult?: IndividualMemberDetailsResultResolvers<ContextType>;
  IndividualOperationsAccessGql?: IndividualOperationsAccessGqlResolvers<ContextType>;
  IndividualOrganization?: IndividualOrganizationResolvers<ContextType>;
  IndividualPhone?: IndividualPhoneResolvers<ContextType>;
  IndividualProfileDetailsResult?: IndividualProfileDetailsResultResolvers<ContextType>;
  IndividualRole?: IndividualRoleResolvers<ContextType>;
  IndividualSearchResult?: IndividualSearchResultResolvers<ContextType>;
  IndividualSearchResults?: IndividualSearchResultsResolvers<ContextType>;
  IndividualSecurityGroupsResult?: IndividualSecurityGroupsResultResolvers<ContextType>;
  InvoiceItem?: InvoiceItemResolvers<ContextType>;
  LELegalEntityTranslations?: LeLegalEntityTranslationsResolvers<ContextType>;
  Language?: LanguageResolvers<ContextType>;
  LanguageDetails?: LanguageDetailsResolvers<ContextType>;
  LanguageExtended?: LanguageExtendedResolvers<ContextType>;
  Leadership?: LeadershipResolvers<ContextType>;
  LeadershipAffiliation?: LeadershipAffiliationResolvers<ContextType>;
  LeadershipEmbeddedDataBase?: LeadershipEmbeddedDataBaseResolvers<ContextType>;
  LeadershipResponseResult?: LeadershipResponseResultResolvers<ContextType>;
  LegalEntity?: LegalEntityResolvers<ContextType>;
  LegalEntityResult?: LegalEntityResultResolvers<ContextType>;
  LegalEntityTranslations?: LegalEntityTranslationsResolvers<ContextType>;
  Link?: LinkResolvers<ContextType>;
  Links?: LinksResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  LocationData?: LocationDataResolvers<ContextType>;
  LocationDetails?: LocationDetailsResolvers<ContextType>;
  LocationInfo?: LocationInfoResolvers<ContextType>;
  Meeting?: MeetingResolvers<ContextType>;
  MemberOrganizationDetailsResult?: MemberOrganizationDetailsResultResolvers<ContextType>;
  MemberSearchResult?: MemberSearchResultResolvers<ContextType>;
  MemberSearchResults?: MemberSearchResultsResolvers<ContextType>;
  MembersTrends?: MembersTrendsResolvers<ContextType>;
  Membership?: MembershipResolvers<ContextType>;
  MembershipAffiliation?: MembershipAffiliationResolvers<ContextType>;
  MembershipEmbeddedDataBase?: MembershipEmbeddedDataBaseResolvers<ContextType>;
  MembershipForAddMember?: MembershipForAddMemberResolvers<ContextType>;
  MembershipHistoryBase?: MembershipHistoryBaseResolvers<ContextType>;
  MembershipsList?: MembershipsListResolvers<ContextType>;
  MonthlyData?: MonthlyDataResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  OfficerIndividual?: OfficerIndividualResolvers<ContextType>;
  OfficerMembership?: OfficerMembershipResolvers<ContextType>;
  OktaProfileDataType?: OktaProfileDataTypeResolvers<ContextType>;
  OpenInvoiceListResult?: OpenInvoiceListResultResolvers<ContextType>;
  OptionDetail?: OptionDetailResolvers<ContextType>;
  OptionType?: OptionTypeResolvers<ContextType>;
  OrganisationDataBase?: OrganisationDataBaseResolvers<ContextType>;
  Organization?: OrganizationResolvers<ContextType>;
  OrganizationAddress?: OrganizationAddressResolvers<ContextType>;
  OrganizationData?: OrganizationDataResolvers<ContextType>;
  OrganizationDetailsResult?: OrganizationDetailsResultResolvers<ContextType>;
  ParticipatingDistricts?: ParticipatingDistrictsResolvers<ContextType>;
  Payment?: PaymentResolvers<ContextType>;
  PaymentProfileNameResult?: PaymentProfileNameResultResolvers<ContextType>;
  PaymentProfileResult?: PaymentProfileResultResolvers<ContextType>;
  PhoneNumber?: PhoneNumberResolvers<ContextType>;
  PhoneNumbers?: PhoneNumbersResolvers<ContextType>;
  PhysicalLocation?: PhysicalLocationResolvers<ContextType>;
  PresidentialRepresentative?: PresidentialRepresentativeResolvers<ContextType>;
  PrivacyPolicyTMessages?: PrivacyPolicyTMessagesResolvers<ContextType>;
  Profession?: ProfessionResolvers<ContextType>;
  Program?: ProgramResolvers<ContextType>;
  ProgramDefinition?: ProgramDefinitionResolvers<ContextType>;
  ProgramField?: ProgramFieldResolvers<ContextType>;
  ProgramFieldDefinition?: ProgramFieldDefinitionResolvers<ContextType>;
  ProgramFieldGroup?: ProgramFieldGroupResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Question?: QuestionResolvers<ContextType>;
  QuestionResponse?: QuestionResponseResolvers<ContextType>;
  Recognition?: RecognitionResolvers<ContextType>;
  RecognitionClub?: RecognitionClubResolvers<ContextType>;
  RecognitionDataInfo?: RecognitionDataInfoResolvers<ContextType>;
  RecognitionIndividual?: RecognitionIndividualResolvers<ContextType>;
  RecognitionItem?: RecognitionItemResolvers<ContextType>;
  RecognitionItemInfo?: RecognitionItemInfoResolvers<ContextType>;
  RecognitionMembership?: RecognitionMembershipResolvers<ContextType>;
  Recurrence?: RecurrenceResolvers<ContextType>;
  RecurrenceRule?: RecurrenceRuleResolvers<ContextType>;
  RecurrenceSpecifiers?: RecurrenceSpecifiersResolvers<ContextType>;
  RegionalGroupDistricts?: RegionalGroupDistrictsResolvers<ContextType>;
  Report?: ReportResolvers<ContextType>;
  RequestSource?: RequestSourceResolvers<ContextType>;
  ResumeItem?: ResumeItemResolvers<ContextType>;
  ResumeRole?: ResumeRoleResolvers<ContextType>;
  ResumeRoles?: ResumeRolesResolvers<ContextType>;
  Role?: RoleResolvers<ContextType>;
  RoleAccess?: RoleAccessResolvers<ContextType>;
  RolesAuthenticationTicketDataType?: RolesAuthenticationTicketDataTypeResolvers<ContextType>;
  SecurityQuestion?: SecurityQuestionResolvers<ContextType>;
  SharingPermission?: SharingPermissionResolvers<ContextType>;
  SharingPermissionResult?: SharingPermissionResultResolvers<ContextType>;
  SharingPermissionsExtended?: SharingPermissionsExtendedResolvers<ContextType>;
  SharingPermissionsRegular?: SharingPermissionsRegularResolvers<ContextType>;
  SharingPermissionsResult?: SharingPermissionsResultResolvers<ContextType>;
  ShortClubInfo?: ShortClubInfoResolvers<ContextType>;
  SiteSearchResults?: SiteSearchResultsResolvers<ContextType>;
  SolrFacetFieldOption?: SolrFacetFieldOptionResolvers<ContextType>;
  SolrFacetFields?: SolrFacetFieldsResolvers<ContextType>;
  SolrFacets?: SolrFacetsResolvers<ContextType>;
  SolrHighlighting?: SolrHighlightingResolvers<ContextType>;
  SolrResponse?: SolrResponseResolvers<ContextType>;
  SolrResponseHeader?: SolrResponseHeaderResolvers<ContextType>;
  SolrResultItem?: SolrResultItemResolvers<ContextType>;
  SponsorshipItem?: SponsorshipItemResolvers<ContextType>;
  SponsorshipResult?: SponsorshipResultResolvers<ContextType>;
  State?: StateResolvers<ContextType>;
  StatesByCountryResult?: StatesByCountryResultResolvers<ContextType>;
  SubReport?: SubReportResolvers<ContextType>;
  SubSponser?: SubSponserResolvers<ContextType>;
  Subscriptions?: SubscriptionsResolvers<ContextType>;
  SuccessAssignmentsResult?: SuccessAssignmentsResultResolvers<ContextType>;
  SuccessBody?: SuccessBodyResolvers<ContextType>;
  Term?: TermResolvers<ContextType>;
  TerminateClubVendorResult?: TerminateClubVendorResultResolvers<ContextType>;
  TerminationReason?: TerminationReasonResolvers<ContextType>;
  TimeZone?: TimeZoneResolvers<ContextType>;
  TrackedGiftDetailsResult?: TrackedGiftDetailsResultResolvers<ContextType>;
  TransactionTypeResult?: TransactionTypeResultResolvers<ContextType>;
  TransactionTypeTranslations?: TransactionTypeTranslationsResolvers<ContextType>;
  UpdateClubResult?: UpdateClubResultResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserProfile?: UserProfileResolvers<ContextType>;
  Vendor?: VendorResolvers<ContextType>;
  VendorRequestor?: VendorRequestorResolvers<ContextType>;
  Void?: GraphQLScalarType;
  Website?: WebsiteResolvers<ContextType>;
  WebsiteType?: WebsiteTypeResolvers<ContextType>;
  addFiscalCodeError?: AddFiscalCodeErrorResolvers<ContextType>;
  addPanError?: AddPanErrorResolvers<ContextType>;
  addRrnError?: AddRrnErrorResolvers<ContextType>;
  addSponsorError?: AddSponsorErrorResolvers<ContextType>;
  createLeadershipError?: CreateLeadershipErrorResolvers<ContextType>;
  createLeadershipResult?: CreateLeadershipResultResolvers<ContextType>;
  createMembershipError?: CreateMembershipErrorResolvers<ContextType>;
  createMembershipResult?: CreateMembershipResultResolvers<ContextType>;
  ecardAddResult?: EcardAddResultResolvers<ContextType>;
  fiscalCodeInfoResult?: FiscalCodeInfoResultResolvers<ContextType>;
  leadershipResult?: LeadershipResultResolvers<ContextType>;
  membershipResult?: MembershipResultResolvers<ContextType>;
  panInfoResult?: PanInfoResultResolvers<ContextType>;
  rrnInfoResult?: RrnInfoResultResolvers<ContextType>;
  terminateLeadershipError?: TerminateLeadershipErrorResolvers<ContextType>;
  terminateMembershipError?: TerminateMembershipErrorResolvers<ContextType>;
  updateMembershipTypeResult?: UpdateMembershipTypeResultResolvers<ContextType>;
};

