/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

// import directCall from './direct';

class Workflow {
  // switch: 'app' | 'direct' = 'app';

  workflow!: string;

  action!: string;

  directBaseUrl!: string;

  directEndPoint!: string;

  baseUrl = `${process.env.GATSBY_MRX_API_PUBLIC_URL}/domui`;

  data!: Record<string, any>;

  constructor(workflow: string) {
    this.workflow = workflow;
  }

  setAction(action: string) {
    this.action = action;
  }

  setData(data: Record<string, any>) {
    this.data = data;
  }

  getUrl() {
    return `${this.baseUrl}/${this.workflow}/${this.action}`;
  }

  async getCall() {
    try {
      const res = await axios.get<any>(this.getUrl(), {
        params: this.data,
      });
      return res;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err?.response?.status === 404) {
          // Request was made but no response was received
          const res = err?.response;
          return res;
        }
        if (err.response) {
          // Server responded with a status code other than 2xx
          throw new Error(
            `Error ${err.response.status}: ${err.response.data.wfRes.message ||
              err.response.statusText}`
          );
        } else if (err.request) {
          // Request was made but no response was received
          throw new Error(
            'No response received from the server. Please check your network.'
          );
        } else {
          // Something went wrong during the request setup
          throw new Error('Error in request setup.');
        }
      } else {
        // Handle unexpected errors
        throw new Error('An unexpected error occurred.');
      }
    }
  }

  async postCall() {
    try {
      const res = await axios.post<any>(this.getUrl(), {
        data: { ...this.data, apiMethod: 'post' },
      });
      return res;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err?.response?.status === 404) {
          const res = err?.response;
          return res;
        }
        if (err.response) {
          // Server responded with a status code other than 2xx
          const typedError = err as any;
          throw typedError.response.data.wfRes.responseData.errors[0];
        } else if (err.request) {
          // Request was made but no response was received
          throw new Error(
            'No response received from the server. Please check your network.'
          );
        } else {
          // Something went wrong during the request setup
          throw new Error('Error in request setup.');
        }
      } else {
        // Handle unexpected errors
        throw new Error('An unexpected error occurred.');
      }
    }
  }

  async putCall() {
    try {
      const res = await axios.post<any>(this.getUrl(), {
        data: { ...this.data, apiMethod: 'put' },
      });
      return res;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response) {
          // Server responded with a status code other than 2xx
          const typedError = err as any;

          throw typedError.response.data.wfRes.responseData.errors[0] ||
            typedError.response.data.wfRes.responseData ||
            new Error('An unexpected error occurred.');
        } else if (err.request) {
          // Request was made but no response was received
          throw new Error(
            'No response received from the server. Please check your network.'
          );
        } else {
          // Something went wrong during the request setup
          throw new Error('Error in request setup.');
        }
      } else {
        // Handle unexpected errors
        throw new Error('An unexpected error occurred.');
      }
    }
  }
}

export default Workflow;
